import React, { PropsWithChildren } from 'react'
import StatamicImage from '@components/common/StatamicImage'
import { Set_InsertComponents_BannerWithImage } from '@graphql/generated'
import Bard from '@components/common/Bard/Bard'
import Button from '@components/common/Button'
import AppLink from '@components/common/AppLink'
import cx from 'classnames'

type Props = Set_InsertComponents_BannerWithImage

const BannerWithImage: React.FC<Props> = (props) => {
  const Comp: React.FC<PropsWithChildren<any>> = ({ children }) =>
    props.button_link ? (
      <AppLink href={props.button_link} className="group block outline-none">
        {children}
      </AppLink>
    ) : (
      <div className="group">{children}</div>
    )

  return (
    <Comp>
      <section className="bg-white transition-all md:grid-cols-[8rem_auto] md:shadow-md hover:md:shadow-lg xl:grid">
        {props.image && (
          <div className="hidden bg-red-50 xl:block">
            <StatamicImage loading="lazy" asset={props.image} className="h-full w-full" />
          </div>
        )}
        <div className="col-start-2 flex flex-col items-start space-y-4 pl-7 pr-7 pb-8 pt-7 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-8 md:pr-12 md:pt-6 md:pb-7 md:pl-7 lg:flex-col lg:items-start lg:justify-start lg:space-y-4 lg:space-x-0 xl:flex-row xl:items-center xl:justify-between xl:space-y-0 xl:space-x-8">
          <div className="space-y-2 md:space-y-3">
            <h2 className="typo-700">{props.title}</h2>
            <Bard html={props.text} className="typo-200-regular" />
          </div>

          {props.button_link && (
            <div className="shrink-0">
              <span
                className={cx([
                  'relative isolate inline-flex items-center justify-center px-7 py-2 outline-none transition-all before:absolute before:inset-0 before:-z-1 before:transition-all [.group:focus-visible_&]:before:ring',
                  'typo-500 px-5 py-1.5 text-base',
                  'text-white before:bg-red-500 hover:before:bg-red-600 disabled:before:bg-gray-300 disabled:hover:before:bg-gray-300 [.group:focus-visible_&]:before:bg-red-600',
                  'hover:translate-x-1 hover:before:-skew-x-13 [.group:focus-visible_&]:translate-x-1 [.group:focus-visible_&]:before:-skew-x-13',
                ])}
              >
                {props.button_text}
              </span>
            </div>
          )}
        </div>
      </section>
    </Comp>
  )
}

export default BannerWithImage
