import Container from './common/Container'
import { GlobalSet_Footer, useGetGlobalsQuery } from '@graphql/generated'
import Logo from '@components/icons/Logo'
import cx from 'classnames'
import AppLink from '@components/common/AppLink'
import Link from '@components/common/Link'
import FacebookIcon from '@components/icons/FacebookIcon'
import InstagramIcon from '@components/icons/InstagramIcon'
import LinkedInIcon from '@components/icons/LinkedInIcon'
import TwitterIcon from '@components/icons/TwitterIcon'
import ChainIcon from '@components/icons/ChainIcon'
import { isExternalUrl } from '@lib/isExternalUrl'
import SmallNewsletterForm from '@components/forms/SmallNewsletterForm'
import Reveal, { RevealChild } from '@components/common/Reveal'

const Footer: React.FC = () => {
  const globalsQuery = useGetGlobalsQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const footerMainNav = globalsQuery.data?.footer_main_navigation
  const footerSecondaryNav = globalsQuery.data?.footer_secondary_navigation
  const footer = globalsQuery.data?.footer as GlobalSet_Footer

  return (
    <footer id="footer" className="relative -mt-10 w-full lg:-mt-7">
      <Reveal viewport={{ margin: '0px' }} className="flex flex-col lg:flex-row">
        <div
          className={cx(
            'relative isolate',
            'after:absolute after:inset-0 after:-z-1 after:translate-y-6 after:skew-y-[1deg] after:border-t-6 after:border-gray-50 after:bg-white lg:after:hidden'
          )}
        >
          <RevealChild
            className={cx(
              'relative isolate inline-flex py-5 pl-6 pr-9 lg:h-full lg:flex-col lg:justify-between lg:pr-20 lg:pt-8 lg:pl-18 lg:pb-10',
              'after:absolute after:inset-0 after:-left-20 after:-z-1 after:-skew-x-13 after:border-r-6 after:border-t-6 after:border-gray-50 after:bg-white'
            )}
          >
            <Logo className="h-25 w-40 text-red-500" />

            <div className="hidden space-x-6 lg:flex">
              {footer?.facebook && (
                <Link href={footer.facebook} className="">
                  <span className="sr-only">Facebook öffnen</span>
                  <FacebookIcon className="h-5 w-5" />
                </Link>
              )}
              {footer?.instagram && (
                <Link href={footer.instagram} className="">
                  <span className="sr-only">Instagram öffnen</span>
                  <InstagramIcon className="h-5 w-5" />
                </Link>
              )}
              {footer?.linkedin && (
                <Link href={footer.linkedin} className="">
                  <span className="sr-only">LinkedIn öffnen</span>
                  <LinkedInIcon className="h-5 w-5" />
                </Link>
              )}
              {footer?.twitter && (
                <Link href={footer.twitter} className="">
                  <span className="sr-only">Twitter öffnen</span>
                  <TwitterIcon className="h-5 w-5" />
                </Link>
              )}
            </div>
          </RevealChild>
        </div>

        <Container fluid asChild>
          <RevealChild className="pt-10 pb-7 lg:mt-7 lg:border-t-6 lg:border-gray-50 lg:pb-10 lg:pl-20 lg:pt-15">
            <div className="md:grid md:grid-flow-dense md:grid-cols-[auto,20.75rem] md:gap-y-10 md:gap-x-8 lg:grid-cols-[3fr,2fr] lg:gap-y-15 xl:grid-cols-[auto,20.75rem]">
              <nav className="mb-14 md:mb-0">
                <ul className="flex flex-col flex-wrap justify-start gap-y-6 md:max-h-56">
                  {footerMainNav?.tree?.map(
                    (item, i) =>
                      item?.page && (
                        <li key={i} className="typo-300">
                          {item.page.url ? (
                            <Link
                              href={item.page.url}
                              className="inline-flex items-center space-x-2"
                            >
                              {isExternalUrl(item.page.url) && <ChainIcon className="h-4 w-4" />}
                              <span>{item.page.title}</span>
                            </Link>
                          ) : (
                            item.page.title
                          )}
                        </li>
                      )
                  )}
                </ul>
              </nav>

              <div className="mb-16 md:mb-0">
                <SmallNewsletterForm
                  title={footer?.newsletter_title ?? undefined}
                  button_text={footer?.newsletter_button_text ?? undefined}
                  thank_you_text={footer?.newsletter_thank_you_text ?? undefined}
                />
              </div>

              <div className="mb-6 md:mb-0 lg:hidden">
                <div className="flex flex-wrap gap-x-8 gap-y-4">
                  {footer?.facebook && (
                    <Link href={footer.facebook} className="">
                      <span className="sr-only">Facebook öffnen</span>
                      <FacebookIcon className="h-5 w-5" />
                    </Link>
                  )}
                  {footer?.instagram && (
                    <Link href={footer.instagram} className="">
                      <span className="sr-only">Instagram öffnen</span>
                      <InstagramIcon className="h-5 w-5" />
                    </Link>
                  )}
                  {footer?.linkedin && (
                    <Link href={footer.linkedin} className="">
                      <span className="sr-only">LinkedIn öffnen</span>
                      <LinkedInIcon className="h-5 w-5" />
                    </Link>
                  )}
                  {footer?.twitter && (
                    <Link href={footer.twitter} className="">
                      <span className="sr-only">Twitter öffnen</span>
                      <TwitterIcon className="h-5 w-5" />
                    </Link>
                  )}
                </div>
              </div>

              <nav className="mb-5 md:col-start-2 md:row-start-3 md:mb-0 lg:row-start-auto">
                <ul className="flex flex-wrap justify-start gap-x-4 gap-y-2 md:justify-end">
                  {footerSecondaryNav?.tree?.map(
                    (item, i) =>
                      item?.page && (
                        <li key={i} className="typo-200">
                          {item.page.permalink ? (
                            <Link href={item.page.permalink}>{item.page.title}</Link>
                          ) : (
                            item.page.title
                          )}
                        </li>
                      )
                  )}
                </ul>
              </nav>

              <p className="typo-200-regular md:row-start-3 lg:row-start-auto">
                {footer?.copyright_text && `© ${new Date().getFullYear()} ${footer.copyright_text}`}
              </p>
            </div>
          </RevealChild>
        </Container>
      </Reveal>
    </footer>
  )
}

export default Footer
