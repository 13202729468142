import { IconProps } from '@additional'
import cx from 'classnames'

const ArrowIcon: React.FC<IconProps> = (props) => {
  const { className, ...leftOverProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cx(
        'origin-left transition-transform group-hover:scale-x-125 group-disabled:group-hover:scale-x-100',
        className
      )}
      {...leftOverProps}
    >
      <g fill="currentColor">
        <path
          className="origin-right transition-transform group-hover:scale-x-[80%] group-disabled:group-hover:scale-x-100"
          d="m13.528 20.421-1.52-1.621 7.255-6.8-7.255-6.8 1.52-1.621 8.12 7.61a1.111 1.111 0 0 1 0 1.621Z"
        />
        <path d="M20.7 13.111H2v-2.222h18.7Z" />
      </g>
    </svg>
  )
}

ArrowIcon.defaultProps = {
  'aria-hidden': true,
}

export default ArrowIcon
