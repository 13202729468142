import Link from 'next/link'
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Slot } from '@radix-ui/react-slot'
import { useRouter } from 'next/router'
import usePreviousValue from '@hooks/usePreviousValue'
import { useMutation } from 'react-query'
import useMutationObserver from '@hooks/useMutationObserver'
import { isExternalUrl } from '@lib/isExternalUrl'

export type WithNextLinkProps = {}

const WithNextLinks: React.FC<PropsWithChildren<WithNextLinkProps>> = ({ children, ...props }) => {
  const router = useRouter()

  const navigate = (event: MouseEvent) => {
    const href = (event.currentTarget as HTMLAnchorElement)?.getAttribute('href')
    if (!href) {
      return
    }

    if (!isExternalUrl(href)) {
      const path = href.replace(location.origin, '') || '/'

      event.preventDefault()

      router.push(path)
    }
  }

  const addListeners = (links: HTMLAnchorElement[]) => {
    for (let link of Array.from(links)) {
      const rel = link.getAttribute('rel')

      const target = link.getAttribute('target')

      // For improved security `rel="noopener"` will be added automatically if target is `_blank`
      // https://github.com/mathiasbynens/rel-noopener/
      if (!rel && target && target === '_blank') {
        link.setAttribute('rel', 'noopener')
      }

      link.addEventListener('click', navigate, false)
    }
  }

  const removeListeners = (links: HTMLAnchorElement[]) => {
    for (let link of Array.from(links)) {
      link.removeEventListener('click', navigate, false)
    }
  }

  const contentRef = useRef<HTMLDivElement>(null)

  const [links, setLinks] = useState<HTMLAnchorElement[]>([])

  useEffect(() => {
    addListeners(links)

    return () => {
      removeListeners(links)
    }
  }, [links])

  useEffect(() => {
    if (!contentRef.current) {
      setLinks([])
      return
    }

    const links = Array.from(contentRef.current.getElementsByTagName('a'))
    setLinks(links)

    return () => {
      setLinks([])
    }
  }, [])

  useMutationObserver(
    contentRef.current,
    (mutations: MutationRecord[]) => {
      if (!contentRef.current) {
        setLinks([])
        return
      }

      const links = Array.from(contentRef.current.getElementsByTagName('a') ?? [])
      setLinks(links)
    },
    {
      characterData: false,
      attributes: false,
      childList: true,
      subtree: true,
    }
  )

  return (
    <div ref={contentRef} {...props}>
      {children}
    </div>
  )
}

WithNextLinks.displayName = 'WithNextLinks'

export default WithNextLinks
