import { IconProps } from '@additional'

const PinIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="m11.475 22.8-.006-.005-.074-.068c-1.746-1.591-7.439-7.233-7.439-13.054C3.956 4.891 7.564 1 12 1s8.045 3.891 8.045 8.673c0 5.82-5.693 11.463-7.44 13.054a2.024 2.024 0 0 1-.075.068v.005a.808.808 0 0 1-.525.2.8.8 0 0 1-.53-.2ZM5.646 9.673a12.528 12.528 0 0 0 2.642 6.9A29.664 29.664 0 0 0 12 20.886a29.9 29.9 0 0 0 3.713-4.313 12.537 12.537 0 0 0 2.639-6.9A6.622 6.622 0 0 0 12 2.823a6.623 6.623 0 0 0-6.354 6.85Zm2.629 0A3.881 3.881 0 0 1 12 5.657a3.882 3.882 0 0 1 3.726 4.016A3.884 3.884 0 0 1 12 13.689a3.883 3.883 0 0 1-3.725-4.016Zm1.69 0a2.119 2.119 0 0 0 2.034 2.19 2.121 2.121 0 0 0 2.034-2.19 2.122 2.122 0 0 0-2.034-2.193 2.12 2.12 0 0 0-2.034 2.193Z"
        fill="currentColor"
      />
    </svg>
  )
}

PinIcon.defaultProps = {
  'aria-hidden': true,
}

export default PinIcon
