import { IconProps } from '@additional'

const FilterIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M3.023 18.869H-.001v-2.227H3.13a3.365 3.365 0 0 1 3.12-2.1 3.364 3.364 0 0 1 3.149 2.185 1.074 1.074 0 0 1 .416-.084h14.184v2.227H9.816a1.065 1.065 0 0 1-.327-.051 3.366 3.366 0 0 1-3.24 2.456 3.366 3.366 0 0 1-3.226-2.406Zm3.227.481a1.443 1.443 0 0 0 1.442-1.442 1.443 1.443 0 0 0-1.442-1.442 1.442 1.442 0 0 0-1.436 1.3c0 .059 0 .119-.006.177A1.442 1.442 0 0 0 6.25 19.35Zm6.863-12.3H-.001V5.126H13.38a3.821 3.821 0 0 1 3.529-2.4 3.821 3.821 0 0 1 3.529 2.4h3.561V7.05h-3.3a3.85 3.85 0 0 1-3.8 3.466 3.849 3.849 0 0 1-3.786-3.462Zm2.16-.43a1.653 1.653 0 0 0 1.636 1.67 1.653 1.653 0 0 0 1.636-1.67 1.653 1.653 0 0 0-1.636-1.67 1.653 1.653 0 0 0-1.637 1.677Z"
        fill="currentColor"
      />
    </svg>
  )
}

FilterIcon.defaultProps = {
  'aria-hidden': true,
}

export default FilterIcon
