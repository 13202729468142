import React from 'react'
import cx from 'classnames'
import Container from '@components/common/Container'
import {
  GetEventsQueryVariables,
  GetTeamMembersQueryVariables,
  Set_Components_Team,
} from '@graphql/generated'
import { TeamFilterContextProvider } from '@components/team/filters/Filter'
import TeamFormatFilter from '@components/team/filters/TeamFormatFilter'
import TeamMembersList from '@components/team/TeamMembersList'

type Props = Set_Components_Team

export const defaultGetTeamMembersVariables: GetTeamMembersQueryVariables = {
  filter: {},
  limit: 1000,
}

const Team: React.FC<Props> = (props) => {
  return (
    <section
      className={cx(
        'pt-12 pb-20 md:pt-14 md:pb-20',
        { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
      )}
    >
      <Container fluid className="">
        <TeamFilterContextProvider>
          <div className="mx-auto max-w-6xl space-y-10 md:space-y-14">
            <div className="">
              <TeamFormatFilter />
            </div>

            <TeamMembersList />
          </div>
        </TeamFilterContextProvider>
      </Container>
    </section>
  )
}

Team.defaultProps = {}

export default Team
