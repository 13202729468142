import { IconProps } from '@additional'

const ShoppingCartExplosionIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M12.015 22.732a1.268 1.268 0 1 1 1.268 1.269 1.268 1.268 0 0 1-1.268-1.269Zm-6.726 0a1.268 1.268 0 1 1 1.268 1.269 1.268 1.268 0 0 1-1.268-1.269Zm-.528-2.011L2.432 9.744H.402L.148 8.601h3.363l.4 1.9 13.8.03-1.463 6.948-10.639 1.144.186.893h9.453l-.254 1.206Zm.587-3.327 9.907-1.06.942-4.56H4.175Z" />

      <g className="origin-center scale-0 opacity-0 transition-all duration-100 group-hover:scale-100 group-hover:opacity-100 group-focus:scale-100 group-focus:opacity-100">
        <path d="m23.853 6.361-8.662.894L17.07 0l-5.154 5.8L10.468.732 8.879 5.951 3.813 1.134l3.61 8.214-4.616 1.912H6l2.638-1.321-1.983-4.716 2.68 2.77.986-3.553 1.322 3.281 3.322-4.083-.976 4.7 5.571-.385-4.889 2.92 6.5.388.008-.1-3.445-.872Z" />
      </g>
    </svg>
  )
}

ShoppingCartExplosionIcon.defaultProps = {
  'aria-hidden': true,
}

export default ShoppingCartExplosionIcon
