import React, { HTMLProps } from 'react'
import { Entry_Events_Event } from '@graphql/generated'
import cx from 'classnames'
import {
  addMonths,
  differenceInMonths,
  format,
  getYear,
  isPast,
  isToday,
  parseISO,
  startOfMonth,
} from 'date-fns'
import { de } from 'date-fns/locale'
import Bard from '@components/common/Bard/Bard'
import StatamicImage from '@components/common/StatamicImage'
import LogoSmall from '@components/icons/LogoSmall'
import ArrowLink from '@components/common/ArrowLink'
import ClockIcon from '@components/icons/ClockIcon'
import PinIcon from '@components/icons/PinIcon'
import AppLink from '@components/common/AppLink'

type RequiredProps = {
  event: Entry_Events_Event
}

type OptionalProps = {
  className?: HTMLProps<HTMLDivElement>['className']
}

function formatDate(date?: string | null): string | null {
  if (!date) {
    return null
  }

  const parsedDate = parseISO(date)

  if (isToday(parsedDate)) {
    return 'Heute'
  }

  // If the date is last year or earlier, add the year.
  if (getYear(parsedDate) < getYear(new Date())) {
    return format(parsedDate, 'd MMMM yyyy', { locale: de })
  }

  // If the date is after this month next year or later, add the year.
  if (differenceInMonths(startOfMonth(parsedDate), startOfMonth(new Date())) >= 12) {
    return format(parsedDate, 'd MMMM yyyy', { locale: de })
  }

  return format(parsedDate, 'd MMMM', { locale: de })
}

function formatTime(date?: string | null): string | null {
  if (!date) {
    return null
  }

  const parsedDate = parseISO(date)

  return format(parsedDate, 'HH:mm', { locale: de })
}

const EventCard = React.forwardRef<HTMLDivElement, RequiredProps & OptionalProps>(
  ({ event, className }, ref) => {
    const isAvailable = event.date ? !isPast(parseISO(event.date)) : false

    return (
      <article
        ref={ref}
        className={cx(
          'relative bg-white transition-all md:flex md:shadow-md hover:md:shadow-lg',
          { 'text-gray-400': !isAvailable },
          className
        )}
      >
        {formatDate(event.date) && (
          <div
            className={cx(
              'typo-600 absolute -top-3 -left-3.5 isolate py-2 px-4 text-white after:absolute after:inset-0 after:-z-1 after:-skew-x-13',
              isAvailable ? 'after:bg-red-500' : 'after:bg-gray-400'
            )}
          >
            {formatDate(event.date)}
          </div>
        )}

        {event.image ? (
          <div className="h-40 w-full xs:h-48 sm:h-56 md:h-auto md:min-h-[10rem] md:w-40 md:shrink-0">
            <StatamicImage
              loading="lazy"
              asset={event.image}
              className="h-full w-full object-cover"
            />
          </div>
        ) : (
          <div className="hidden items-center justify-center border-r-2 border-gray-50 md:flex md:h-auto md:min-h-[10rem] md:w-40 md:shrink-0">
            <LogoSmall asset={event.image} className="h-18 w-20" />
          </div>
        )}

        <div className={cx('p-5 md:grow md:px-8 md:py-6', event.image ? 'pt-4' : 'pt-12')}>
          <div className="space-y-3.5 md:space-y-4">
            <div className="space-y-2.5 md:space-y-1.5">
              <p className={cx('typo-700', { 'text-inherit': !isAvailable })}>{event.title}</p>
              <Bard html={event.description} className="typo-200-regular md:max-w-lg" />
            </div>

            <div className="flex flex-col gap-y-5 gap-x-6 sm:gap-y-2 md:min-h-[2.5rem] md:flex-row md:justify-between">
              <div className="typo-200 flex flex-wrap gap-y-2 gap-x-6">
                {formatTime(event.date) && formatTime(event.date) !== '00:00' && (
                  <div className="inline-flex space-x-2">
                    <ClockIcon className="mt-0.5 h-4 w-4" />
                    <span className="leading-tight">{formatTime(event.date)}</span>
                  </div>
                )}
                {event.location &&
                  (event.location_link ? (
                    <AppLink
                      href={event.location_link}
                      className={cx('inline-flex space-x-2 transition hover:text-red-600', {
                        '[@media(hover:none)]:text-red-500': isAvailable,
                      })}
                    >
                      <PinIcon className="mt-0.5 h-4 w-4" />
                      <span className="leading-tight">{event.location}</span>
                    </AppLink>
                  ) : (
                    <div className="inline-flex space-x-2">
                      <PinIcon className="mt-0.5 h-4 w-4" />
                      <span className="leading-tight">{event.location}</span>
                    </div>
                  ))}
              </div>

              <div className="shrink-0 self-end">
                {event.tickets_link &&
                  (isAvailable ? (
                    <ArrowLink
                      href={event.tickets_link}
                      className="space-x-3 hover:text-red-600 [@media(hover:none)]:text-red-500"
                    >
                      <span>Ticket kaufen</span>
                    </ArrowLink>
                  ) : (
                    <ArrowLink disabled={true} className="space-x-3 disabled:hover:text-inherit">
                      <span>Ticket kaufen</span>
                    </ArrowLink>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </article>
    )
  }
)

EventCard.displayName = 'EventCard'

EventCard.defaultProps = {}

export default React.memo(EventCard)
