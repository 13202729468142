import { Asset_Assets, AssetInterface, Maybe, Scalars } from '@graphql/generated'
import CloudinaryImage, { CloudinaryImageProps } from '@components/common/CloudinaryImage'
import React from 'react'

export type StatamicImageProps = {
  asset: AssetInterface & Partial<{ alt: Maybe<Scalars['String']> }>
  alt?: string
  focus?: string
  width?: number
  height?: number
  aspectRatio?: number
  loading?: string
} & Partial<Omit<CloudinaryImageProps, 'src' | 'alt' | 'focal' | 'aspectRatio' | 'ref'>>

function getImageWidth(props: StatamicImageProps): number | undefined {
  if (props.width) {
    return props.width
  }

  if ((props.asset.width && props.asset.height) || (props.asset.width && props.asset.ratio)) {
    return props.asset.width
  }

  if (props.asset.ratio && props.asset.height) {
    return props.asset.height * props.asset.ratio
  }

  return undefined
}

function getImageHeight(props: StatamicImageProps): number | undefined {
  if (props.height) {
    return props.height
  }

  if ((props.asset.height && props.asset.width) || (props.asset.height && props.asset.ratio)) {
    return props.asset.height
  }

  if (props.asset.ratio && props.asset.width) {
    return props.asset.width / props.asset.ratio
  }

  return undefined
}

function getImageAspectRatio(props: StatamicImageProps): number | undefined {
  if (props.aspectRatio) {
    return props.aspectRatio
  }

  if (props.asset.ratio) {
    return props.asset.ratio
  }

  const imageWidth = getImageWidth(props)
  const imageHeight = getImageHeight(props)

  if (imageWidth && imageHeight) {
    return imageWidth / imageHeight
  }

  return undefined
}

function getImageFocus(props: StatamicImageProps): string | [number, number] | undefined {
  if (props.focus) {
    return props.focus
  }

  if (!props.asset.focus_css) {
    return undefined
  }

  if (!props.asset.width || !props.asset.height) {
    return undefined
  }

  let [x, y] = props.asset.focus_css.replace(/%/g, '').split(' ')

  if (parseFloat(x) === 50 || parseFloat(y) === 50) {
    return undefined
  }

  let numX = (parseInt(x) * props.asset.width) / 100
  let numY = (parseInt(y) * props.asset.height) / 100

  return [Math.round(numX), Math.round(numY)]
}

const StatamicImage = React.forwardRef<HTMLImageElement, StatamicImageProps>((props, ref) => {
  const { asset, alt, focus, width, height, aspectRatio, ...leftOverProps } = props

  const assetUrl = asset.permalink?.replace(/https?:\/\/[^/]+/i, '') ?? ''
  const assetAlt = alt ?? asset.alt ?? undefined

  const imageFocus = getImageFocus(props)
  const imageAspectRatio = getImageAspectRatio(props)

  if (!assetUrl) {
    return null
  }

  return (
    <CloudinaryImage
      ref={ref}
      src={assetUrl}
      alt={assetAlt}
      focal={imageFocus}
      aspectRatio={imageAspectRatio}
      {...leftOverProps}
    />
  )
})

StatamicImage.displayName = 'StatamicImage'

export default StatamicImage
