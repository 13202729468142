import React from 'react'
import {
  Set_Components_TitleWithText,
  Set_TeamMemberComponents_TitleWithText,
} from '@graphql/generated'
import Bard from '@components/common/Bard/Bard'
import cx from 'classnames'
import Container from '@components/common/Container'
import ComponentLoader from '@components/component-loader/ComponentLoader'
import Reveal, { RevealChild } from '@components/common/Reveal'

type Props = (Set_Components_TitleWithText | Set_TeamMemberComponents_TitleWithText) & {
  id?: string
}

const TitleWithText: React.FC<Props> = (props) => {
  const textWidth = props.text_width?.value ?? '1_2'

  return (
    <section
      id={props.id}
      className={cx(
        'py-15 md:py-20',
        { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
      )}
    >
      <Container fluid className="">
        <Reveal className="mx-auto flex max-w-6xl flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-8">
          <div
            className={cx('w-full', {
              'md:w-1/2': textWidth === '1_2',
              'md:w-1/3': textWidth === '2_3',
            })}
          >
            <div className="space-y-2">
              <RevealChild>
                <h2 className="typo-1200">{props.title}</h2>
              </RevealChild>

              {props.subtitle && (
                <RevealChild>
                  <p className="typo-600">{props.subtitle}</p>
                </RevealChild>
              )}
            </div>
          </div>

          <div
            className={cx('w-full text-lg md:text-xl', {
              'md:w-1/2': textWidth === '1_2',
              'md:w-2/3': textWidth === '2_3',
            })}
          >
            <RevealChild>
              <ComponentLoader group="bard" components={props.text_components ?? []} />
            </RevealChild>
          </div>
        </Reveal>
      </Container>
    </section>
  )
}

TitleWithText.defaultProps = {}

export default TitleWithText
