import { IconProps } from '@additional'

const ClockIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M7.004 20.652a9.929 9.929 0 0 1-4.663-6.066A10.009 10.009 0 0 1 9.415 2.342a10.006 10.006 0 0 1 12.24 7.069 9.943 9.943 0 0 1-1 7.585 9.918 9.918 0 0 1-6.064 4.661 10 10 0 0 1-2.594.344 9.966 9.966 0 0 1-4.993-1.349ZM9.971 4.416a7.86 7.86 0 0 0-5.556 9.615 7.862 7.862 0 0 0 9.621 5.552 7.794 7.794 0 0 0 4.769-3.657 7.8 7.8 0 0 0 .777-5.96A7.862 7.862 0 0 0 12 4.148a7.828 7.828 0 0 0-2.029.268Zm5.866 11.037-4.373-2.527a1.068 1.068 0 0 1-.5-.649L9.579 7.109a1.078 1.078 0 0 1 .094-.816 1.085 1.085 0 0 1 .649-.512h.012a1.089 1.089 0 0 1 .82.11 1.079 1.079 0 0 1 .5.671l1.27 4.732 3.982 2.3a1.066 1.066 0 0 1 .5.651 1.073 1.073 0 0 1-.1.814 1.057 1.057 0 0 1-.653.5 1.089 1.089 0 0 1-.282.038 1.065 1.065 0 0 1-.534-.144Z"
        fill="currentColor"
      />
    </svg>
  )
}

ClockIcon.defaultProps = {
  'aria-hidden': true,
}

export default ClockIcon
