import React from 'react'
import StatamicImage from '@components/common/StatamicImage'
import { Set_AsideComponents_AsideTeaser } from '@graphql/generated'
import ArrowLink from '@components/common/ArrowLink'
import cx from 'classnames'
import ArrowIcon from '@components/icons/ArrowIcon'
import AppLink from '@components/common/AppLink'
import { Except } from 'type-fest'

type Props = Except<Set_AsideComponents_AsideTeaser, 'type'> & {
  asH1?: boolean
}

const AsideTeaser: React.FC<Props> = (props) => {
  const LinkComp = props.link ? AppLink : 'div'
  const linkProps = props.link
    ? {
        href: props.link,
      }
    : {}

  const TitleComp = props.asH1 ? 'h1' : 'h2'

  return (
    <section>
      <LinkComp
        className={cx(
          'group flex items-center space-x-4 bg-white outline-none transition-all hover:shadow-md focus-visible:ring',
          props.image ? 'py-4 pl-3.5 pr-6' : 'py-6 pl-7 pr-6',
          {
            'bg-white': !props.background_color?.value || props.background_color.value === 'white',
            'bg-blue-700 text-white hover:bg-blue-900': props.background_color?.value === 'petrol',
            'bg-red-500 text-white hover:bg-red-600': props.background_color?.value === 'red',
          }
        )}
        {...linkProps}
      >
        {props.image && (
          <div className="h-20 w-20 shrink-0 overflow-hidden rounded-full">
            <StatamicImage
              loading="lazy"
              asset={props.image}
              aspectRatio={1}
              className="h-full w-full object-cover transition-transform group-hover:scale-110"
            />
          </div>
        )}

        <div className="grow space-y-3">
          <TitleComp
            className={cx('typo-600', {
              'text-white': ['red', 'petrol'].includes(props.background_color?.value ?? 'white'),
            })}
          >
            {props.title}
          </TitleComp>
          {props.link && (
            <div
              className={cx('typo-200 flex items-center justify-between transition', {
                'group-hover:text-red-500':
                  !props.background_color?.value || props.background_color.value === 'white',
                'text-white': ['red', 'petrol'].includes(props.background_color?.value ?? 'white'),
              })}
            >
              {props.link_text}

              <ArrowIcon className="h-5 w-5" />
            </div>
          )}
        </div>
      </LinkComp>
    </section>
  )
}

AsideTeaser.defaultProps = {
  asH1: false,
}

export default AsideTeaser
