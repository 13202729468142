import { IconProps } from '@additional'

const LogoSmall: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.453 67.864" {...props}>
      <path
        d="m18.613 26.992-17.1 3.534 19.956 3.906L0 47.746l23.143-3.769-5.521 8.652 10.065-3.99-6.155 19.225 17.042-18.43 3.94 16.107 5.036-16.576L63.5 64.129 52.11 38.297l24.343-6.368-25.066-3.665 11.371 4.294-14.47 3.668L55.3 52.408l-9.062-9.386L43 54.673l-3.018-10.789-12.3 13.486 4.748-14.456-8.524 4.114 4.749-6.6-16.183 2.594 15.994-9.471-13.522-3.267 12.65-1.518L1.62 17.221 0 16.572Z"
        fill="#FE510B"
      />
      <path d="m68.4 47.142-11.5-6.243.959 2.243Z" fill="#FE510B" />
      <path d="m8.038 53.1 6.629-4.128-4.62.792Z" fill="#FE510B" />
      <path d="m22.472 20.296-.55 2.678L10.415 11.38Z" fill="#FE510B" />
      <g fill="#19404A">
        <path d="m43.45 30.221 1.387-6.791a2.616 2.616 0 0 0 1.053 1.279 4.033 4.033 0 0 0 2.128.455 7.327 7.327 0 0 0 2.066-.247 3.147 3.147 0 0 0 1.384-.826 4.817 4.817 0 0 0 .951-1.551 13.709 13.709 0 0 0 .682-2.418l3.219-15.08a12.2 12.2 0 0 0 .372-2.6A2.158 2.158 0 0 0 55.93.516 4.8 4.8 0 0 0 53.348 0a5.963 5.963 0 0 0-2.479.455 4.915 4.915 0 0 0-1.818 1.529L49.42.206h-3.925L39.239 30.22Zm5.188-25.179a3.155 3.155 0 0 1 .888-.93 2.1 2.1 0 0 1 1.219-.351 2.2 2.2 0 0 1 1.136.206.931.931 0 0 1 .31.826 5.454 5.454 0 0 1-.124 1.033L49.175 19.3a3.358 3.358 0 0 1-.661 1.651 2.086 2.086 0 0 1-1.488.414 1.494 1.494 0 0 1-1.611-1.281Z" />
        <path d="M38.847 32.033a3.244 3.244 0 0 1-.238.7l2.784 2.139a5.188 5.188 0 0 0 1.214-1.543 5.96 5.96 0 0 0 .641-2.1h-4.229s-.089.436-.172.8" />
        <path d="M37.526 33.672v1.593a3.012 3.012 0 0 0 3.322 0l-2.626-1.993a1.7 1.7 0 0 1-.7.4" />
        <path d="M34.561 34.798c.008.011.017-.025.077-.044a.075.075 0 0 1 .062.022.165.165 0 0 1 .051.092c.015.1-.03.151-.011.154s.018-.077.06-.128c.021-.026.026-.038.111-.044a.108.108 0 0 1 .1.032c.069.065.063.122.085.118s-.023-.049-.018-.148c0-.05-.008-.046.026-.079a.07.07 0 0 1 .091-.016c.077.039.087.066.1.052a1.248 1.248 0 0 1-.188-.3 5.134 5.134 0 0 1 .041-.911.36.36 0 0 1 .172-.252.278.278 0 0 1 .293.051 6.275 6.275 0 0 1 .794.922c.231.269.395.435.6.652v-1.274c-.513-.084-.718-.318-1.169-.781-.275-.282-.483-.249-.641-.177-.5.23-.443 1.629-.469 1.768s-.175.274-.164.288" />
        <path d="M35.06 34.48v-.01l-.008.01Z" />
        <path d="M35.338 35.904a.175.175 0 1 1 .175.175.175.175 0 0 1-.175-.175" />
        <path d="M34.276 36.47a.259.259 0 1 1 .259.259.259.259 0 0 1-.259-.259" />
        <path d="M33.764 35.848a.123.123 0 1 1 .123.123.123.123 0 0 1-.123-.123" />
        <path d="M34.47 37.225a.152.152 0 1 1 .152.152.152.152 0 0 1-.152-.152" />
        <path d="M33.699 37.373a.171.171 0 1 1 .171.171.171.171 0 0 1-.171-.171" />
        <path d="M34.056 38.222a.107.107 0 1 1 .107.107.107.107 0 0 1-.107-.107" />
      </g>
    </svg>
  )
}

LogoSmall.defaultProps = {
  'aria-hidden': true,
}

export default LogoSmall
