import Link, { LinkProps } from 'next/link'
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  PropsWithChildren,
} from 'react'
import { isExternalUrl } from '@lib/isExternalUrl'

export type AppLinkProps =
  | (AnchorHTMLAttributes<HTMLAnchorElement> & Pick<LinkProps, 'replace' | 'scroll'>)
  | ButtonHTMLAttributes<HTMLButtonElement>

const AppLink = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  PropsWithChildren<AppLinkProps>
>(({ children, ...otherProps }, ref) => {
  // @ts-ignore
  let { replace, scroll, ...props } = otherProps

  if ('href' in props) {
    if (!props.href) {
      props.href = ''
    }

    if (isExternalUrl(props.href)) {
      props.target = '_blank'
      props.rel = 'noopener noreferrer'
    }

    if (props.href.startsWith('#')) {
      scroll = scroll ?? false
    }

    return (
      <Link href={props.href} replace={replace} scroll={scroll}>
        <a ref={ref as ForwardedRef<HTMLAnchorElement>} {...props}>
          {children}
        </a>
      </Link>
    )
  }

  props as ButtonHTMLAttributes<HTMLButtonElement>

  if (!props.type) {
    props.type = 'button'
  }

  return (
    // @ts-ignore
    <button ref={ref as ForwardedRef<HTMLButtonElement>} {...props}>
      {children}
    </button>
  )
})

AppLink.displayName = 'AppLink'

export default AppLink
