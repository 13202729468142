import { IconProps } from '@additional'

const Logo: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150.721 92.484" {...props}>
      <g data-name="Group 3">
        <g fill="currentColor">
          <path
            data-name="Path 1"
            d="m125.386 0-20.052 21.685-4.636-18.952-5.926 19.5L76.006 4.394l13.4 30.394-28.642 7.492 29.493 4.313-13.379-5.052 17.025-4.315-8.251-19.041 10.662 11.044 3.808-13.709 3.555 12.694 14.471-15.868-5.585 17.011 10.03-4.841-5.588 7.761 19.041-3.047-18.818 11.142 15.909 3.848-14.884 1.786 30.561 13.581 1.9.763-21.9-12.26 20.126-4.158-23.481-4.6 25.262-15.662-27.231 4.43 6.5-10.18-11.843 4.7Z"
          />
          <path data-name="Path 2" d="m70.238 24.382 13.535 7.346-1.134-2.641Z" />
          <path data-name="Path 3" d="m141.262 17.371-7.8 4.857 5.436-.931Z" />
          <path data-name="Path 4" d="m124.279 55.97.647-3.151 13.539 13.641Z" />
        </g>
        <g data-name="Group 2">
          <g data-name="Group 1" fill="#14404a">
            <path
              data-name="Path 5"
              d="M22.055 53.11a2.586 2.586 0 0 0-.874-2.238 5.362 5.362 0 0 0-3.015-.631 6.916 6.916 0 0 0-2.941.558 5.593 5.593 0 0 0-2.115 1.824l.486-2.14H8.977L0 92.484h4.959L8.1 77.856a2.955 2.955 0 0 0 1.239 1.484 5.064 5.064 0 0 0 2.561.51 9.365 9.365 0 0 0 2.431-.267 3.49 3.49 0 0 0 1.628-.949 5.343 5.343 0 0 0 1.094-1.823 20.972 20.972 0 0 0 .826-2.894l3.793-17.745a16.612 16.612 0 0 0 .389-3.063m-5.4 4.034-3.4 15.851a4.233 4.233 0 0 1-.827 1.945 2.455 2.455 0 0 1-1.749.485 1.755 1.755 0 0 1-1.9-1.507l3.792-17.7a3.75 3.75 0 0 1 1.045-1.093 2.486 2.486 0 0 1 1.435-.413 2.533 2.533 0 0 1 1.361.267 1.13 1.13 0 0 1 .389 1 6.331 6.331 0 0 1-.145 1.165"
            />
            <path
              data-name="Path 6"
              d="m26.335 73.626 1.459-6.856h8.654l2.238-10.6a15.2 15.2 0 0 0 .436-3.161 2.368 2.368 0 0 0-1.313-2.48 12.736 12.736 0 0 0-4.376-.534 26.817 26.817 0 0 0-3.548.194 5.014 5.014 0 0 0-2.31.851 4.481 4.481 0 0 0-1.433 1.871 17.3 17.3 0 0 0-.972 3.258l-3.793 17.745a12.127 12.127 0 0 0-.316 1.7c-.048.485-.073.94-.073 1.361a2.442 2.442 0 0 0 1.362 2.553 13.626 13.626 0 0 0 4.668.558 23.811 23.811 0 0 0 3.354-.193 4.575 4.575 0 0 0 2.165-.851 4.7 4.7 0 0 0 1.385-1.872 17.444 17.444 0 0 0 .972-3.258l.924-4.229h-4.475l-.827 3.938a3.391 3.391 0 0 1-.778 1.824 2.884 2.884 0 0 1-1.8.412 3.5 3.5 0 0 1-1.386-.2.813.813 0 0 1-.413-.825 3.218 3.218 0 0 1 .049-.535q.047-.29.146-.68m3.6-17.065a3.35 3.35 0 0 1 .754-1.8 2.987 2.987 0 0 1 1.823-.391 3.342 3.342 0 0 1 1.36.2.83.83 0 0 1 .389.827 6.326 6.326 0 0 1-.146 1.167l-1.312 6.233H28.62Z"
            />
            <path
              data-name="Path 7"
              d="M47.92 54.519h3.4l.875-4.036h-3.4l1.9-8.848h-4.959l-1.9 8.848h-1.8l-.875 4.036h1.8l-4.036 18.912a7.341 7.341 0 0 0-.194 1.12c-.033.358-.049.7-.049 1.02a3.506 3.506 0 0 0 1.361 3.063q1.361.973 4.667.973h1.264l.876-4.231h-.924a3.12 3.12 0 0 1-1.58-.291 1.219 1.219 0 0 1-.461-1.118 6.332 6.332 0 0 1 .145-1.167Z"
            />
            <path
              data-name="Path 8"
              d="M67.633 54.446a8.493 8.493 0 0 0 .122-1.336 2.432 2.432 0 0 0-1.41-2.553 14.1 14.1 0 0 0-4.715-.558 23.31 23.31 0 0 0-3.307.194 4.578 4.578 0 0 0-2.163.851 4.687 4.687 0 0 0-1.386 1.871 17.37 17.37 0 0 0-.972 3.258l-.729 3.451h4.52l.584-2.869a4.354 4.354 0 0 1 .778-1.993 2.306 2.306 0 0 1 1.75-.534 3.382 3.382 0 0 1 1.41.218.906.906 0 0 1 .437.9 5.9 5.9 0 0 1-.194 1.409l-1.265 6.03a2.65 2.65 0 0 0-.948-.318 14.879 14.879 0 0 0-2.309-.12h-.923a5.13 5.13 0 0 0-3.7 1.216 7.209 7.209 0 0 0-1.8 3.841l-1.507 7.05a14.119 14.119 0 0 0-.389 2.772 3.211 3.211 0 0 0 .291 1.482 2.007 2.007 0 0 0 .924.874 4.65 4.65 0 0 0 1.628.413c.664.066 1.483.1 2.455.1q2.917 0 4.741-.12a17.188 17.188 0 0 0 2.8-.365l4.959-23.434q.194-.973.316-1.727m-9.31 21.465a3.938 3.938 0 0 1-.68.073c-.325.017-.843.024-1.556.024a2.673 2.673 0 0 1-1.313-.22.936.936 0 0 1-.34-.85 4.552 4.552 0 0 1 .072-.756c.05-.274.106-.591.172-.947l.973-4.57a10.606 10.606 0 0 1 .315-1.24 2.208 2.208 0 0 1 .438-.8 1.46 1.46 0 0 1 .7-.413 6.646 6.646 0 0 1 3.209.171Z"
            />
            <path
              data-name="Path 9"
              d="M85.405 53.25a2.781 2.781 0 0 0-.875-2.358 4.656 4.656 0 0 0-2.771-.656 6.864 6.864 0 0 0-3.039.607 5.359 5.359 0 0 0-1.969 1.58l.437-1.945h-4.619l-6.175 29.124h4.959l4.911-23.289a2.814 2.814 0 0 1 2.528-1.6 2.586 2.586 0 0 1 1.337.243.973.973 0 0 1 .364.875v.438a1.313 1.313 0 0 1-.1.487l-1.409 6.659h4.478l1.556-7.243a15.088 15.088 0 0 0 .389-2.918"
            />
            <path
              data-name="Path 10"
              d="M118.677 50.532a12.736 12.736 0 0 0-4.376-.534 26.81 26.81 0 0 0-3.548.194 5.011 5.011 0 0 0-2.31.851 4.484 4.484 0 0 0-1.434 1.871 17.235 17.235 0 0 0-.972 3.258l-3.793 17.745a12.169 12.169 0 0 0-.316 1.7c-.049.485-.073.94-.073 1.361a2.443 2.443 0 0 0 1.361 2.553 13.623 13.623 0 0 0 4.667.558 23.822 23.822 0 0 0 3.355-.193 4.571 4.571 0 0 0 2.164-.851 4.7 4.7 0 0 0 1.386-1.872 17.423 17.423 0 0 0 .972-3.258l.924-4.229h-4.473l-.827 3.938a3.378 3.378 0 0 1-.778 1.824 2.88 2.88 0 0 1-1.8.412 3.5 3.5 0 0 1-1.386-.2.812.812 0 0 1-.413-.825 3.219 3.219 0 0 1 .049-.535q.048-.29.146-.68l1.458-6.856h8.654l2.238-10.6a15.178 15.178 0 0 0 .436-3.161 2.368 2.368 0 0 0-1.313-2.48m-3.7 6.03-1.305 6.235h-4.18l1.312-6.223a3.343 3.343 0 0 1 .754-1.8 2.989 2.989 0 0 1 1.824-.391 3.344 3.344 0 0 1 1.361.2.831.831 0 0 1 .389.827 6.3 6.3 0 0 1-.145 1.167"
            />
            <path
              data-name="Path 11"
              d="m99.597 44.291-1.632 7.99a3.077 3.077 0 0 0-1.239-1.505 4.745 4.745 0 0 0-2.5-.535 8.622 8.622 0 0 0-2.431.291 3.7 3.7 0 0 0-1.628.972 5.669 5.669 0 0 0-1.119 1.824 16.128 16.128 0 0 0-.8 2.845l-3.792 17.745a14.358 14.358 0 0 0-.438 3.063 2.539 2.539 0 0 0 .9 2.262 5.653 5.653 0 0 0 3.038.607 7.016 7.016 0 0 0 2.917-.535 5.783 5.783 0 0 0 2.139-1.8l-.438 2.091h4.619l7.361-35.315Zm-6.1 29.626a3.711 3.711 0 0 1-1.045 1.094 2.479 2.479 0 0 1-1.435.413 2.593 2.593 0 0 1-1.337-.242 1.1 1.1 0 0 1-.364-.972 6.418 6.418 0 0 1 .146-1.216l3.4-15.851a3.95 3.95 0 0 1 .778-1.943 2.454 2.454 0 0 1 1.751-.487 1.758 1.758 0 0 1 1.9 1.507Z"
            />
            <path
              data-name="Path 12"
              d="M105.013 42.159a3.807 3.807 0 0 1 .28-.822l-3.275-2.517a6.108 6.108 0 0 0-1.429 1.816 7.013 7.013 0 0 0-.754 2.466h4.976s.1-.513.2-.943"
            />
            <path
              data-name="Path 13"
              d="M106.567 40.231V38.36a3.544 3.544 0 0 0-3.909 0l3.09 2.346a2 2 0 0 1 .818-.469"
            />
            <path
              data-name="Path 14"
              d="M110.055 38.904c-.01-.013-.02.03-.09.051a.088.088 0 0 1-.073-.026.192.192 0 0 1-.06-.108c-.018-.114.035-.177.013-.181s-.021.09-.071.151c-.025.031-.031.045-.131.051a.128.128 0 0 1-.115-.037c-.081-.077-.074-.144-.1-.139s.027.057.021.174c0 .058.009.054-.03.093a.083.083 0 0 1-.107.018c-.09-.045-.1-.077-.112-.061a1.467 1.467 0 0 1 .221.348 6.037 6.037 0 0 1-.049 1.072.423.423 0 0 1-.2.3.327.327 0 0 1-.344-.06 7.4 7.4 0 0 1-.934-1.085c-.272-.316-.464-.512-.71-.767v1.5c.6.1.845.374 1.376.919.323.331.569.293.754.208.593-.271.522-1.917.551-2.08s.206-.322.193-.339"
            />
            <path data-name="Path 15" d="M109.469 39.283v.011l.009-.011Z" />
            <path
              data-name="Path 16"
              d="M109.142 37.602a.206.206 0 1 1-.206-.206.205.205 0 0 1 .206.206"
            />
            <path data-name="Path 17" d="M110.391 36.942a.3.3 0 1 1-.3-.3.3.3 0 0 1 .3.3" />
            <path
              data-name="Path 18"
              d="M110.993 37.67a.144.144 0 1 1-.144-.145.144.144 0 0 1 .144.145"
            />
            <path
              data-name="Path 19"
              d="M110.163 36.047a.179.179 0 1 1-.179-.179.179.179 0 0 1 .179.179"
            />
            <path data-name="Path 20" d="M111.069 35.876a.2.2 0 1 1-.2-.2.2.2 0 0 1 .2.2" />
            <path
              data-name="Path 21"
              d="M110.65 34.877a.125.125 0 1 1-.125-.126.125.125 0 0 1 .125.126"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  'aria-hidden': true,
}

export default Logo
