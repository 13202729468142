import React, { HTMLProps } from 'react'
import { Entry_Posts_Post } from '@graphql/generated'
import cx from 'classnames'
import NextImage from 'next/image'
import { format, isToday, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import ShareIcon from '@components/icons/ShareIcon'
import AppLink from '@components/common/AppLink'
import Link from '@components/common/Link'
import ShareButton from '@components/common/ShareButton'
import LikeButton from '@components/news/LikeButton'
import PostMediaCarousel from '@components/news/PostMediaCarousel'
import StatamicImage from '@components/common/StatamicImage'
import Avatar from '@components/common/Avatar'
import NextLink from 'next/link'

type RequiredProps = {
  post: Entry_Posts_Post
}

type OptionalProps = {
  autoHeight?: boolean
  size?: 'normal' | 'small'
  lazy?: boolean
  className?: HTMLProps<HTMLDivElement>['className']
}

function formatDate(date?: string | null): string | null {
  if (!date) {
    return null
  }

  const parsedDate = parseISO(date)

  const day = isToday(parsedDate) ? 'Heute' : format(parsedDate, 'd. MMMM yyyy', { locale: de })
  const time = format(parsedDate, 'HH:mm', { locale: de })

  return `${day}, ${time} Uhr`
}

const PostCard = React.forwardRef<HTMLDivElement, RequiredProps & OptionalProps>(
  ({ post, autoHeight, size, lazy, className }, ref) => {
    return (
      <article ref={ref} className={cx('flex flex-col bg-white transition-all', className)}>
        <NextLink href={post.permalink!}>
          <a
            tabIndex={-1}
            className={cx(
              'peer relative grow outline-none',
              'after:pointer-events-none after:absolute after:inset-0 after:z-1 after:bg-radial-gradient after:opacity-0 after:transition hover:after:opacity-[0.1]'
            )}
          >
            <span className="sr-only">Beitrag &quot;{post?.title}&quot; öffnen</span>
            <PostMediaCarousel
              post={post}
              lazy={lazy}
              autoHeight={autoHeight}
              contentSize={size}
              className="flex h-full items-start justify-center"
            />
          </a>
        </NextLink>

        <div
          className={cx('shrink-0 pl-4 pr-6 pb-4 pt-3', {
            'flex items-end sm:block sm:space-y-1': size === 'normal',
            'flex items-end space-x-6': size === 'small',
          })}
        >
          <AppLink
            href={post.url!}
            className={cx(
              'block space-y-1 outline-none ring-offset-2 focus-visible:ring',
              'transition hover:text-red-500 [.peer:hover+*_&]:text-red-500',
              {
                'flex-grow sm:flex-grow-0': size === 'normal',
                'flex-grow': size === 'small',
              }
            )}
          >
            <p
              title="Veröffentlichungsdatum"
              className={cx('text-gray-500', {
                'typo-100-regular text-xs': size === 'small',
                'typo-100-regular': size === 'normal',
              })}
            >
              {post.date && (
                // @ts-ignore
                <time pubdate="pubdate" dateTime={post.date}>
                  {formatDate(post.date)}
                </time>
              )}
            </p>
            <p
              className={cx([
                'line-clamp-2',
                {
                  'typo-200 md:typo-300': size === 'small',
                  'typo-300 md:typo-400': size === 'normal',
                },
              ])}
            >
              {post?.title}
            </p>
          </AppLink>
          <div className="flex items-center justify-between space-x-6">
            {size === 'normal' && (
              <div className="hidden h-8 space-x-4 overflow-y-hidden sm:flex">
                {post?.author &&
                  (post.author.team_member?.url ? (
                    <Link
                      href={post.author.team_member.url}
                      className="typo-200 my-0.5 inline-flex items-center space-x-2"
                    >
                      <Avatar user={post.author} className="h-6 w-6" />
                      <span>{post.author.name}</span>
                    </Link>
                  ) : (
                    <div className="typo-200-regular my-0.5 inline-flex items-center space-x-2">
                      <Avatar user={post.author} className="h-6 w-6" />
                      <span>{post.author.name}</span>
                    </div>
                  ))}
                <ul title="Tags" className="inline-flex flex-wrap gap-3 p-1">
                  {post.tags?.map(
                    (tag) =>
                      tag && (
                        <li key={tag.slug}>
                          <AppLink
                            href={`/?tags=${encodeURI(JSON.stringify([tag.slug]))}`}
                            className="typo-100-regular inline-block bg-blue-200 px-3 py-1 text-blue-900 outline-none transition hover:bg-blue-300 focus-visible:ring"
                          >
                            {tag.title}
                          </AppLink>
                        </li>
                      )
                  )}
                </ul>
              </div>
            )}

            <div className="flex items-center space-x-6">
              <ShareButton
                value={post.permalink}
                title={post.title}
                className={cx(
                  'typo-200 items-center space-x-2 outline-none ring-offset-2 transition hover:text-red-500 focus-visible:ring',
                  {
                    'hidden sm:inline-flex': size === 'normal',
                    hidden: size === 'small',
                  }
                )}
              >
                <ShareIcon className="h-5 w-5" />
                <span>Share</span>
              </ShareButton>
              <LikeButton
                post={post}
                size={size}
                className={cx('whitespace-nowrap', {
                  'typo-100 sm:typo-200': size === 'normal',
                  'typo-100': size === 'small',
                })}
              >
                {(likes) => (
                  <>
                    <span>{likes}</span>
                    {size === 'normal' && <span className="hidden sm:inline"> Likes</span>}
                  </>
                )}
              </LikeButton>
            </div>
          </div>
        </div>
      </article>
    )
  }
)

PostCard.displayName = 'PostCard'

PostCard.defaultProps = {
  size: 'normal',
  lazy: true,
}

export default React.memo(PostCard)
