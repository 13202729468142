import { IconProps } from '@additional'

const ProfileExplosionIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M16.183 24.001a5.423 5.423 0 0 0 .059-.8 6.055 6.055 0 0 0-6.24-5.833 6.054 6.054 0 0 0-6.24 5.833 5.423 5.423 0 0 0 .059.8H2.449a7.037 7.037 0 0 1-.046-.8 7.276 7.276 0 0 1 5.664-6.956 3.592 3.592 0 0 1-1.665-3.032 3.6 3.6 0 0 1 3.6-3.6 3.6 3.6 0 0 1 3.6 3.6 3.592 3.592 0 0 1-1.665 3.032 7.276 7.276 0 0 1 5.665 6.956 7.036 7.036 0 0 1-.047.8ZM7.762 13.213a2.241 2.241 0 0 0 2.24 2.238 2.241 2.241 0 0 0 2.24-2.238 2.24 2.24 0 0 0-2.24-2.238 2.24 2.24 0 0 0-2.241 2.237Z" />

      <g className="origin-center scale-0 opacity-0 transition-all duration-100 group-hover:scale-100 group-hover:opacity-100 group-focus:scale-100 group-focus:opacity-100">
        <path d="m23.97 7.247-9.869 1.018 2.141-8.266-5.872 6.6L8.72.825 6.912 6.774 1.14 1.286l4.113 9.359-5.259 2.179h3.638l3.005-1.506-2.259-5.373L7.431 9.1l1.124-4.048 1.506 3.738 3.785-4.652-1.111 5.355 6.347-.438-5.57 3.327 7.4.442.009-.116-3.925-.993Z" />
      </g>
    </svg>
  )
}

ProfileExplosionIcon.defaultProps = {
  'aria-hidden': true,
}

export default ProfileExplosionIcon
