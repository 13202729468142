import React from 'react'
import { Set_Components_ThreeIconCards } from '@graphql/generated'
import cx from 'classnames'
import Container from '@components/common/Container'
import AppLink from '@components/common/AppLink'
import TitleWithText from '@components/component-loader/components/TitleWithText'
import Reveal, { RevealChild } from '@components/common/Reveal'

type Props = Set_Components_ThreeIconCards

// Source: https://gist.github.com/codeguy/6684588?permalink_comment_id=3243980#gistcomment-3243980
function slugify(text: string): string {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
}

const ThreeIconCards: React.FC<Props> = (props) => {
  return (
    <>
      <section
        className={cx(
          { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
        )}
      >
        <Container fluid className="">
          <Reveal className="mx-auto max-w-6xl pt-12 pb-18 md:py-25">
            <div className="-m-2 flex flex-row flex-wrap justify-between md:justify-center md:gap-x-8 lg:gap-x-12 xl:gap-x-16">
              {props.icon_cards?.map((card, i) =>
                !card ? null : (
                  <RevealChild key={i} className="aspect-square w-1/2 p-2 md:w-50 lg:w-64 xl:w-80">
                    <AppLink
                      href={'#' + slugify(card.title ?? '')}
                      className={cx(
                        'group relative isolate flex h-full w-full flex-col items-center justify-between p-2 py-5 outline-none focus-visible:ring md:p-4 md:pb-6 lg:p-10',
                        'before:absolute before:inset-0 before:-z-1 before:block before:transition-transform [@media(hover:hover)]:md:hover:before:translate-x-[11.5%] [@media(hover:hover)]:md:hover:before:-skew-x-13',
                        {
                          transparent: 'before:bg-gray-50',
                          white: 'before:bg-gray-50',
                          gray: 'before:bg-white',
                        }[props.background_color?.value ?? 'transparent']
                      )}
                    >
                      <div className="relative flex grow items-center justify-center self-stretch transition-transform [@media(hover:hover)]:md:group-hover:translate-x-[20%]">
                        {card.icon?.permalink && (
                          <img
                            aria-hidden="true"
                            src={card.icon.permalink}
                            alt={`Icon für ${card.icon_text}`}
                            className={cx('w-2/3 lg:w-4/5', {
                              'transition duration-100 group-hover:opacity-0': !!card.icon_hover,
                            })}
                          />
                        )}
                        {card.icon_hover?.permalink && (
                          <img
                            aria-hidden="true"
                            src={card.icon_hover.permalink}
                            alt={`Icon für ${card.icon_text}`}
                            className="absolute left-1/2 top-1/2 w-2/3 -translate-x-1/2 -translate-y-1/2 opacity-0 transition duration-100 group-hover:opacity-100 lg:w-4/5"
                          />
                        )}
                      </div>
                      <p className="typo-600 xl:typo-700 w-full shrink-0 text-center transition-all [@media(hover:hover)]:md:group-hover:translate-x-[8%] [@media(hover:hover)]:md:group-hover:text-red-600">
                        {card.icon_text}
                      </p>
                    </AppLink>
                  </RevealChild>
                )
              )}
            </div>
          </Reveal>
        </Container>
      </section>

      {props.icon_cards?.map((card, i) =>
        !card ? null : (
          <TitleWithText
            key={i}
            type="title_with_text"
            id={card.title ? slugify(card.title) : undefined}
            text_width={card.text_width}
            background_color={card.background_color}
            title={card.title}
            subtitle={card.subtitle}
            text_components={card.text_components}
          />
        )
      )}
    </>
  )
}

ThreeIconCards.defaultProps = {}

export default ThreeIconCards
