import React, { useState } from 'react'

type ReturnType = string

export type BardMap = {
  [key: string]:
    | string
    | {
        tag: string
        className?: string
      }
}

const useBardMapping = (html?: string | null, map: BardMap = {}): ReturnType => {
  if (!html) {
    return ''
  }

  if (Object.keys(map).length <= 0) {
    return html
  }

  return html.replace(/<(\/?)([^\s>]+)(“[^”]*”|'[^’]*’|[^'”>])*>/g, (match, endTagSymbol, tag) => {
    let config = map[tag]

    if (!config) {
      return match
    }

    const newTag = typeof config === 'string' ? config : config.tag
    const className = typeof config === 'string' ? null : config.className

    return match.replace(new RegExp(`<${endTagSymbol}${tag}`), () => {
      if (endTagSymbol) {
        return `<${endTagSymbol}${newTag}`
      }

      return `<${newTag}` + (className ? ` class="${className}"` : '')
    })
  })
}

export default useBardMapping
