import React, { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import { Combobox } from '@headlessui/react'
import ChevronIcon from '@components/icons/ChevronIcon'
import { AnimatePresence, motion } from 'framer-motion'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import {
  Entry_Team_TeamMember,
  Maybe,
  Term_Formats_Format,
  Term_Tags_Tag,
  useGetAuthorsQuery,
  useGetFormatsQuery,
  useGetTagsQuery,
  useGetTeamMembersQuery,
  User,
} from '@graphql/generated'
import CrossIcon from '@components/icons/CrossIcon'
import Autocomplete, { AutocompleteOption } from '@components/forms/fields/Autocomplete'

type Option = Term_Tags_Tag

const TagsFilter: React.FC<{}> = (props) => {
  const router = useRouter()
  const queryLinkBuilder = useQueryLinkBuilder()
  const [searchQuery, setSearchQuery] = useState('')
  const tagsQuery = useGetTagsQuery(
    {
      limit: 30,
      query: searchQuery,
    },
    {
      staleTime: 60,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const currentTags: string[] = router.query.tags
    ? typeof router.query.tags === 'string' && /^\[.*\]$/.test(router.query.tags ?? '')
      ? JSON.parse(router.query.tags)
      : Array.isArray(router.query.tags)
      ? router.query.tags
      : [router.query.tags]
    : []

  const tags = (tagsQuery.data?.terms?.data ?? [])
    .map((tag) =>
      !tag
        ? null
        : {
            key: tag.slug,
            title: tag.title,
          }
    )
    .filter((a) => a)

  const selectedTags = tags.filter((tag) => tag && currentTags.includes(tag.key))
  const tagsWithoutSelected = tags.filter((tag) => tag && !currentTags.includes(tag.key))

  const update = (value: Array<Maybe<AutocompleteOption>>) => {
    const stringValues = value.flatMap((o) => (o ? [o.key] : []))
    router.replace(queryLinkBuilder('tags', stringValues.length ? stringValues : undefined))
  }

  return (
    <Autocomplete
      label="Tags"
      selectedLabel="Ausgewählte Tags"
      removeLabel={(option) => `Tagfilter "${option.title}" entfernen`}
      value={selectedTags}
      options={tags}
      isLoading={tagsQuery.isLoading}
      onSearch={(query) => setSearchQuery(query)}
      onChange={update}
    />
  )
}

export default TagsFilter
