import { IconProps } from '@additional'

const FacebookIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M13.25 8.875v-2.5a1.25 1.25 0 0 1 1.25-1.25h1.25V2h-2.5A3.75 3.75 0 0 0 9.5 5.75v3.125H7V12h2.5v10h3.75V12h2.5L17 8.875Z"
        fill="currentColor"
      />
    </svg>
  )
}

FacebookIcon.defaultProps = {
  'aria-hidden': true,
}

export default FacebookIcon
