import React, { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import { Combobox } from '@headlessui/react'
import ChevronIcon from '@components/icons/ChevronIcon'
import { AnimatePresence, motion } from 'framer-motion'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import { Maybe, Term_Formats_Format, useGetFormatsQuery } from '@graphql/generated'
import CrossIcon from '@components/icons/CrossIcon'
import Autocomplete, { AutocompleteOption } from '@components/forms/fields/Autocomplete'

type Option = Term_Formats_Format

const FormatFilter: React.FC<{}> = (props) => {
  const router = useRouter()
  const queryLinkBuilder = useQueryLinkBuilder()
  const [searchQuery, setSearchQuery] = useState('')
  const formatsQuery = useGetFormatsQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const currentFormats: string[] = router.query.formats
    ? typeof router.query.formats === 'string' && /^\[.*\]$/.test(router.query.formats ?? '')
      ? JSON.parse(router.query.formats)
      : Array.isArray(router.query.formats)
      ? router.query.formats
      : [router.query.formats]
    : []

  const formats = (formatsQuery.data?.terms?.data ?? [])
    .map((format) =>
      !format
        ? null
        : {
            key: format.slug,
            title: format.title,
          }
    )
    .filter((f) => f)

  const selectedFormats = formats.filter((format) => format && currentFormats.includes(format.key))
  const formatsWithoutSelected = formats.filter(
    (format) => format && !currentFormats.includes(format.key)
  )
  const filteredFormats =
    searchQuery === ''
      ? formats
      : formats.filter((format) =>
          format?.title.toLowerCase().includes(searchQuery.toLowerCase())
        ) ?? []

  const update = (value: Array<Maybe<AutocompleteOption>>) => {
    const stringValues = value.flatMap((o) => (o ? [o.key] : []))
    router.replace(queryLinkBuilder('formats', stringValues.length ? stringValues : undefined))
  }

  return (
    <Autocomplete
      label="Formate"
      selectedLabel="Ausgewählte Formate"
      removeLabel={(option) => `Formatfilter "${option.title}" entfernen`}
      value={selectedFormats}
      options={filteredFormats}
      onSearch={(query) => setSearchQuery(query)}
      onChange={update}
    />
  )
}

export default FormatFilter
