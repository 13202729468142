import React, { HTMLProps } from 'react'
import { User } from '@graphql/generated'
import StatamicImage from '@components/common/StatamicImage'
import cx from 'classnames'

const Avatar: React.FC<{
  user: User
  className?: HTMLProps<HTMLDivElement>['className']
}> = ({ user, className }) => {
  return (
    <span
      className={cx(
        'inline-flex items-center justify-center overflow-hidden rounded-full bg-red-500',
        className
      )}
    >
      {user.avatar ? (
        <StatamicImage
          loading="lazy"
          asset={user.avatar}
          aspectRatio={1}
          className="h-full w-full object-cover"
        />
      ) : (
        <span className="typo-100-regular text-white">{user.initials}</span>
      )}
    </span>
  )
}

export default Avatar
