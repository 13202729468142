import { Maybe } from '@graphql/generated'
import { Listbox } from '@headlessui/react'
import ChevronIcon from '@components/icons/ChevronIcon'
import { AnimatePresence, motion } from 'framer-motion'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import cx from 'classnames'
import { Fragment } from 'react'

export type SelectOption = {
  title: string
  key: string
  disabled?: boolean
}

export type SelectProps = {
  name?: string
  label: string
  value?: Maybe<SelectOption>
  options: Array<Maybe<SelectOption>>
  onChange: (value: SelectOption) => void
}

const Select: React.FC<SelectProps> = (props) => {
  return (
    <Listbox
      as="div"
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      className="relative"
    >
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">{props.label}</Listbox.Label>
          <Listbox.Button className="typo-200-regular md:typo-100-regular inline-flex w-full w-full items-center justify-between border-b-2 border-gray-100 py-2 pr-2 !text-blue-700 outline-none ring-offset-2 focus-visible:ring">
            <span>{props.value?.title ?? props.label}</span>
            <ChevronIcon className={cx('h-3 w-3 shrink-0 transition', { 'rotate-180': open })} />
          </Listbox.Button>

          <AnimatePresence>
            {open && (
              <Listbox.Options static as={Fragment}>
                <motion.ul
                  variants={{
                    hidden: {
                      height: 0,
                    },
                    show: {
                      height: 'auto',
                    },
                  }}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  transition={{
                    type: 'tween',
                    duration: 0.1,
                  }}
                  className="absolute left-0 top-full z-1 -mt-0.5 min-w-full overflow-hidden border-2 border-gray-100 bg-white shadow-lg outline-none focus-visible:ring"
                >
                  {props.options.map((option) =>
                    !option ? null : (
                      <Listbox.Option
                        as={Fragment}
                        key={option.key}
                        value={option}
                        disabled={option.disabled}
                      >
                        {({ active, selected, disabled }) => (
                          <li
                            className={cx(
                              'flex cursor-pointer items-center justify-between px-3 py-2 !text-blue-700 transition',
                              selected
                                ? 'typo-200 md:typo-100'
                                : 'typo-200-regular md:typo-100-regular',
                              {
                                'bg-gray-100': active,
                                'opacity-50': disabled,
                              }
                            )}
                          >
                            <span>{option.title}</span>
                            {selected && <CheckmarkIcon className="h-2.5 w-2.5 shrink-0" />}
                          </li>
                        )}
                      </Listbox.Option>
                    )
                  )}
                </motion.ul>
              </Listbox.Options>
            )}
          </AnimatePresence>
        </>
      )}
    </Listbox>
  )
}

export default Select
