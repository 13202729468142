import { IconProps } from '@additional'

const LinkedInIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M18.118 23.367v-9.552c-1.557-3.776-5.247 1.364-5.247 1.364v8.188H7.988V8.355h4.883v2.871a6.781 6.781 0 0 1 5.247-2.871 4.422 4.422 0 0 1 .876.088c.067.011.133.024.2.039s.1.033.156.049A4.868 4.868 0 0 1 23 13.234v10.13Zm-16.953 0V8.355h4.882v15.012ZM1 3.476a2.842 2.842 0 1 1 2.842 2.842A2.843 2.843 0 0 1 1 3.476Z"
        fill="currentColor"
      />
    </svg>
  )
}

LinkedInIcon.defaultProps = {
  'aria-hidden': true,
}

export default LinkedInIcon
