import { getCookie } from 'cookies-next'
import { OptionsType } from 'cookies-next/lib/types'

export const getCookieDecoded = (key: string, options?: OptionsType): string | null => {
  const cookie = getCookie(key, options)

  if (typeof cookie === 'string') {
    return decodeURIComponent(cookie)
  }

  return null
}
