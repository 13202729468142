import * as ReactToast from '@radix-ui/react-toast'
import React, { PropsWithChildren, useContext, useReducer, useState } from 'react'
import cx from 'classnames'
import NextLink from 'next/link'
import Link from '@components/common/Link'
import styles from './Toast.module.css'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import CrossIcon from '@components/icons/CrossIcon'

export type ToastProps = {
  id: string
  open: ReactToast.ToastProps['open']
  title: string
  level: 'success' | 'error'
  description?: string

  type: ReactToast.ToastProps['type']
  duration?: ReactToast.ToastProps['duration']
}

type Props = { toast: ToastProps; onClose: (toast: ToastProps) => void }

const Toast: React.FC<Props> = ({ toast, onClose }) => {
  return (
    <ReactToast.Root
      open={toast.open}
      onOpenChange={() => onClose(toast)}
      type={toast.type}
      duration={toast.duration}
      className={cx('bg-white py-4 pl-4 pr-8 shadow-lg', styles.toast)}
    >
      <div className="flex items-start space-x-2">
        <div className="">
          <div className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-white">
            {toast.level === 'success' && <CheckmarkIcon className="mt-px h-2.5 w-2.5" />}
            {toast.level === 'error' && <CrossIcon className="h-2.5 w-2.5" />}
          </div>
        </div>

        <div className="space-y-1">
          <ReactToast.Title className="font-semibold">{toast.title}</ReactToast.Title>
          {toast.description && (
            <ReactToast.Description className="leading-snug">
              {toast.description}
            </ReactToast.Description>
          )}
        </div>
      </div>

      {/*<ReactToast.Action />*/}
      {/*<ReactToast.Close />*/}
    </ReactToast.Root>
  )
}

export default Toast
