import AppLink, { AppLinkProps } from '@components/common/AppLink'
import { PropsWithChildren } from 'react'
import cx from 'classnames'
import Link from '@components/common/Link'
import ArrowIcon from '@components/icons/ArrowIcon'

type ArrowLinkProps = AppLinkProps & { reverse?: boolean; size?: 'normal' | 'small' }

const ArrowLink: React.FC<PropsWithChildren<ArrowLinkProps>> = ({
  children,
  size,
  reverse,
  className,
  ...props
}) => {
  return (
    <Link {...props} className={cx('group inline-flex items-center justify-between', className)}>
      {reverse && (
        <ArrowIcon
          className={cx('translate-x-full rotate-180', {
            'h-5 w-5': size === 'normal',
            'h-4 w-4': size === 'small',
          })}
        />
      )}

      {children}

      {!reverse && (
        <ArrowIcon
          className={cx('', {
            'h-5 w-5': size === 'normal',
            'h-4 w-4': size === 'small',
          })}
        />
      )}
    </Link>
  )
}

ArrowLink.defaultProps = {
  size: 'normal',
  reverse: false,
}

export default ArrowLink
