import { IconProps } from '@additional'

const ChainIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M3.646 20.354a5.634 5.634 0 0 1 0-7.959L6.38 9.662a5.633 5.633 0 0 1 7.958 0 1.375 1.375 0 1 1-1.944 1.944 2.883 2.883 0 0 0-4.07 0L5.592 14.34a2.877 2.877 0 0 0 4.069 4.069l1.126-1.126a1.375 1.375 0 0 1 1.945 1.945l-1.126 1.125a5.634 5.634 0 0 1-7.959 0ZM9.66 14.34a1.375 1.375 0 0 1 1.945-1.944 2.881 2.881 0 0 0 4.069 0l2.734-2.734a2.878 2.878 0 1 0-4.07-4.069l-1.126 1.124a1.374 1.374 0 0 1-1.944-1.943l1.126-1.125a5.627 5.627 0 1 1 7.958 7.957l-2.733 2.734a5.635 5.635 0 0 1-7.959 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

ChainIcon.defaultProps = {
  'aria-hidden': true,
}

export default ChainIcon
