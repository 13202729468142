import React from 'react'
import {
  Set_Components_ImageGallery,
  Set_TeamMemberComponents_ImageGallery,
} from '@graphql/generated'
import cx from 'classnames'
import Container from '@components/common/Container'
import StatamicImage from '@components/common/StatamicImage'
import Reveal, { RevealChild } from '@components/common/Reveal'

type Props = Set_Components_ImageGallery | Set_TeamMemberComponents_ImageGallery

const ImageGallery: React.FC<Props> = (props) => {
  return (
    <section
      className={cx(
        'pt-14 pb-22 md:py-20 md:pb-35',
        { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
      )}
    >
      <Container fluid className="">
        <Reveal className="mx-auto grid max-w-6xl grid-flow-row-dense auto-rows-[16rem] grid-cols-1 gap-5 sm:grid-cols-2 md:auto-rows-[22rem] lg:auto-rows-[30rem] lg:grid-cols-3">
          {props.images?.map(
            (image, i) =>
              image && (
                <RevealChild
                  key={image.id}
                  className={cx({
                    'lg:col-span-2': i % 4 === 1 || i % 4 === 2,
                  })}
                >
                  <StatamicImage loading="lazy" asset={image} className="h-full object-cover" />
                </RevealChild>
              )
          )}
        </Reveal>
      </Container>
    </section>
  )
}

ImageGallery.defaultProps = {}

export default ImageGallery
