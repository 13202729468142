import { IconProps } from '@additional'

const ExplosionIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M17.35 10.93 24 6.81l-7.24 1.18 1.7-2.66-3.1 1.23L17.28.55l-5.29 5.73-1.23-5.01-1.57 5.17-5.06-4.81 3.58 8.13L0 11.78l7.31 1.07-2.51 5.26 5.19-2.37 3.3 7.71.63-6.03 2.55 2.27-.17-2.83.79.45.36-1.38 2.72 2.01.18-.21-3.58-3.61-.39 1.61-1.34-.83.26 2.34-2.24-2.16-.38 4.14-2.05-5.02-3.85 1.95 1.46-3.26.44-.87-3.23-.5 3.78-.95-1.84-4.24 2.32 2.4.89-3.2.83 2.95 3.37-3.7-1.3 3.98 2.32-1.12-1.28 1.77 4.4-.7-4.4 2.61 3.59.86-3.48.42 8.15 3.63.48.19.12-.25-5.19-2.91 5.38-1.11-6.24-1.22z"
        fill="currentColor"
      />
      <path
        d="m5.61 7.62-3.17-1.2-.12.25 3.76 2.04-.47-1.09zM22.06 4.36l-3.05 1.9 2.13-.36.92-1.54z"
        fill="currentColor"
      />
    </svg>
  )
}

ExplosionIcon.defaultProps = {
  'aria-hidden': true,
}

export default ExplosionIcon
