import React, { useState } from 'react'
import AppLink from '@components/common/AppLink'
import CrossIcon from '@components/icons/CrossIcon'
import { useRouter } from 'next/router'
import { useGetCategoriesQuery } from '@graphql/generated'
import cx from 'classnames'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import BurgerIcon from '@components/icons/BurgerIcon'
import * as Collapsible from '@radix-ui/react-collapsible'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import NextLink from 'next/link'
import FilterButton from '@components/common/FilterButton'

const CategoryFilter: React.FC<{}> = (props) => {
  const router = useRouter()

  const categoriesQuery = useGetCategoriesQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const selectedCategory = router.query.category

  const queryLinkBuilder = useQueryLinkBuilder()

  return (
    <div className="space-y-2">
      <label htmlFor="filter-category" className="typo-100-regular">
        Kategorien
      </label>

      <div id="filter-category" className="flex flex-wrap gap-3">
        <FilterButton href={queryLinkBuilder('category', undefined)} active={!selectedCategory}>
          Alle
        </FilterButton>

        {categoriesQuery.data?.terms?.data?.map((category) =>
          !category?.slug ? null : (
            <FilterButton
              key={category.slug}
              href={queryLinkBuilder('category', category.slug)}
              active={selectedCategory === category.slug}
            >
              {category.title}
            </FilterButton>
          )
        )}
      </div>
    </div>
  )
}

export default CategoryFilter
