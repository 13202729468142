import React, { PropsWithChildren } from 'react'
import { Set_AsideComponents_Banner, Set_InsertComponents_Banner } from '@graphql/generated'
import Bard from '@components/common/Bard/Bard'
import Button from '@components/common/Button'
import AppLink from '@components/common/AppLink'
import cx from 'classnames'
import FlatExplosionIllustration from '@components/illustrations/FlatExplosionIllustration'

type QueryData = Set_InsertComponents_Banner | Set_AsideComponents_Banner

type Props = QueryData & {
  aside?: boolean
}

const Banner: React.FC<Props> = (props) => {
  const Comp: React.FC<PropsWithChildren<any>> = ({ children }) =>
    props.button_link ? (
      <AppLink href={props.button_link} className="group block outline-none">
        {children}
      </AppLink>
    ) : (
      <div className="group">{children}</div>
    )

  return (
    <Comp>
      <section
        className={cx(
          'relative isolate overflow-hidden transition-all',
          {
            'md:shadow-md hover:md:shadow-lg': !props.aside,
          },
          {
            'bg-red-600 text-white':
              !props.background_color?.value || props.background_color.value === 'red',
            'bg-blue-700 text-white': props.background_color?.value === 'petrol',
          }
        )}
      >
        <div
          className={cx(
            'flex flex-col items-start space-y-4 pl-7 pr-7 pb-8 pt-7 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-8 md:pr-12 md:pt-7 md:pb-8 md:pl-8 lg:flex-col lg:items-start lg:justify-start lg:space-y-4 lg:space-x-0',
            {
              'xl:flex-row xl:items-center xl:justify-between xl:space-y-0 xl:space-x-8':
                !props.aside,
              'lg:pl-7 lg:pr-7 lg:pb-8 lg:pt-7': props.aside,
            }
          )}
        >
          <div
            className={cx('w-full flex-1 space-y-2', {
              'md:space-y-3': !props.aside,
            })}
          >
            <h2 className={cx('text-inherit', props.aside ? 'typo-600' : 'typo-700')}>
              {props.title}
            </h2>
            <Bard
              html={props.text}
              className="typo-200-regular xs:max-w-[80%] sm:max-w-[60%] md:max-w-full"
            />
          </div>

          {props.button_link && (
            <div className="shrink-0">
              <span
                className={cx([
                  'relative isolate inline-flex items-center justify-center px-7 py-2 outline-none transition-all before:absolute before:inset-0 before:-z-1 before:transition-all [.group:focus-visible_&]:before:ring',
                  'typo-500 px-5 py-1.5 text-base',
                  'text-red-500 before:bg-white hover:before:bg-gray-50 focus-visible:before:bg-gray-50 disabled:opacity-60 disabled:hover:before:bg-white',
                  'hover:translate-x-1 hover:before:-skew-x-13 [.group:focus-visible_&]:translate-x-1 [.group:focus-visible_&]:before:-skew-x-13',
                ])}
              >
                {props.button_text}
              </span>
            </div>
          )}
        </div>

        <FlatExplosionIllustration
          className={cx(
            'absolute -bottom-12 -right-10 -z-1 w-48 sm:-bottom-14 sm:-right-12 sm:w-56 md:-bottom-16 md:-right-13 md:w-80',
            {
              'lg:-bottom-10 lg:-right-6 lg:w-48 xl:-bottom-16 xl:-right-13 xl:w-80': !props.aside,
              'lg:-bottom-12 lg:-right-10 lg:-z-1 lg:w-48': props.aside,
            },
            {
              'text-red-500':
                !props.background_color?.value || props.background_color.value === 'red',
              'text-white/10': props.background_color?.value === 'petrol',
            }
          )}
        />
      </section>
    </Comp>
  )
}

Banner.defaultProps = {
  aside: false,
}

export default Banner
