import React, { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import { Combobox } from '@headlessui/react'
import ChevronIcon from '@components/icons/ChevronIcon'
import { AnimatePresence, motion } from 'framer-motion'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import {
  Entry_Team_TeamMember,
  Maybe,
  Term_Formats_Format,
  useGetAuthorsQuery,
  useGetFormatsQuery,
  useGetTeamMembersQuery,
  User,
} from '@graphql/generated'
import CrossIcon from '@components/icons/CrossIcon'
import Autocomplete, { AutocompleteOption } from '@components/forms/fields/Autocomplete'

type Option = Entry_Team_TeamMember

const AuthorFilter: React.FC<{}> = (props) => {
  const router = useRouter()
  const queryLinkBuilder = useQueryLinkBuilder()
  const [searchQuery, setSearchQuery] = useState('')
  const authorsQuery = useGetAuthorsQuery(
    {
      limit: 30,
      query: searchQuery,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const currentAuthors: string[] = router.query.authors
    ? typeof router.query.authors === 'string' && /^\[.*\]$/.test(router.query.authors ?? '')
      ? JSON.parse(router.query.authors)
      : Array.isArray(router.query.authors)
      ? router.query.authors
      : [router.query.authors]
    : []

  const authors = (authorsQuery.data?.entries?.data ?? [])
    .map((author) =>
      !(author && 'author' in author && author.author?.id)
        ? null
        : {
            key: author.author?.id,
            title: author.title,
          }
    )
    .filter((a) => a)

  const selectedAuthors = authors.filter((author) => author && currentAuthors.includes(author.key))
  const authorsWithoutSelected = authors.filter(
    (author) => author && !currentAuthors.includes(author.key)
  )

  const update = (value: Array<Maybe<AutocompleteOption>>) => {
    const stringValues = value.flatMap((o) => (o ? [o.key] : []))
    router.replace(queryLinkBuilder('authors', stringValues.length ? stringValues : undefined))
  }

  return (
    <Autocomplete
      label="Autor:innen"
      selectedLabel="Ausgewählte Autor:innen"
      removeLabel={(option) => `Autor:innenfilter "${option.title}" entfernen`}
      value={selectedAuthors}
      options={authors}
      isLoading={authorsQuery.isLoading}
      onSearch={(query) => setSearchQuery(query)}
      onChange={update}
    />
  )
}

export default AuthorFilter
