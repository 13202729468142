import { HTMLAttributes } from 'react'

type OptionalProps = Omit<HTMLAttributes<SVGElement>, 'xmlns' | 'viewBox'> & {}

const CheckmarkIcon: React.FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M6.26 16 .41 10.09l1.74-1.72 3.69 3.72L13.52 0l2.07 1.31L6.26 16z"
      />
    </svg>
  )
}

export default CheckmarkIcon
