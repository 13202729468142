import { IconProps } from '@additional'

const InstagramIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M7.519 22A5.526 5.526 0 0 1 2 16.48V7.519A5.526 5.526 0 0 1 7.519 2h8.962A5.526 5.526 0 0 1 22 7.519v8.961A5.526 5.526 0 0 1 16.481 22ZM3.774 7.519v8.961a3.749 3.749 0 0 0 3.745 3.745h8.962a3.749 3.749 0 0 0 3.745-3.745V7.519a3.749 3.749 0 0 0-3.745-3.745H7.519a3.749 3.749 0 0 0-3.745 3.745Zm3.073 4.482A5.153 5.153 0 1 1 12 17.154 5.158 5.158 0 0 1 6.847 12Zm1.775 0a3.379 3.379 0 1 0 3.379-3.38A3.383 3.383 0 0 0 8.622 12Zm7.829-4.437a1.3 1.3 0 1 1 .919.381 1.308 1.308 0 0 1-.919-.382Z"
        fill="currentColor"
      />
    </svg>
  )
}

InstagramIcon.defaultProps = {
  'aria-hidden': true,
}

export default InstagramIcon
