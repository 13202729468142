import React from 'react'
import StatamicImage from '@components/common/StatamicImage'
import { BardText, Set_AsideComponents_AsideTeaser } from '@graphql/generated'
import ArrowLink from '@components/common/ArrowLink'
import cx from 'classnames'
import Bard from '@components/common/Bard/Bard'

type Props = BardText & {}

const BardText: React.FC<Props> = (props) => {
  return (
    <Bard
      html={props.text}
      bardMap={{
        h1: { tag: 'h3', className: 'typo-1000 md:typo-1100' },
        h2: { tag: 'h4', className: 'typo-900 md:typo-1000' },
        h3: { tag: 'h5', className: 'typo-800 md:typo-900' },
        h4: { tag: 'h6', className: 'typo-400 md:typo-800' },
        h5: 'p',
        h6: 'p',
      }}
    />
  )
}

BardText.defaultProps = {}

export default BardText
