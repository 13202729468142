import React from 'react'
import cx from 'classnames'
import Container from '@components/common/Container'
import {
  Entry_Posts_Post,
  Entry_Team_TeamMember,
  GetEventsQueryVariables,
  GetPageBySlugQuery,
  GetPostsDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
  Maybe,
  Set_Components_Team,
  Set_TeamMemberComponents_Posts,
  useGetPageBySlugQuery,
  useGetPostsQuery,
  User,
} from '@graphql/generated'
import { TeamFilterContextProvider } from '@components/team/filters/Filter'
import TeamFormatFilter from '@components/team/filters/TeamFormatFilter'
import TeamMembersList from '@components/team/TeamMembersList'
import { useRouter } from 'next/router'
import { gqlFetcher, queryClient } from '@lib/fetcher'
import { useInfiniteQuery } from 'react-query'
import { motion } from 'framer-motion'
import Button from '@components/common/Button'
import PostCard from '@components/news/PostCard'
import PostsGrid from '@components/news/PostsGrid'

type Props = Set_TeamMemberComponents_Posts

const NUMBER_OF_PAGES_TO_FETCH_AT_ONCE = 2

export const buildTeamMemberPostsVariables = (user: Pick<User, 'id'>): GetPostsQueryVariables => ({
  filter: { author: { is: user.id } },
  limit: 1000,
})

const TeamMemberPosts: React.FC<Props> = (props) => {
  const router = useRouter()

  let uri: string = Array.isArray(router.query.uri)
    ? router.query.uri.join('/')
    : router.query.uri ?? ''
  if (!uri.startsWith('/')) {
    uri = '/' + uri
  }

  const queryData = queryClient.getQueryData<GetPageBySlugQuery>(['GetPageBySlug', { uri }])

  const page = queryData!.entry as Entry_Team_TeamMember

  const variables = buildTeamMemberPostsVariables({ id: page.author?.id ?? null })

  const postsQuery = useInfiniteQuery<GetPostsQuery>(
    ['GetPosts', variables],
    async ({ pageParam = 1 }) => {
      await queryClient.cancelQueries(['GetPosts'])

      return await gqlFetcher<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, {
        ...variables,
        page: pageParam,
      })()
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.entries?.has_more_pages ? lastPage.entries?.current_page + 1 : null,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const fetchNextPages = (numberOfPages: number): void => {
    if (!numberOfPages) {
      return
    }

    postsQuery.fetchNextPage().then((result) => {
      if (result.hasNextPage) {
        fetchNextPages(numberOfPages - 1)
      }
    })
  }

  // Map all loaded infinite query pages into one array
  const posts: Array<Maybe<Entry_Posts_Post>> =
    postsQuery.data?.pages.flatMap(
      (page) => (page?.entries?.data as Array<Maybe<Entry_Posts_Post>>) ?? []
    ) ?? []

  return (
    <section
      className={cx(
        'pt-12 pb-20 md:pt-14 md:pb-20',
        { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
      )}
    >
      <Container fluid className="">
        <PostsGrid
          title={`Beiträge von ${page.title}`}
          posts={posts}
          showLoadMore={postsQuery.hasNextPage}
          onLoadMore={() => fetchNextPages(NUMBER_OF_PAGES_TO_FETCH_AT_ONCE)}
        />
      </Container>
    </section>
  )
}

TeamMemberPosts.defaultProps = {}

export default TeamMemberPosts
