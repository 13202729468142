import { IconProps } from '@additional'

const SearchIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="m22.62 20.892-4.148-4.135a9.662 9.662 0 0 0 2.062-5.978 9.759 9.759 0 1 0-9.759 9.759 9.662 9.662 0 0 0 5.978-2.062l4.139 4.144a1.225 1.225 0 1 0 1.728-1.728ZM3.46 10.779a7.319 7.319 0 1 1 7.319 7.319 7.319 7.319 0 0 1-7.319-7.319Z"
        fill="currentColor"
      />
    </svg>
  )
}

SearchIcon.defaultProps = {
  'aria-hidden': true,
}

export default SearchIcon
