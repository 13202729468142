import { Slot } from '@radix-ui/react-slot'
import { HTMLAttributes, PropsWithChildren } from 'react'
import cx from 'classnames'

type ContainerProps = {
  asChild?: boolean
  fluid?: boolean
} & HTMLAttributes<HTMLDivElement>

const Container: React.FC<PropsWithChildren<ContainerProps>> = ({
  asChild,
  fluid,
  className,
  children,
  ...props
}) => {
  const Comp = asChild ? Slot : 'div'

  return (
    <Comp className={cx([fluid ? 'w-full px-6 sm:px-10' : 'container px-4'], className)} {...props}>
      {children}
    </Comp>
  )
}

Container.defaultProps = {
  asChild: false,
  fluid: false,
}

export default Container
