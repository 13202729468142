import React from 'react'
import cx from 'classnames'
import Container from '@components/common/Container'
import {
  Entry_Posts_Post,
  Entry_Team_TeamMember,
  GetEventsQueryVariables,
  GetPageBySlugQuery,
  GetPostsDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
  Maybe,
  Set_Components_Team,
  Set_TeamMemberComponents_Posts,
  useGetPageBySlugQuery,
  useGetPostsQuery,
  User,
} from '@graphql/generated'
import { TeamFilterContextProvider } from '@components/team/filters/Filter'
import TeamFormatFilter from '@components/team/filters/TeamFormatFilter'
import TeamMembersList from '@components/team/TeamMembersList'
import { useRouter } from 'next/router'
import { gqlFetcher, queryClient } from '@lib/fetcher'
import { useInfiniteQuery } from 'react-query'
import { motion } from 'framer-motion'
import Button from '@components/common/Button'
import PostCard from '@components/news/PostCard'
import Reveal from '@components/common/Reveal'

type Props = {
  title?: Maybe<string>
  posts: Array<Maybe<Entry_Posts_Post>>
  maxCols?: 2 | 3
} & (
  | {
      showLoadMore?: false
    }
  | {
      showLoadMore: true
      onLoadMore: () => void
    }
)

const PostsGrid: React.FC<Props> = (props) => {
  const maxCols = props.maxCols ?? 3

  return (
    <div className="mx-auto max-w-6xl space-y-5 md:space-y-7">
      {props.title && (
        <Reveal transition={{ delay: 0.3 }}>
          <h2 className="typo-400 md:typo-1000">{props.title}</h2>
        </Reveal>
      )}

      <div className="space-y-10">
        <div
          className={cx('grid grid-cols-1 gap-4 md:gap-5', {
            'sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2': maxCols === 2,
            'sm:grid-cols-2 lg:grid-cols-3': maxCols >= 3,
          })}
        >
          {props.posts.map((post, i) =>
            !post ? null : (
              <Reveal key={post.id} transition={{ delay: 0.5 }}>
                <PostCard post={post} size="small" className="md:shadow-md hover:md:shadow-lg" />
              </Reveal>
            )
          )}
        </div>

        {props.showLoadMore && (
          <Reveal className="flex justify-center">
            <Button onClick={props.onLoadMore}>Mehr laden</Button>
          </Reveal>
        )}
      </div>
    </div>
  )
}

PostsGrid.defaultProps = {
  maxCols: 3,
}

export default PostsGrid
