import React from 'react'
import cx from 'classnames'
import Container from '@components/common/Container'
import { Set_Components_Teaser, Set_TeamMemberComponents_Teaser } from '@graphql/generated'
import Button from '@components/common/Button'
import Bard from '@components/common/Bard/Bard'
import Reveal, { RevealChild } from '@components/common/Reveal'

type Props = Set_Components_Teaser | Set_TeamMemberComponents_Teaser

const Teaser: React.FC<Props> = (props) => {
  return (
    <section className="bg-red-500 pt-14 pb-24 text-white md:pt-20 md:pb-25">
      <Container fluid className="">
        <Reveal className="mx-auto flex max-w-6xl flex-col items-stretch space-y-9 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-16">
          <div className="grow space-y-7 md:space-y-4">
            <RevealChild>
              <h2 className="typo-1200 text-inherit">{props.title}</h2>
            </RevealChild>
            <RevealChild>
              <Bard html={props.text} className="typo-400-regular" />
            </RevealChild>
          </div>

          <div className="shrink-0">
            {props.link && (
              <RevealChild>
                <Button href={props.link} level="secondary" className="md:mt-5">
                  {props.link_text}
                </Button>
              </RevealChild>
            )}
          </div>
        </Reveal>
      </Container>
    </section>
  )
}

Teaser.defaultProps = {}

export default Teaser
