export function isExternalUrl(url: string): boolean {
  if (/^https?:\/\//.test(url)) {
    if (process.browser) {
      return !url.startsWith(location.origin)
    }

    if (process.env.NEXT_PUBLIC_APP_URL) {
      return !url.startsWith(process.env.NEXT_PUBLIC_APP_URL)
    }

    return true
  }

  return false
}
