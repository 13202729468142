import { IconProps } from '@additional'

const LogoSmallLight: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.329 41.124" {...props}>
      <path
        d="M11.279 16.354.914 18.498l12.093 2.367L0 28.933l14.024-2.284-3.346 5.243 6.1-2.418-3.73 11.65 10.327-11.17 2.388 9.76 3.052-10.045 9.665 9.19-6.9-15.654 14.752-3.859-15.192-2.219 6.891 2.6-8.769 2.227 4.249 9.8-5.491-5.684-1.961 7.06-1.831-6.538-7.453 8.172 2.876-8.761-5.166 2.493 2.878-4-9.805 1.574 9.692-5.739-8.194-1.977 7.665-.92L.982 10.435 0 10.042Z"
        fill="#FE510B"
      />
      <path d="m41.449 28.567-6.971-3.783.584 1.36Z" fill="#FE510B" />
      <path d="m4.872 32.177 4.017-2.5-2.8.48Z" fill="#FE510B" />
      <path d="m13.617 12.299-.333 1.623-6.973-7.026Z" fill="#FE510B" />
      <g fill="#fff">
        <path d="m26.33 18.313.84-4.115a1.585 1.585 0 0 0 .638.775 2.444 2.444 0 0 0 1.289.276 4.44 4.44 0 0 0 1.252-.15 1.907 1.907 0 0 0 .839-.5 2.919 2.919 0 0 0 .576-.94 8.308 8.308 0 0 0 .413-1.465l1.953-9.139a7.394 7.394 0 0 0 .225-1.577 1.308 1.308 0 0 0-.464-1.165A2.912 2.912 0 0 0 32.328 0a3.613 3.613 0 0 0-1.5.276 2.978 2.978 0 0 0-1.1.926l.226-1.077h-2.379l-3.791 18.188Zm3.144-15.258a1.912 1.912 0 0 1 .538-.563 1.276 1.276 0 0 1 .739-.213 1.335 1.335 0 0 1 .689.125.564.564 0 0 1 .188.5 3.306 3.306 0 0 1-.075.626L29.8 11.694a2.035 2.035 0 0 1-.4 1 1.264 1.264 0 0 1-.9.251.905.905 0 0 1-.976-.776Z" />
        <path d="M23.54 19.411a1.966 1.966 0 0 1-.144.423l1.687 1.3a3.143 3.143 0 0 0 .736-.935 3.612 3.612 0 0 0 .388-1.27h-2.563l-.1.485" />
        <path d="M22.74 20.405v.965a1.825 1.825 0 0 0 2.013 0l-1.592-1.208a1.029 1.029 0 0 1-.421.242" />
        <path d="M20.944 21.088c0 .007.01-.015.047-.027a.045.045 0 0 1 .038.013.1.1 0 0 1 .031.055c.009.059-.018.091-.007.093s.011-.047.037-.078c.013-.016.016-.023.068-.027a.065.065 0 0 1 .059.019c.042.04.038.074.051.072s-.014-.03-.011-.089c0-.03 0-.028.016-.048a.043.043 0 0 1 .055-.01c.047.023.053.04.058.032a.756.756 0 0 1-.114-.179 3.111 3.111 0 0 1 .025-.552.218.218 0 0 1 .1-.153.168.168 0 0 1 .177.031 3.8 3.8 0 0 1 .481.559c.14.163.239.264.366.4v-.772c-.311-.051-.435-.193-.709-.473-.166-.171-.293-.151-.388-.107-.305.139-.269.987-.284 1.071s-.106.166-.1.175" />
        <path d="M21.245 20.895v-.006.006Z" />
        <path d="M21.414 21.759a.106.106 0 1 1 .106.106.106.106 0 0 1-.106-.106" />
        <path d="M20.771 22.1a.157.157 0 1 1 .157.157.157.157 0 0 1-.157-.157" />
        <path d="M20.461 21.723a.074.074 0 1 1 .074.074.074.074 0 0 1-.074-.074" />
        <path d="M20.888 22.557a.092.092 0 1 1 .092.092.092.092 0 0 1-.092-.092" />
        <path d="M20.421 22.648a.1.1 0 1 1 .1.1.1.1 0 0 1-.1-.1" />
        <path d="M20.637 23.162a.065.065 0 1 1 .065.065.065.065 0 0 1-.065-.065" />
      </g>
    </svg>
  )
}

LogoSmallLight.defaultProps = {
  'aria-hidden': true,
}

export default LogoSmallLight
