import React, { useContext, useState } from 'react'
import CrossIcon from '@components/icons/CrossIcon'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import FilterIcon from '@components/icons/FilterIcon'
import * as Collapsible from '@radix-ui/react-collapsible'
import CategoryFilter from '@components/news/filters/CategoryFilter'
import { NewsFilterContext } from '@components/news/filters/Filter'
import SearchFilter from '@components/news/filters/SearchFilter'
import SortSelect from '@components/news/filters/SortSelect'
import FormatFilter from '@components/news/filters/FormatFilter'
import AuthorFilter from '@components/news/filters/AuthorFilter'
import TagsFilter from '@components/news/filters/TagsFilter'

const FilterItemMotionVariants: Variants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
}

const FiltersCard: React.FC<{}> = (props) => {
  const { filter, sort } = useContext(NewsFilterContext)

  const numberOfActiveFilters = Object.keys(filter).length + (sort ? 1 : 0)

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            layout
            onClick={() => setIsOpen(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.3,
              },
            }}
            transition={{
              duration: 0.1,
            }}
            className="fixed inset-0 bg-black/50 md:hidden [@supports(backdrop-filter:blur(0))]:bg-black/20 [@supports(backdrop-filter:blur(0))]:backdrop-blur"
          />
        )}
      </AnimatePresence>

      <Collapsible.Root asChild open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <fieldset
          className={cx(
            'shallow-lg:max-h-[70vh] shallow-md:max-h-[50vh] shallow:overflow-y-auto isolate bg-white md:shadow-none',
            isOpen ? 'shadow-2xl' : 'shadow-xl'
          )}
        >
          <legend className="float-left w-full">
            <Collapsible.Trigger className="group flex w-full items-center justify-between py-4 px-6 outline-none md:pl-4 md:pr-5">
              <span className="typo-500 group-hover:text-red-600">Filtermenü</span>

              <span className="relative -m-1 p-1 text-blue-900 outline-none group-hover:text-black group-focus-visible:ring">
                <AnimatePresence exitBeforeEnter>
                  {isOpen ? (
                    <motion.span
                      key="cross"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0, transition: { delay: 0.3, duration: 0.1 } }}
                      transition={{ duration: 0.1 }}
                    >
                      <CrossIcon className="h-4 w-4" />
                    </motion.span>
                  ) : (
                    <motion.span
                      key="filter"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.1 } }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.1 }}
                    >
                      {!!numberOfActiveFilters && (
                        <span className="absolute top-0.5 left-0 flex h-2.5 w-2.5 items-center justify-center rounded-full bg-red-500 font-sans text-[0.5rem] font-bold leading-none text-white">
                          <span className="-mt-px">{numberOfActiveFilters}</span>
                        </span>
                      )}
                      <FilterIcon className="h-4 w-4" />
                    </motion.span>
                  )}
                </AnimatePresence>
              </span>
            </Collapsible.Trigger>
          </legend>

          <AnimatePresence>
            {isOpen && (
              <Collapsible.Content forceMount asChild>
                <motion.div
                  variants={{
                    closed: {
                      height: 0,
                      transition: {
                        type: 'spring',
                        duration: 0.3,
                        when: 'afterChildren',
                      },
                    },
                    open: {
                      height: 'auto',
                      transition: {
                        type: 'spring',
                        duration: 0.3,
                        staggerChildren: 0.05,
                      },
                    },
                  }}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  className="clear-left"
                >
                  <div className="space-y-7 px-6 pb-8 pt-4 md:pl-4 md:pr-5">
                    <motion.div variants={FilterItemMotionVariants}>
                      <SearchFilter />
                    </motion.div>

                    <motion.div variants={FilterItemMotionVariants}>
                      <CategoryFilter />
                    </motion.div>

                    <div className="space-y-3">
                      <motion.p variants={FilterItemMotionVariants} className="typo-100-regular">
                        Filter
                      </motion.p>

                      <div className="space-y-4">
                        <motion.div variants={FilterItemMotionVariants}>
                          <SortSelect />
                        </motion.div>

                        <motion.div variants={FilterItemMotionVariants}>
                          <FormatFilter />
                        </motion.div>

                        <motion.div variants={FilterItemMotionVariants}>
                          <AuthorFilter />
                        </motion.div>

                        <motion.div variants={FilterItemMotionVariants}>
                          <TagsFilter />
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </Collapsible.Content>
            )}
          </AnimatePresence>
        </fieldset>
      </Collapsible.Root>
    </>
  )
}

export default FiltersCard
