import { Scalars } from '@graphql/generated'
import React, { PropsWithChildren, useState } from 'react'
import { useRouter } from 'next/router'

export type NewsFilterContextType = {
  filter: {
    title?: { matches: string }
    categories?: { contains: string }
    format?: { in: string[] }
    author?: { in: string[] }
    tags?: { jsonContains: string[] }
  }
  sort: string | string[]
}

export const NewsFilterContext = React.createContext<NewsFilterContextType>({
  filter: {},
  sort: '',
})

export const NewsFilterContextProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const router = useRouter()

  const filter: NewsFilterContextType['filter'] = {}
  let sort: NewsFilterContextType['sort'] = ''

  if (router.query.search) {
    filter['title'] = {
      matches: Array.isArray(router.query.search) ? router.query.search[0] : router.query.search,
    }
  }
  if (router.query.category) {
    filter['categories'] = {
      contains: Array.isArray(router.query.category)
        ? router.query.category[0]
        : router.query.category,
    }
  }
  if (router.query.formats) {
    filter['format'] = {
      in:
        typeof router.query.formats === 'string'
          ? /^\[.*\]$/.test(router.query.formats ?? '')
            ? JSON.parse(router.query.formats)
            : [router.query.formats]
          : router.query.formats,
    }
  }
  if (router.query.authors) {
    filter['author'] = {
      in:
        typeof router.query.authors === 'string'
          ? /^\[.*\]$/.test(router.query.authors ?? '')
            ? JSON.parse(router.query.authors)
            : [router.query.authors]
          : router.query.authors,
    }
  }
  if (router.query.tags) {
    filter['tags'] = {
      jsonContains:
        typeof router.query.tags === 'string'
          ? /^\[.*\]$/.test(router.query.tags ?? '')
            ? JSON.parse(router.query.tags)
            : [router.query.tags]
          : router.query.tags,
    }
  }

  if (router.query.sort && !Array.isArray(router.query.sort)) {
    switch (router.query.sort) {
      case 'chronological':
        sort = 'date desc'
        break
      case 'popularity':
        sort = ['number_of_likes desc', 'date desc']
        break
      case 'unpopularity':
        sort = ['number_of_likes asc', 'date desc']
        break
    }
  }

  return (
    <NewsFilterContext.Provider value={{ filter, sort }}>{children}</NewsFilterContext.Provider>
  )
}
