import React, { useCallback, useMemo, useRef, useState } from 'react'
import CrossIcon from '@components/icons/CrossIcon'
import { useRouter } from 'next/router'
import { AnimatePresence, motion } from 'framer-motion'
import SearchIcon from '@components/icons/SearchIcon'
import debounce from 'lodash/debounce'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'

const SearchFilter: React.FC<{}> = (props) => {
  const router = useRouter()
  const queryLinkBuilder = useQueryLinkBuilder()

  const currentSearch = router.query.search

  const syncToUrl = useRef(
    debounce((value: string) => {
      router.replace(queryLinkBuilder('search', value, !!value))
    }, 300)
  ).current

  const [search, setSearch] = useState(
    Array.isArray(currentSearch) ? currentSearch.join('') : currentSearch ?? ''
  )

  const update = (newSearch: string) => {
    setSearch(newSearch)
    syncToUrl(newSearch)
  }

  return (
    <label htmlFor="" className="group relative">
      <input
        type="text"
        name="search"
        value={search}
        onInput={(e) => update(e.currentTarget.value)}
        placeholder="Suchen"
        className="w-full flex-grow border border-gray-50 bg-gray-50 py-3 pl-4  pr-8 text-blue-900 outline-none transition placeholder:text-blue-900/50 hover:border-gray-100 hover:bg-gray-100 focus:border-blue-900"
      />
      <span className="absolute inset-y-0 right-0 inline-flex items-center justify-center pr-3">
        <AnimatePresence exitBeforeEnter>
          {!!search ? (
            <motion.button
              key="cross"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.05 }}
              onClick={() => update('')}
              className="outline-none focus-visible:ring"
            >
              <span className="sr-only">Suche löschen</span>
              <CrossIcon className="h-4 w-4" />
            </motion.button>
          ) : (
            <motion.span
              key="filter"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.05 }}
            >
              <SearchIcon className="h-4 w-4" />
            </motion.span>
          )}
        </AnimatePresence>
      </span>
    </label>
  )
}

export default SearchFilter
