import React, { useState } from 'react'
import AppLink from '@components/common/AppLink'
import CrossIcon from '@components/icons/CrossIcon'
import { useRouter } from 'next/router'
import { useGetEventTypesQuery } from '@graphql/generated'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import FilterButton from '@components/common/FilterButton'
import Reveal, { RevealChild } from '@components/common/Reveal'

const EventTypeFilter: React.FC<{}> = (props) => {
  const router = useRouter()

  const eventTypesQuery = useGetEventTypesQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const selectedEventType = router.query.event_type

  const queryLinkBuilder = useQueryLinkBuilder()

  return (
    <Reveal transition={{ delayChildren: 0.5 }} className="space-y-2">
      <label htmlFor="filter-event-type" className="sr-only">
        {eventTypesQuery.data?.taxonomy?.title}
      </label>

      <div id="filter-event-type" className="flex flex-wrap justify-center gap-y-3.5 gap-x-4">
        <RevealChild key="alle">
          <FilterButton
            href={queryLinkBuilder('event_type', undefined)}
            active={!selectedEventType}
          >
            Alle
          </FilterButton>
        </RevealChild>

        {eventTypesQuery.data?.terms?.data?.map((eventType) =>
          !eventType?.slug ? null : (
            <RevealChild key={eventType.slug}>
              <FilterButton
                href={queryLinkBuilder('event_type', eventType.slug)}
                active={selectedEventType === eventType.slug}
              >
                {eventType.title}
              </FilterButton>
            </RevealChild>
          )
        )}
      </div>
    </Reveal>
  )
}

export default EventTypeFilter
