import React, { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'

export type TeamFilterContextType = {
  filter: {
    team_formats?: { contains: string | string[] }
  }
}

export const TeamFilterContext = React.createContext<TeamFilterContextType>({
  filter: {},
})

export const TeamFilterContextProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const router = useRouter()

  const filter: TeamFilterContextType['filter'] = {}

  if (router.query.team_format) {
    filter['team_formats'] = { contains: router.query.team_format }
  }

  return <TeamFilterContext.Provider value={{ filter }}>{children}</TeamFilterContext.Provider>
}
