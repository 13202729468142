import { getCookieDecoded } from '@lib/cookie'
import { IncomingMessage } from 'http'
import { QueryClient, QueryFunctionContext } from 'react-query'

export let queryClient = new QueryClient()

export function createNewQueryClient(): QueryClient {
  queryClient = new QueryClient()

  return queryClient
}

export class ResponseError extends Error {
  status: number
  response?: Response

  constructor(message: string, status: number, response?: Response) {
    super(message || response?.statusText)

    this.status = status
    this.response = response
  }

  public getStatus(): number {
    return this.status
  }
}

export class FetchError extends ResponseError {
  info: { [key: string]: unknown } = {}

  constructor(
    message: string,
    status: number,
    response?: Response,
    info?: { [key: string]: unknown }
  ) {
    super(message, status, response)

    if (info) {
      this.info = info
    }
  }

  public getInfo(): { [key: string]: unknown } {
    return this.info
  }
}

export class UnprocessableEntity extends ResponseError {
  validationErrors: { [key: string]: string[] } = {}

  constructor(
    message: string,
    status: number,
    response?: Response,
    validationErrors?: {
      [property: string]: string[]
    }
  ) {
    super(message, status, response)

    if (validationErrors) {
      this.validationErrors = validationErrors
    }
  }

  public getValidationErrors(): {
    [property: string]: string[]
  } {
    return this.validationErrors
  }

  public getSingleValidationErrors(): {
    [property: string]: string
  } {
    return Object.fromEntries(
      Object.entries(this.validationErrors).map(([key, values]) => [key, values[0]])
    )
  }
}

export function gqlFetcher<TData, TVariables extends { filter: { [key: string]: any } } | {}>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
  req?: IncomingMessage,
  previewToken?: string
) {
  return async (context?: QueryFunctionContext): Promise<TData> => {
    if (!previewToken) {
      const cookiePreviewToken = queryClient.getQueryData('preview-token')
      previewToken = typeof cookiePreviewToken === 'string' ? cookiePreviewToken : undefined
    }

    const url = previewToken
      ? process.env.NEXT_PUBLIC_GRAPHQL_URL! + `?token=${previewToken}`
      : process.env.NEXT_PUBLIC_GRAPHQL_URL!

    if (query.includes(' entry(')) {
      if (!variables) {
        // @ts-ignore
        variables = {}
      }
      // @ts-ignore
      if (!variables.filter) {
        // @ts-ignore
        variables.filter = {}
      }
      if (
        previewToken &&
        variables &&
        'filter' in variables &&
        !(variables.filter.status || variables.filter.published)
      ) {
        variables.filter.status = { in: ['draft', 'published', 'scheduled', 'expired'] }
      }
    }

    const res = await fetch(url, {
      signal: context?.signal,
      method: 'POST',
      credentials: 'include',
      headers: {
        credentials: 'include',
        Accept: 'application/json',
        'Content-Type': 'application/json',

        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-TOKEN': getCookieDecoded('XSRF-TOKEN', { req })?.slice(0, -1) ?? '',
        cookie: req?.headers.cookie ?? '',

        ...(options ?? {}),
      },
      body: JSON.stringify({ query, variables }),
    })

    if (!res.ok) {
      throw new FetchError(res.statusText, res.status, res)
    }

    const json = await res.json()

    if (json.errors) {
      const { message, ...info } = json.errors[0]

      if (message === 'validation') {
        throw new UnprocessableEntity(message, res.status, res, info.extensions.validation)
      }

      throw new FetchError(message, res.status, res, info)
    }

    return json.data
  }
}

export interface WrappedData<T> {
  data: T
}

export type JsonFetcher<TData, TPayload extends { [k: string]: any } = {}> = (
  url: string | URL,
  payload?: TPayload | null,
  options?: JsonFetcherOptions | null,
  req?: IncomingMessage,
  previewToken?: string
) => () => Promise<TData>

export type JsonFetcherOptions = Omit<RequestInit, 'body'>

export function jsonFetcher<TData, TPayload extends { [k: string]: any } = {}>(
  url: string | URL,
  payload?: TPayload | null,
  options?: JsonFetcherOptions | null,
  req?: IncomingMessage,
  previewToken?: string
): (context?: QueryFunctionContext) => Promise<TData> {
  if (!previewToken) {
    const cookiePreviewToken = queryClient.getQueryData('preview-token')
    previewToken = typeof cookiePreviewToken === 'string' ? cookiePreviewToken : undefined
  }

  // Convert relative url to absolute
  if (typeof url === 'string' && !/^https?:\/\//.test(url)) {
    url = url.startsWith('/') ? url : `/${url}`
    url = process.env.NEXT_PUBLIC_BACKEND_URL + url
  }

  // Convert url string to url instance
  let urlInstance = typeof url === 'string' ? new URL(url) : url

  if (previewToken) {
    urlInstance.searchParams.set('token', previewToken)
  }

  return async (context) => {
    const fetchOptions: RequestInit = {
      signal: context?.signal,
      credentials: 'include',
      ...options,
      headers: {
        credentials: 'include',
        Accept: 'application/json',
        ...(process.browser && payload instanceof FormData
          ? {}
          : {
              'Content-Type': 'application/json',
            }),

        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-TOKEN': getCookieDecoded('XSRF-TOKEN', { req })?.slice(0, -1) ?? '',
        cookie: req?.headers.cookie ?? '',

        ...options?.headers,
      },
    }

    if (payload && typeof payload === 'object') {
      if (['GET', 'OPTIONS'].includes(fetchOptions.method ?? 'GET')) {
        Object.entries(payload).forEach(([key, value]) => urlInstance.searchParams.set(key, value))
      } else {
        fetchOptions.body =
          process.browser && payload instanceof FormData ? payload : JSON.stringify(payload)
      }
    }

    const response = await fetch(urlInstance.toString(), fetchOptions)

    if (!response.ok) {
      const status = response.status
      let { message, ...info } = await response.json()

      if (status === 422) {
        throw new UnprocessableEntity(message, status, response, info.errors)
      }

      throw new FetchError(message, status, response, info)
    }

    return await response
      .clone()
      .json()
      .catch(() => null)
  }
}
