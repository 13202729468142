import { Entry_Posts_Post, Sets_Content } from '@graphql/generated'
import { HTMLProps, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Mousewheel } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/mousewheel'
import PostMedia from '@components/news/PostMedia'
import useResizeObserver from '@hooks/useResizeObserver'
import throttle from 'lodash/throttle'
import cx from 'classnames'
import AppLink from '@components/common/AppLink'
import NextLink from 'next/link'
import { Except } from 'type-fest'

type RequiredProps = {
  post: Entry_Posts_Post
}

type OptionalProps = Partial<
  HTMLProps<HTMLDivElement> & {
    autoHeight: boolean
    contentSize: 'small' | 'normal'
    lazy: boolean
    className: string
  }
>

const PostMediaCarousel: React.FC<RequiredProps & OptionalProps> = ({
  post,
  autoHeight,
  contentSize,
  lazy,
  ...props
}) => {
  const [height, setHeight] = useState<number | null>()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [firstSlide, setFirstSlide] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!wrapperRef.current) {
      return
    }

    const swiper = wrapperRef.current.querySelector('.swiper')
    if (!swiper) {
      return
    }

    const firstSlide = swiper.querySelector('.swiper-wrapper :first-child :first-child')
    if (!firstSlide) {
      return
    }

    setFirstSlide(firstSlide as HTMLElement)
  }, [wrapperRef])

  const updateHeight = throttle(() => {
    if (!firstSlide) {
      return
    }

    if (autoHeight) {
      setHeight(firstSlide.clientHeight ?? null)
    } else {
      setHeight((firstSlide.clientWidth / 16) * 9)
    }
  })

  useEffect(updateHeight, [updateHeight, firstSlide])

  useResizeObserver(firstSlide, updateHeight)

  return (
    <div ref={wrapperRef} {...props}>
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        style={{ width: '0px', height: height !== null ? `${height}px` : '100%', flexGrow: 1 }}
        watchSlidesProgress
        modules={[Pagination, Navigation, Mousewheel]}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
      >
        {((post.content ?? []) as Sets_Content[]).map((item, i) => (
          <SwiperSlide key={i}>
            {({ isVisible }) => (
              <PostMedia
                content={item}
                contentSize={contentSize}
                lazy={i > 0 || lazy}
                allowGrowing
                autoPlay
                isVisible={isVisible}
                className={cx('flex w-full items-center justify-center', {
                  'h-full': !autoHeight || i > 0,
                })}
              />
            )}
          </SwiperSlide>
        ))}

        <div className="swiper-button-prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-label="Zurück"
            className="h-4 w-4 rotate-180 md:h-5 md:w-5"
          >
            <path d="M19.057 12 8.575 24l-1.633-1.427L16.177 12 6.942 1.427 8.575 0Z" />
          </svg>
        </div>
        <div className="swiper-button-next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-label="Weiter"
            className="h-4 w-4 md:h-5 md:w-5"
          >
            <path d="M19.057 12 8.575 24l-1.633-1.427L16.177 12 6.942 1.427 8.575 0Z" />
          </svg>
        </div>
      </Swiper>
    </div>
  )
}

PostMediaCarousel.defaultProps = {
  lazy: true,
}

export default PostMediaCarousel
