import React, { FC, HTMLProps } from 'react'
import { FormFieldChildProps } from '@components/forms/fields/FormField'
import cx from 'classnames'
import { useLabelContext } from '@radix-ui/react-label'

type InputProps = Partial<
  // TODO: Remove unnecessary type addition.
  FormFieldChildProps<string> & HTMLProps<HTMLInputElement>
>

const Input: FC<InputProps> = ({ className, meta, ...props }) => {
  const labelId = useLabelContext()

  return (
    <input
      {...props}
      aria-labelledby={labelId}
      aria-describedby={`${labelId}-error`}
      aria-invalid={!!meta?.error && meta?.touched}
      className={cx(
        'border border-gray-50 bg-gray-50 px-4 py-3.5 outline-none transition placeholder:text-blue-900/50 focus:border-blue-900 enabled:hover:border-gray-100 enabled:hover:bg-gray-100 disabled:text-blue-900/60',
        className,
        {
          '!border-red-500 !bg-red-50': meta?.error && meta?.touched,
        }
      )}
    />
  )
}

export default React.memo(Input)
