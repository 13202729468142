import React from 'react'
import {
  Set_Components_ImageWithText,
  Set_TeamMemberComponents_ImageWithText,
} from '@graphql/generated'
import Bard from '@components/common/Bard/Bard'
import cx from 'classnames'
import Container from '@components/common/Container'
import StatamicImage from '@components/common/StatamicImage'
import Button from '@components/common/Button'
import Reveal, { RevealChild } from '@components/common/Reveal'

type Props = Set_Components_ImageWithText | Set_TeamMemberComponents_ImageWithText

const ImageWithText: React.FC<Props> = (props) => {
  const imagePosition = props.image_position?.value ?? 'right'

  return (
    <section
      className={cx(
        'pt-14 pb-22 md:py-20 md:pb-35',
        { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
      )}
    >
      <Container fluid className="">
        <Reveal
          className={cx('mx-auto flex max-w-6xl flex-col gap-y-16 gap-x-8 md:justify-between', {
            'md:flex-row': imagePosition === 'right',
            'md:flex-row-reverse md:space-x-reverse': imagePosition === 'left',
          })}
        >
          <div className="w-full shrink-0 space-y-11 md:w-1/2 md:max-w-sm md:space-y-9 md:pt-5 lg:max-w-md xl:max-w-lg xl:pt-6">
            <div className="space-y-6">
              <RevealChild>
                <h2 className="typo-1200">{props.title}</h2>
              </RevealChild>
              <RevealChild>
                <Bard html={props.text} className="text-lg md:text-xl" />
              </RevealChild>
            </div>

            {props.button_link && (
              <RevealChild>
                <Button href={props.button_link}>{props.button_text}</Button>
              </RevealChild>
            )}
          </div>

          <RevealChild className="w-full md:w-[45%] md:self-center">
            {props.image && <StatamicImage loading="lazy" asset={props.image} className="w-full" />}
          </RevealChild>
        </Reveal>
      </Container>
    </section>
  )
}

ImageWithText.defaultProps = {}

export default ImageWithText
