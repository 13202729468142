import NextError, { ErrorProps } from 'next/error'
import { NextPageContext } from 'next'

function mapTitle({ title, statusCode }: Pick<ErrorProps, 'title' | 'statusCode'>): string {
  if (title) {
    return title
  }

  switch (statusCode) {
    case 401:
      return 'Du musst eingeloggt sein, um diese Seite ansehen zu können'
    case 403:
      return 'Du bist nicht dazu berechtigt diese Seite anzusehen'
    case 404:
      return 'Diese Seite konnte nicht gefunden werden'
    case 503:
      return 'Wartungsarbeiten. Wir sind in Kürze zurück'
    default:
      return 'Ein unerwarteter Fehler ist aufgetreten'
  }
}

const Error: React.FC<ErrorProps> = ({ statusCode, title, ...props }) => {
  const mappedTitle = mapTitle({ title, statusCode })

  return <NextError statusCode={statusCode} title={mappedTitle} />
}

export default Error
