import React, { useContext } from 'react'
import {
  Entry_Posts_Post,
  GetPostsDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
  Maybe,
  Sets_InsertComponents,
} from '@graphql/generated'
import { motion } from 'framer-motion'
import { defaultGetPostsVariables } from '@components/pages/NewsPage'
import { NewsFilterContext } from '@components/news/filters/Filter'
import { gqlFetcher, queryClient } from '@lib/fetcher'
import { useInfiniteQuery } from 'react-query'
import PostCard from '@components/news/PostCard'
import Button from '@components/common/Button'
import ComponentLoader from '@components/component-loader/ComponentLoader'
import Loading from '@components/common/Loading'
import Reveal from '@components/common/Reveal'

const NUMBER_OF_PAGES_TO_FETCH_AT_ONCE = 5

/**
 * This function inserts given insert components into an
 * array of posts. Every insert component will be
 * inserted after it's given number of posts.
 *
 * @param posts
 * @param inserts
 * @param runThrough
 */
function mergePostsWithInserts(
  posts: Array<Maybe<Entry_Posts_Post>>,
  inserts?: Array<Maybe<Sets_InsertComponents>>,
  runThrough: number = 1
): Array<Maybe<Entry_Posts_Post | (Sets_InsertComponents & { id: string })>> {
  if (!(Array.isArray(inserts) && inserts.length)) {
    return posts
  }

  if (!posts.length) {
    return posts
  }

  const postsWithInserts: Array<
    Maybe<Entry_Posts_Post | (Sets_InsertComponents & { id: string })>
  > = []

  inserts.forEach((insert, i) => {
    if (!insert) {
      return
    }

    const postsBeforeInsert = insert.posts_before_insert || 0

    if (posts.length > postsBeforeInsert) {
      postsWithInserts.push(...posts.splice(0, postsBeforeInsert))
      postsWithInserts.push({
        ...insert,
        id: `insert-${insert.type}-${runThrough}-${i}`,
      })
    } else {
      postsWithInserts.push(...posts)
      posts = []
    }
  })

  if (!posts.length) {
    return postsWithInserts
  }

  postsWithInserts.push(...mergePostsWithInserts(posts, inserts, runThrough + 1))

  return postsWithInserts
}

const PostsListItem: React.FC<{
  item: Maybe<Entry_Posts_Post | (Sets_InsertComponents & { id: string })>
  lazy?: boolean
}> = ({ item, lazy }) => {
  if (!item) return null

  if ('blueprint' in item && item.blueprint === 'post') {
    return (
      <PostCard
        key={item.id}
        post={item}
        lazy={lazy}
        autoHeight
        className="md:shadow-md hover:md:shadow-lg"
      />
    )
  }

  if ('type' in item) {
    return <ComponentLoader key={item.id} group="insert-components" component={item} />
  }

  return null
}

PostsListItem.defaultProps = {
  lazy: true,
}

const PostsList: React.FC<{
  insertComponents?: Maybe<Sets_InsertComponents>[]
}> = ({ insertComponents }) => {
  const { filter, sort } = useContext(NewsFilterContext)

  const variables = {
    ...defaultGetPostsVariables,
    filter,
    sort: sort || defaultGetPostsVariables.sort,
  }

  const postsQuery = useInfiniteQuery<GetPostsQuery>(
    ['GetPosts', variables],
    async ({ pageParam = 1 }) => {
      await queryClient.cancelQueries(['GetPosts'])

      return await gqlFetcher<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, {
        ...variables,
        page: pageParam,
      })()
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.entries?.has_more_pages ? lastPage.entries?.current_page + 1 : null,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  if (postsQuery.isLoading) {
    return (
      <div className="flex items-start justify-center">
        <Loading />
      </div>
    )
  }

  const fetchNextPages = (numberOfPages: number): void => {
    if (!numberOfPages) {
      return
    }

    postsQuery.fetchNextPage().then((result) => {
      if (result.hasNextPage) {
        fetchNextPages(numberOfPages - 1)
      }
    })
  }

  // Map all loaded infinite query pages into one array
  const posts: Array<Maybe<Entry_Posts_Post>> =
    postsQuery.data?.pages.flatMap(
      (page) => (page?.entries?.data as Array<Maybe<Entry_Posts_Post>>) ?? []
    ) ?? []

  const postsWithInserts = mergePostsWithInserts(posts, insertComponents)

  return (
    <div className="space-y-8 md:space-y-16">
      <div className="space-y-6 md:space-y-4">
        {postsWithInserts.map(
          (item, i) =>
            !!item?.id && (
              <Reveal key={item.id}>
                <PostsListItem item={item} lazy={i > 1} />
              </Reveal>
            )
        )}
      </div>

      {postsQuery.hasNextPage ? (
        <Reveal viewport={{ amount: 'all' }} className="flex justify-center">
          <Button
            disabled={postsQuery.isFetching}
            onClick={() => fetchNextPages(NUMBER_OF_PAGES_TO_FETCH_AT_ONCE)}
          >
            Mehr laden
          </Button>
        </Reveal>
      ) : (
        // If there are multiple pages and the user has loaded every page
        (postsQuery.data?.pageParams ?? []).length >= 2 && (
          <Reveal
            viewport={{ amount: 'all' }}
            transition={{ delay: 1 }}
            className="flex justify-center"
          >
            <p className="typo-100-regular max-w-[68ch] text-center">
              Wow, du hast schon sämtliche Beiträge angesehen. Das war aber nur der Anfang. Wir
              sorgen regelmässig für Nachschub - schau doch bald wieder vorbei.
            </p>
          </Reveal>
        )
      )}
    </div>
  )
}

PostsList.displayName = 'PostsList'

PostsList.defaultProps = {
  insertComponents: [],
}

const MemoizedPostsList = React.memo(PostsList)

export default MemoizedPostsList
