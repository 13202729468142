import { Maybe } from '@graphql/generated'
import { Combobox } from '@headlessui/react'
import ChevronIcon from '@components/icons/ChevronIcon'
import { AnimatePresence, motion } from 'framer-motion'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import cx from 'classnames'
import { Fragment, useEffect, useRef, useState } from 'react'
import CrossIcon from '@components/icons/CrossIcon'
import { FormikHandlers } from 'formik'

export type AutocompleteOption = {
  title: string
  key: string
}

export type AutocompleteProps = {
  name?: string
  label: string
  isLoading?: boolean
  disabled?: boolean
  allowCustom?: boolean
  selectedLabel: string
  removeLabel: string | ((option: AutocompleteOption) => string)
  value: Array<Maybe<AutocompleteOption>>
  options: Array<Maybe<AutocompleteOption>>
  onSearch: (query: string) => void
  onChange: (value: Array<Maybe<AutocompleteOption>>) => void
  onBlur?: FormikHandlers['handleBlur']
}

const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  const [query, setQuery] = useState('')
  function onChangeQuery(query: string) {
    setQuery(query)
    props.onSearch(query)
  }

  const onChange = (value: Array<Maybe<AutocompleteOption>>) => {
    props.onChange(value)
    setQuery('')
  }

  const removeOption = (format: AutocompleteOption) => {
    onChange(props.value?.filter((selected) => selected && selected.key !== format.key) ?? [])
  }

  const comboButton = useRef<HTMLButtonElement>(null)

  return (
    <Combobox
      as="div"
      value={props.value}
      onChange={onChange}
      multiple
      name={props.name}
      className="relative space-y-4"
    >
      {({ open }) => (
        <>
          <div className="relative">
            <Combobox.Label className="sr-only">{props.label}</Combobox.Label>
            <Combobox.Input
              placeholder={props.label}
              onChange={(event) => onChangeQuery(event.target.value)}
              displayValue={() => query}
              className="placeholder:typo-200-regular md:placeholder:typo-100-regular typo-200-regular md:typo-100-regular w-full rounded-none border-b-2 border-gray-100 py-2 !text-blue-700 outline-none ring-offset-2 placeholder:!text-blue-700 placeholder:transition focus:placeholder:!text-gray-500"
              onBlur={props.onBlur}
              onFocus={() => !open && comboButton.current?.click()}
            />
            <Combobox.Button
              ref={comboButton}
              className="absolute inset-y-0 right-0 flex items-center justify-center px-2"
            >
              <ChevronIcon
                className={cx('h-3 w-3 transition', {
                  'rotate-180': open,
                })}
              />
            </Combobox.Button>

            <Combobox.Options as={Fragment}>
              <ul className="absolute left-0 bottom-full z-1 -mb-0.5 max-h-[15rem] min-w-full overflow-auto border-2 border-gray-100 bg-white shadow-lg outline-none md:bottom-auto md:top-full md:-mb-0 md:-mt-0.5">
                {props.isLoading ? (
                  <li className="typo-200-regular md:typo-100-regular flex cursor-pointer items-center justify-between px-3 py-2 !text-blue-700 transition">
                    Suchen...
                  </li>
                ) : (
                  <>
                    {props.allowCustom &&
                      query &&
                      !props.options.find(
                        (o) =>
                          o?.key.toLowerCase() === query.toLowerCase() ||
                          o?.title.toLowerCase() === query.toLowerCase()
                      ) &&
                      !props.value?.find(
                        (o) =>
                          o?.key.toLowerCase() === query.toLowerCase() ||
                          o?.title.toLowerCase() === query.toLowerCase()
                      ) && (
                        <Combobox.Option
                          as={Fragment}
                          key={query}
                          value={{ key: query, title: query }}
                        >
                          {({ active, selected, disabled }) => (
                            <li
                              className={cx(
                                'flex cursor-pointer items-center justify-between px-3 py-2 !text-blue-700 transition',
                                selected
                                  ? 'typo-200 md:typo-100'
                                  : 'typo-200-regular md:typo-100-regular',
                                {
                                  'bg-gray-100': active,
                                  'opacity-50': disabled,
                                }
                              )}
                            >
                              <span>&quot;{query}&quot; erstellen</span>
                            </li>
                          )}
                        </Combobox.Option>
                      )}

                    {props.options.map((format) =>
                      !format ? null : (
                        <Combobox.Option as={Fragment} key={format.key} value={format}>
                          {({ active, selected, disabled }) => (
                            <li
                              className={cx(
                                'flex cursor-pointer items-center justify-between px-3 py-2 !text-blue-700 transition',
                                selected
                                  ? 'typo-200 md:typo-100'
                                  : 'typo-200-regular md:typo-100-regular',
                                {
                                  'bg-gray-100': active,
                                  'opacity-50': disabled,
                                }
                              )}
                            >
                              <span>{format.title}</span>
                              {selected && <CheckmarkIcon className="h-2.5 w-2.5 shrink-0" />}
                            </li>
                          )}
                        </Combobox.Option>
                      )
                    )}
                  </>
                )}
              </ul>
            </Combobox.Options>
          </div>

          {Array.isArray(props.value) && !!props.value.length && (
            <ul
              aria-label={props.selectedLabel}
              className="flex flex-wrap items-center gap-x-2.5 gap-y-2"
            >
              {props.value?.map((option) =>
                !option ? null : (
                  <li key={option.key}>
                    <button
                      aria-label={
                        typeof props.removeLabel === 'function'
                          ? props.removeLabel(option)
                          : props.removeLabel
                      }
                      className="typo-100-regular inline-flex items-center space-x-2 bg-blue-200 py-1 pl-3 pr-2 text-blue-900 outline-none transition hover:bg-blue-300 focus-visible:ring"
                      onClick={() => removeOption(option)}
                    >
                      <span>{option.title}</span>
                      <CrossIcon className="h-3 w-3" />
                    </button>
                  </li>
                )
              )}
            </ul>
          )}
        </>
      )}
    </Combobox>
  )
}

export default Autocomplete
