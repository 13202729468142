import { IconProps } from '@additional'

const BurgerIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M0 21.669v-2.266h24v2.266Zm0-8.536v-2.267h24v2.267Zm0-8.537V2.33h24v2.267Z"
        fill="currentColor"
      />
    </svg>
  )
}

BurgerIcon.defaultProps = {
  'aria-hidden': true,
}

export default BurgerIcon
