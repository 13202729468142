import { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as Fathom from 'fathom-client'
import { LoadOptions } from 'fathom-client'

const useFathomTracking = (
  trackingCode?: Parameters<typeof Fathom.load>[0],
  includedDomains?: LoadOptions['includedDomains']
) => {
  const router = useRouter()
  const url = process.env.NEXT_PUBLIC_FATHOM_SCRIPT_URL

  useEffect(() => {
    if (!url) {
      return
    }

    if (!trackingCode) {
      return
    }

    // Initialize Fathom when the app loads
    Fathom.load(trackingCode, {
      url: url,
      includedDomains: includedDomains,
      honorDNT: true,
    })

    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }

    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [trackingCode, includedDomains])
}

export default useFathomTracking
