import React, { useContext } from 'react'
import {
  Entry_Events_Event,
  GetEventsDocument,
  GetEventsQuery,
  GetEventsQueryVariables,
  Maybe,
} from '@graphql/generated'
import { motion } from 'framer-motion'
import { gqlFetcher, queryClient } from '@lib/fetcher'
import { useInfiniteQuery, useQuery } from 'react-query'
import EventCard from '@components/events/EventCard'
import Button from '@components/common/Button'
import { EventsFilterContext } from '@components/events/filters/Filter'
import { defaultGetEventsVariables } from '@components/component-loader/components/Events'
import Loading from '@components/common/Loading'
import Reveal, { RevealChild } from '@components/common/Reveal'

const NUMBER_OF_PAGES_TO_FETCH_AT_ONCE = 2

const EventsList: React.FC<{
  variables?: GetEventsQueryVariables
}> = (props) => {
  const { filter } = useContext(EventsFilterContext)

  const variables = {
    ...defaultGetEventsVariables,
    ...props.variables,
    filter: {
      ...(defaultGetEventsVariables?.filter ?? {}),
      ...(props.variables?.filter ?? {}),
      ...filter,
    },
  }

  const eventsQuery = useInfiniteQuery<GetEventsQuery>(
    ['GetEvents', variables],
    async ({ pageParam = 1 }) => {
      await queryClient.cancelQueries(['GetEvents', variables])

      return await gqlFetcher<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, {
        ...variables,
        page: pageParam,
      })()
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.entries?.has_more_pages ? lastPage.entries?.current_page + 1 : null,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  if (eventsQuery.isLoading) {
    return (
      <div className="flex justify-center">
        <Loading />
      </div>
    )
  }

  const fetchNextPages = (numberOfPages: number): void => {
    if (!numberOfPages) {
      return
    }

    eventsQuery.fetchNextPage().then((result) => {
      if (result.hasNextPage) {
        fetchNextPages(numberOfPages - 1)
      }
    })
  }

  // Map all loaded infinite query pages into one array
  const events: Array<Maybe<Entry_Events_Event>> =
    eventsQuery.data?.pages.flatMap(
      (page) => (page?.entries?.data as Array<Maybe<Entry_Events_Event>>) ?? []
    ) ?? []

  if (!events.length) {
    return (
      <p className="typo-300-regular flex justify-center gap-x-4 text-gray-500">
        <span className="mt-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-gray-500">
          <span aria-hidden="true" className="typo-100 text-white">
            i
          </span>
        </span>
        <span>Zu diesem Filter haben wir momentan keine Veranstaltungen.</span>
      </p>
    )
  }

  return (
    <div className="space-y-16 md:space-y-22">
      <div className="space-y-7 md:space-y-8">
        {events.map(
          (event) =>
            !!event?.id && (
              <Reveal key={event.id} transition={{ delay: 0.3 }}>
                <EventCard event={event} />
              </Reveal>
            )
        )}
      </div>

      {eventsQuery.hasNextPage && (
        <Reveal viewport={{ amount: 'all' }} className="flex justify-center">
          <Button onClick={() => fetchNextPages(NUMBER_OF_PAGES_TO_FETCH_AT_ONCE)}>
            Mehr laden
          </Button>
        </Reveal>
      )}
    </div>
  )
}

EventsList.displayName = 'EventsList'

EventsList.defaultProps = {}

const MemoizedEventsList = React.memo(EventsList)

export default MemoizedEventsList
