import { IconProps } from '@additional'

const ChevronIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 19.215 0 8.838l2.771-3.205L12 13.614l9.229-7.981L24 8.833Z"
        fill="currentColor"
      />
    </svg>
  )
}

ChevronIcon.defaultProps = {
  'aria-hidden': true,
}

export default ChevronIcon
