import AppLink, { AppLinkProps } from '@components/common/AppLink'
import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

export type ButtonProps = AppLinkProps & {
  level?: 'primary' | 'secondary'
  size?: 'large' | 'small'
  noSkew?: boolean
  pressed?: boolean
}

const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(({ level, size, noSkew, pressed, children, className, ...props }, ref) => {
  if (!('href' in props)) {
    props['aria-pressed'] = pressed
  }

  return (
    <AppLink
      ref={ref}
      {...props}
      className={cx(
        'relative isolate inline-flex items-center justify-center px-7 py-2 outline-none transition-all before:absolute before:inset-0 before:-z-1 before:transition-all focus-visible:before:ring',
        { 'typo-600 px-7 py-2 text-xl': size === 'large' },
        { 'typo-500 px-5 py-1.5 text-base': size === 'small' },
        !noSkew &&
          (pressed
            ? 'translate-x-1 before:-skew-x-13'
            : 'hover:translate-x-1 hover:before:-skew-x-13 focus-visible:translate-x-1 focus-visible:before:-skew-x-13 disabled:hover:translate-x-0 disabled:hover:before:skew-x-0'),
        {
          'text-white before:bg-red-500 hover:before:bg-red-600 focus-visible:before:bg-red-600 disabled:before:bg-gray-300 disabled:hover:before:bg-gray-300':
            level === 'primary',
        },
        {
          'text-red-500 before:bg-white hover:before:bg-gray-50 focus-visible:before:bg-gray-50 disabled:opacity-60 disabled:hover:before:bg-white':
            level === 'secondary' && !pressed,
          'text-red-500 before:bg-gray-50 disabled:opacity-60': level === 'secondary' && pressed,
        },
        className
      )}
    >
      {children}
    </AppLink>
  )
})

Button.displayName = 'Button'

Button.defaultProps = {
  level: 'primary',
  size: 'large',
  noSkew: false,
  pressed: false,
}

export default Button
