import React, { HTMLProps } from 'react'
import { Entry_Posts_Post, Set_AsideComponents_Post } from '@graphql/generated'
import cx from 'classnames'
import NextImage from 'next/image'
import { format, isToday, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import ShareIcon from '@components/icons/ShareIcon'
import LikeButton from '@components/news/LikeButton'
import AppLink from '@components/common/AppLink'
import ShareButton from '@components/common/ShareButton'
import PostMediaCarousel from '@components/news/PostMediaCarousel'
import StatamicImage from '@components/common/StatamicImage'
import Avatar from '@components/common/Avatar'
import NextLink from 'next/link'

type Props = Set_AsideComponents_Post & {
  post?: Entry_Posts_Post
  className?: HTMLProps<HTMLDivElement>['className']
}

function formatDate(date?: string | null): string | null {
  if (!date) {
    return null
  }

  const parsedDate = parseISO(date)

  const day = isToday(parsedDate) ? 'Heute' : format(parsedDate, 'd. MMMM yyyy', { locale: de })
  const time = format(parsedDate, 'HH:mm', { locale: de })

  return `${day}, ${time} Uhr`
}

const Post = React.forwardRef<HTMLDivElement, Props>(({ title, post, className }, ref) => {
  return (
    <article ref={ref} className={cx('bg-white transition-all', className)}>
      {post && (
        <NextLink href={post.permalink!}>
          <a
            tabIndex={-1}
            className={cx(
              'peer relative outline-none',
              'after:pointer-events-none after:absolute after:inset-0 after:z-1 after:bg-radial-gradient after:opacity-0 after:transition hover:after:opacity-[0.1]'
            )}
          >
            <span className="sr-only">Beitrag &quot;{post?.title}&quot; öffnen</span>
            <PostMediaCarousel
              tabIndex={-1}
              post={post}
              autoHeight
              contentSize="small"
              className="flex items-center justify-center"
            />
          </a>
        </NextLink>
      )}

      <div className={cx('space-y-1 pl-4 pr-6 pb-4 pt-3')}>
        <h2 className="typo-600 peer">
          {post?.url ? <AppLink href={post.url}>{title}</AppLink> : title}
        </h2>
        {post && (
          <div className="flex items-end justify-between space-x-6">
            <AppLink
              href={post.url!}
              className={cx(
                'block grow space-y-1 outline-none ring-offset-2 focus-visible:ring',
                'transition hover:text-red-500 [.peer:hover+*_&]:text-red-500'
              )}
            >
              <p title="Veröffentlichungsdatum" className="typo-100-regular text-gray-500">
                {formatDate(post.date)}
              </p>
              <p className="typo-300 md:typo-400">{post.title}</p>
            </AppLink>

            <LikeButton post={post} size="small" className="typo-100 -mb-1" />
          </div>
        )}
      </div>
    </article>
  )
})

Post.displayName = 'Post'

Post.defaultProps = {}

export default Post
