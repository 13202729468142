import { IconProps } from '@additional'

const ShareIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="m23.03 11.541-9.192-8.733v4.9C5.259 8.936 2.195 15.064.969 21.192c3.064-4.29 6.741-6.251 12.869-6.251v5.025Z"
        fill="currentColor"
      />
    </svg>
  )
}

ShareIcon.defaultProps = {
  'aria-hidden': true,
}

export default ShareIcon
