import { Slot } from '@radix-ui/react-slot'
import { HTMLAttributes, PropsWithChildren } from 'react'
import cx from 'classnames'

type LoadingProps = {} & HTMLAttributes<HTMLDivElement>

const Loading: React.FC<LoadingProps> = ({ className, ...props }) => {
  return (
    <div className={cx('flex items-center justify-center space-x-1', className)} {...props}>
      <div className="sr-only motion-reduce:not-sr-only">Loading...</div>
      <div className="h-1.5 w-1.5 animate-[bounce_1s_infinite] rounded-full bg-blue-900 motion-reduce:hidden"></div>
      <div className="h-1.5 w-1.5 animate-[bounce_1s_200ms_infinite] rounded-full bg-blue-900 motion-reduce:hidden"></div>
      <div className="h-1.5 w-1.5 animate-[bounce_1s_400ms_infinite] rounded-full bg-blue-900 motion-reduce:hidden"></div>
    </div>
  )
}

Loading.defaultProps = {}

export default Loading
