import {
  Entry_Pages_News,
  GetPostsQueryVariables,
  Scalars,
  Sets_Components,
  useGetPostsQuery,
} from '@graphql/generated'
import { MotionBard } from '@components/common/Bard/Bard'
import { motion } from 'framer-motion'
import Container from '@components/common/Container'
import FiltersCard from '@components/news/filters/FiltersCard'
import { useRouter } from 'next/router'
import { NewsFilterContextProvider } from '@components/news/filters/Filter'
import PostsList from '@components/news/PostsList'
import AppLink from '@components/common/AppLink'
import ArrowLink from '@components/common/ArrowLink'
import ComponentLoader from '@components/component-loader/ComponentLoader'
import AsideTeaser from '@components/component-loader/components/AsideTeaser'

type RequiredProps = {
  page: Entry_Pages_News
}

type OptionalProps = Partial<{}>

export const defaultGetPostsVariables: GetPostsQueryVariables = {
  filter: {},
  sort: 'date desc',
  limit: 10,
}

const NewsPage: React.FC<RequiredProps & OptionalProps> = ({ page }) => {
  return (
    <main className="min-h-screen bg-gray-50 pt-h-logo md:isolate">
      <Container fluid className="pt-6 pb-20 md:pt-9 md:pb-40">
        <NewsFilterContextProvider>
          <div className="mx-auto grid max-w-8xl gap-y-4 md:grid-cols-[14.375rem,auto] md:gap-x-6">
            <aside className="fixed inset-x-0 bottom-0 z-40 row-span-full md:static md:inset-x-auto md:bottom-auto">
              <div className="sticky top-[calc(var(--logo-height)+theme(spacing.9))]">
                <FiltersCard />
              </div>
            </aside>

            <div className="grid gap-y-24 md:gap-y-32 lg:grid-cols-[1.5fr,1fr] lg:gap-x-6 xl:grid-cols-[auto,20.75rem]">
              <PostsList insertComponents={page.insert_components ?? []} />

              <aside className="space-y-6 md:space-y-4">
                <AsideTeaser
                  asH1
                  {...{
                    type: 'teaser',
                    title: page.title,
                    link: page.title_link,
                    link_text: page.title_link_text,
                  }}
                />

                <ComponentLoader
                  group="aside-components"
                  components={page.aside_components ?? []}
                />
              </aside>
            </div>
          </div>
        </NewsFilterContextProvider>
      </Container>
    </main>
  )
}

export default NewsPage
