import React from 'react'
import cx from 'classnames'
import Container from '@components/common/Container'
import { GetEventsQueryVariables, Set_Components_Events } from '@graphql/generated'
import { EventsFilterContextProvider } from '@components/events/filters/Filter'
import EventTypeFilter from '@components/events/filters/EventTypeFilter'
import EventsList from '@components/events/EventsList'
import { motion } from 'framer-motion'
import Reveal from '@components/common/Reveal'

type Props = Set_Components_Events

export const defaultGetEventsVariables: GetEventsQueryVariables = {
  filter: {},
  sort: 'date asc',
  limit: 5,
}

export const defaultGetPastEventsVariables: GetEventsQueryVariables = {
  ...defaultGetEventsVariables,
  sort: 'date desc',
  filter: {
    ...(defaultGetEventsVariables?.filter ?? {}),
    status: 'expired',
  },
}

const Events: React.FC<Props> = (props) => {
  return (
    <section
      className={cx(
        'pt-12 pb-20 md:pt-14 md:pb-30',
        { white: 'bg-white', gray: 'bg-gray-50' }[props.background_color?.value ?? 'transparent']
      )}
    >
      <Container fluid className="">
        <EventsFilterContextProvider>
          <div className="mx-auto max-w-3xl space-y-10 md:space-y-14">
            <div className="">
              <EventTypeFilter />
            </div>

            <div className="space-y-20 md:space-y-25">
              <section className="">
                <h2 className="sr-only">Zukünftige Veranstaltungen</h2>

                <EventsList />
              </section>

              <section className="space-y-12 md:space-y-14">
                <Reveal viewport={{ amount: 'all' }}>
                  <h2 className="typo-400 flex items-center gap-x-6">
                    <span>Vergangene Veranstaltungen</span>
                    <span
                      aria-hidden={true}
                      className="hidden h-0.5 flex-1 bg-blue-700/10 xs:inline-block"
                    ></span>
                  </h2>
                </Reveal>

                <EventsList variables={defaultGetPastEventsVariables} />
              </section>
            </div>
          </div>
        </EventsFilterContextProvider>
      </Container>
    </section>
  )
}

Events.defaultProps = {}

export default Events
