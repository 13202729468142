import React, { HTMLProps, PropsWithChildren } from 'react'
import {
  HTMLMotionProps,
  motion,
  Transition,
  useReducedMotion,
  VariantLabels,
  Variants,
} from 'framer-motion'

type Props = Pick<HTMLProps<HTMLDivElement>, 'id' | 'className'> & Partial<HTMLMotionProps<'div'>>

function useDefaults(): {
  variants: Variants
  initial: VariantLabels
  whileInView: VariantLabels
  viewport: Parameters<typeof motion.div>[0]['viewport']
  transition: Transition
  childTransition: Transition
} {
  const reducedMotion = useReducedMotion()

  return {
    variants: {
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0 },
    },
    initial: reducedMotion ? 'visible' : 'hidden',
    whileInView: 'visible',
    viewport: { margin: '0px 0px -50px 0px', once: true },
    transition: { staggerChildren: 0.2 },
    childTransition: {},
  }
}

export const RevealChild = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, variants, transition, ...props }, ref) => {
    const defaults = useDefaults()

    return (
      <motion.div
        ref={ref}
        variants={{
          ...variants,
          hidden: {
            ...defaults.variants.hidden,
            ...variants?.hidden,
          },
          visible: {
            ...defaults.variants.visible,
            ...variants?.visible,
          },
        }}
        transition={{ ...defaults.childTransition, ...transition }}
        {...props}
      >
        {children}
      </motion.div>
    )
  }
)

RevealChild.displayName = 'RevealChild'

const Reveal = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, variants, initial, whileInView, viewport, transition, ...props }, ref) => {
    const defaults = useDefaults()

    return (
      <motion.div
        ref={ref}
        variants={{
          ...variants,
          hidden: {
            ...defaults.variants.hidden,
            ...variants?.hidden,
          },
          visible: {
            ...defaults.variants.visible,
            ...variants?.visible,
          },
        }}
        initial={initial === undefined ? defaults.initial : initial}
        whileInView={whileInView === undefined ? defaults.whileInView : whileInView}
        viewport={{ ...defaults.viewport, ...viewport }}
        transition={{ ...defaults.transition, ...transition }}
        {...props}
      >
        {children}
      </motion.div>
    )
  }
)

Reveal.displayName = 'Reveal'

export default Reveal
