import { Scalars } from '@graphql/generated'
import React, { PropsWithChildren, useState } from 'react'
import { useRouter } from 'next/router'

export type EventsFilterContextType = {
  filter: {
    event_types?: { contains: string | string[] }
  }
}

export const EventsFilterContext = React.createContext<EventsFilterContextType>({
  filter: {},
})

export const EventsFilterContextProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const router = useRouter()

  const filter: EventsFilterContextType['filter'] = {}

  if (router.query.event_type) {
    filter['event_types'] = { contains: router.query.event_type }
  }

  return <EventsFilterContext.Provider value={{ filter }}>{children}</EventsFilterContext.Provider>
}
