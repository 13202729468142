import React from 'react'
import { useRouter } from 'next/router'
import { useGetTeamFormatsQuery } from '@graphql/generated'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import FilterButton from '@components/common/FilterButton'
import Reveal, { RevealChild } from '@components/common/Reveal'

const TeamFormatFilter: React.FC<{}> = (props) => {
  const router = useRouter()

  const teamFormatsQuery = useGetTeamFormatsQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const selectedTeamFormat = router.query.team_format

  const queryLinkBuilder = useQueryLinkBuilder()

  return (
    <Reveal transition={{ delayChildren: 0.5 }} className="space-y-2">
      <label htmlFor="filter-team-format" className="sr-only">
        {teamFormatsQuery.data?.taxonomy?.title}
      </label>

      <div id="filter-team-format" className="flex flex-wrap justify-center gap-y-3.5 gap-x-4">
        <RevealChild key="alle">
          <FilterButton
            href={queryLinkBuilder('team_format', undefined)}
            active={!selectedTeamFormat}
          >
            Alle
          </FilterButton>
        </RevealChild>

        {teamFormatsQuery.data?.terms?.data?.map((teamFormat) =>
          !teamFormat?.slug ? null : (
            <RevealChild key={teamFormat.slug}>
              <FilterButton
                href={queryLinkBuilder('team_format', teamFormat.slug)}
                active={selectedTeamFormat === teamFormat.slug}
              >
                {teamFormat.title}
              </FilterButton>
            </RevealChild>
          )
        )}
      </div>
    </Reveal>
  )
}

export default TeamFormatFilter
