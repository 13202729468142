import { useEffect, useState } from 'react'

const useMutationObserver = (
  element: HTMLElement | null,
  callback: MutationCallback,
  options: MutationObserverInit = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  useEffect(() => {
    if (element) {
      const observer = new MutationObserver(callback)

      observer.observe(element, options ?? {})

      return () => {
        observer.disconnect()
      }
    }
  }, [callback, element, options])
}

export default useMutationObserver
