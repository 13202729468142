import React, { HTMLProps } from 'react'
import { Entry_Team_TeamMember, Term_TeamFormats_TeamFormat } from '@graphql/generated'
import cx from 'classnames'
import AppLink from '@components/common/AppLink'
import StatamicImage from '@components/common/StatamicImage'
import ArrowIcon from '@components/icons/ArrowIcon'

type RequiredProps = {
  teamMember: Entry_Team_TeamMember
}

type OptionalProps = {
  className?: HTMLProps<HTMLDivElement>['className']
}

const TeamMemberCard = React.forwardRef<HTMLDivElement, RequiredProps & OptionalProps>(
  ({ teamMember, className }, ref) => {
    const image = teamMember.image ?? teamMember.author?.avatar

    const teamFormatTitleKeys: {
      [key: string]: keyof Term_TeamFormats_TeamFormat
    } = {
      '': 'title',
      diverse: 'title',
      female: 'title_female',
      male: 'title_male',
    }
    const teamFormatTitleKey: keyof Term_TeamFormats_TeamFormat =
      teamFormatTitleKeys[teamMember.author?.salutation?.value ?? ''] ?? 'title'

    return (
      <article ref={ref} className={className}>
        <AppLink
          href={teamMember.url ?? '#'}
          className="group flex flex-col outline-none focus-visible:ring"
        >
          <div className="grow overflow-hidden">
            {image && (
              <StatamicImage
                loading="lazy"
                asset={image}
                className="aspect-[21/25] w-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
            )}
          </div>

          <div className="relative isolate shrink-0 pl-2.5 pt-3 pb-4 pr-4">
            <div
              className={cx(
                'absolute top-0 -right-1 -translate-y-2/3 px-4 py-3 xl:-translate-y-1/2',
                'after:absolute after:inset-0 after:-z-1 after:-skew-x-13 after:bg-white after:transition after:duration-300 group-hover:after:bg-red-500 [@media(hover:none)]:after:bg-red-500'
              )}
            >
              <ArrowIcon className="h-5 w-5 text-inherit transition duration-300 group-hover:text-white [@media(hover:none)]:text-white" />
            </div>

            <div className="space-y-0.5 transition duration-300 group-hover:translate-x-1">
              <p className="typo-200 md:typo-400 leading-tight">{teamMember.author?.name}</p>
              <p className="typo-100-regular md:typo-200-regular">
                {(teamMember.team_formats as Array<Term_TeamFormats_TeamFormat>)
                  ?.map((format) => (!format ? null : format[teamFormatTitleKey]))
                  ?.join(', ')}
              </p>
            </div>
          </div>
        </AppLink>
      </article>
    )
  }
)

TeamMemberCard.displayName = 'TeamMemberCard'

TeamMemberCard.defaultProps = {}

export default React.memo(TeamMemberCard)
