import { PropsWithChildren } from 'react'
import cx from 'classnames'
import AppLink, { AppLinkProps } from '@components/common/AppLink'

type FilterButtonProps = {
  active?: boolean
  noBorder?: boolean
  className?: string
} & AppLinkProps

const FilterButton: React.FC<PropsWithChildren<FilterButtonProps>> = ({
  active,
  noBorder,
  className,
  children,
  ...props
}) => {
  className = cx(
    'inline-block focus-visible:ring transition-all outline-none hover:border hover:px-[calc(theme(spacing.5)+0.0625rem)] hover:py-[calc(theme(spacing.2)+0.0625rem)]',
    noBorder ? 'border-0 px-5.5 py-2.5' : 'border-2 px-5 py-2',
    active
      ? 'typo-200 border-red-500 bg-red-500 text-white hover:border-red-600 hover:bg-red-600'
      : 'typo-200-regular border-gray-100 bg-white text-blue-900 hover:border-red-500 hover:text-red-500',
    className
  )

  return (
    <AppLink className={className} scroll={false} {...props}>
      {children}
    </AppLink>
  )
}

FilterButton.defaultProps = {
  active: false,
  replace: true,
}

export default FilterButton
