import React, { useContext, useEffect, useState } from 'react'
import cx from 'classnames'
import {
  Entry_Posts_Post,
  useCreateLikeMutation,
  useDeleteLikeMutation,
  useGetCurrentUserQuery,
} from '@graphql/generated'
import ExplosionIcon from '@components/icons/ExplosionIcon'
import { queryClient } from '@lib/fetcher'
import { QueryKey } from 'react-query'
import { Updater } from 'react-query/types/core/utils'
import { AnimatePresence, motion } from 'framer-motion'
import ExplosionFilledIcon from '@components/icons/ExplosionFilledIcon'
import { useRouter } from 'next/router'
import { hasPermission } from '@lib/user'
import { ToastsContext } from '@components/toasts/Toasts/Toasts'

type Props = {
  post: Entry_Posts_Post
  size?: 'normal' | 'small'
  className?: HTMLButtonElement['className']
  children?: (likes: number) => string | React.ReactElement
}

async function optimisticOnMutate<TQueryData>(
  queryKey: QueryKey,
  updater: Updater<TQueryData | undefined, TQueryData | undefined>
) {
  await queryClient.cancelQueries(queryKey)

  const previous = queryClient.getQueryData<TQueryData | undefined>(queryKey)

  queryClient.setQueryData<TQueryData | undefined>(queryKey, updater)
}

const LikeButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ post, size, className, children }, ref) => {
    const router = useRouter()
    const userQuery = useGetCurrentUserQuery()
    const user = userQuery.data?.user
    const isLoggedIn = !!user

    const { dispatchToast } = useContext(ToastsContext)

    let [isLiked, setIsLiked] = useState<boolean>(!!post.liked_at)
    let [numberOfLikes, setNumberOfLikes] = useState<number>(post.number_of_likes ?? 0)

    useEffect(() => {
      setIsLiked(!!post.liked_at)
      setNumberOfLikes(post.number_of_likes ?? 0)
    }, [post])

    const createLikeMutation = useCreateLikeMutation({
      onMutate: async () => {
        setIsLiked(true)
        setNumberOfLikes((numberOfLikes) => numberOfLikes + 1)
      },
      onError: (error, variables, context) => {
        setIsLiked(false)
        setNumberOfLikes((numberOfLikes) => (numberOfLikes > 0 ? numberOfLikes - 1 : 0))
      },
      onSettled: () => {
        queryClient.invalidateQueries(['GetPageBySlug', { uri: post.uri }])

        // Not invalidated, because this could change the order of the posts immediately if ordered by populatiry.
        // queryClient.invalidateQueries(['GetPosts'])
      },
    })

    const deleteLikeMutation = useDeleteLikeMutation({
      onMutate: async () => {
        setIsLiked(false)
        setNumberOfLikes((numberOfLikes) => (numberOfLikes > 0 ? numberOfLikes - 1 : 0))
      },
      onError: (error, variables, context) => {
        setIsLiked(true)
        setNumberOfLikes((numberOfLikes) => numberOfLikes + 1)
      },
      onSettled: () => {
        queryClient.invalidateQueries(['GetPageBySlug', { uri: post.uri }])

        // Not invalidated, because this could change the order of the posts immediately if ordered by populatiry.
        // queryClient.invalidateQueries(['GetPosts'])
      },
    })

    function onClick() {
      if (!isLoggedIn) {
        router.push('/login?redirect=' + encodeURIComponent(router.asPath))
        return
      }

      if (!hasPermission(user, 'website like posts')) {
        dispatchToast({
          level: 'error',
          title: 'Das klappt so leider nicht.',
          description: 'Du benötigst eine gültige Mitgliedschaft, um Posts liken zu können.',
        })
        router.push('/profil/mitgliedschaft')
        return
      }

      if (isLiked) {
        deleteLikeMutation.mutate({ entryId: post.id })
      } else {
        createLikeMutation.mutate({ entryId: post.id })
      }
    }

    const loading = createLikeMutation.isLoading || deleteLikeMutation.isLoading
    const published = post.published
    const disabled = !published || loading

    return (
      <button
        ref={ref}
        aria-label={isLiked ? 'Like entfernen' : 'Like hinzufügen'}
        className={cx(
          'inline-flex items-center space-x-2',
          'group outline-none ring-offset-2 transition focus-visible:ring enabled:hover:text-red-500',
          'disabled:cursor-default disabled:opacity-50',
          className
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <AnimatePresence exitBeforeEnter>
          {isLiked ? (
            <motion.span
              key="filled"
              animate={{ opacity: 1, transition: { duration: 0.05 } }}
              exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.1 } }}
            >
              <ExplosionFilledIcon
                className={cx('text-red-500 transition group-enabled:group-hover:scale-125', {
                  'h-9 h-9': size === 'normal',
                  'h-8 h-8': size === 'small',
                })}
              />
            </motion.span>
          ) : (
            <motion.span
              key="empty"
              animate={{ opacity: 1, transition: { duration: 0.05 } }}
              exit={{ opacity: 0, scale: 0.8, transition: { duration: 0.1 } }}
            >
              <ExplosionIcon
                className={cx('text-red-500 transition group-enabled:group-hover:scale-125', {
                  'h-9 h-9': size === 'normal',
                  'h-8 h-8': size === 'small',
                })}
              />
            </motion.span>
          )}
        </AnimatePresence>
        <span>{children?.(numberOfLikes) ?? numberOfLikes}</span>
      </button>
    )
  }
)

LikeButton.displayName = 'LikeButton'

LikeButton.defaultProps = {
  size: 'normal',
}

export default LikeButton
