import React, { useState } from 'react'
import { useRouter } from 'next/router'
import useQueryLinkBuilder from '@hooks/useQueryLinkBuilder'
import Select, { SelectOption } from '@components/forms/fields/Select'

const options: SelectOption[] = [
  {
    key: 'chronological',
    title: 'Chronologisch',
  },
  {
    key: 'popularity',
    title: 'Beliebtheit',
  },
  {
    key: 'unpopularity',
    title: 'Unbeliebtheit',
  },
]

const SortSelect: React.FC<{}> = (props) => {
  const router = useRouter()
  const queryLinkBuilder = useQueryLinkBuilder()

  const currentSort = router.query.sort

  const sortOption = options.find((o) => o.key === currentSort)

  const update = (newSortOption: SelectOption) => {
    router.replace(queryLinkBuilder('sort', newSortOption.key))
  }

  return <Select label="Sortieren nach" value={sortOption} options={options} onChange={update} />
}

export default SortSelect
