import { FieldInputProps, FieldMetaProps, useField } from 'formik'
import React, { cloneElement, FC, PropsWithChildren, useMemo } from 'react'
import * as Label from '@radix-ui/react-label'
import { useLabelContext } from '@radix-ui/react-label'
import cx from 'classnames'

export type FormFieldChildProps<T = any> = FieldInputProps<T> & { meta: FieldMetaProps<T> }

type RequiredProps = {
  name: string
  children: React.ReactElement & { type: { displayName: string } }
}

type OptionalProps = {
  className?: string
  labelClassName?: string
  label?: string | (() => React.ReactElement)
  htmlFor?: string
  id?: string
  errorMessagePostfix?: React.ReactElement
}

const LabelProvider: FC<{ children: (props: { labelId?: string }) => JSX.Element }> = ({
  children,
}) => {
  const labelId = useLabelContext()
  return children({ labelId })
}

const FormField: FC<RequiredProps & OptionalProps> = ({
  children,
  className,
  name,
  label,
  labelClassName,
  errorMessagePostfix,
}) => {
  const [field, fieldMeta] = useField(name)
  const fieldElement = useMemo(() => {
    return cloneElement(children, {
      ...field,
      meta: fieldMeta,
    })
  }, [children, field, fieldMeta])

  return (
    <div className={className}>
      <Label.Root asChild role={undefined}>
        <label className="flex flex-col">
          <LabelProvider>
            {({ labelId }) => (
              <>
                <span className={cx('ml-1', labelClassName)}>
                  {typeof label === 'function' ? label() : label}
                </span>
                {fieldElement}

                {(errorMessagePostfix || (!!fieldMeta.error && fieldMeta.touched)) && (
                  <div className="mt-1 flex justify-between gap-x-4">
                    {!!fieldMeta.error && fieldMeta.touched ? (
                      <div
                        id={labelId ? `${labelId}-error` : undefined}
                        className="typo-100 text-red-500"
                      >
                        {fieldMeta.error}
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {errorMessagePostfix}
                  </div>
                )}
              </>
            )}
          </LabelProvider>
        </label>
      </Label.Root>
    </div>
  )
}

export default React.memo(FormField)
