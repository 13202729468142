import * as ReactToast from '@radix-ui/react-toast'
import React, { PropsWithChildren, useContext, useReducer, useState } from 'react'
import cx from 'classnames'
import NextLink from 'next/link'
import Link from '@components/common/Link'
import Toast, { ToastProps } from '@components/toasts/Toast/Toast'

export type DispatchToastProps = {
  title: string
  level?: ToastProps['level']
  description?: string

  type?: ReactToast.ToastProps['type']
  duration?: ReactToast.ToastProps['duration']
}

export type ToastsContextType = {
  toasts: ToastProps[]
  dispatchToast: (toast: DispatchToastProps) => ToastProps | null
  closeToast: (toast: ToastProps) => void
}

export const ToastsContext = React.createContext<ToastsContextType>({
  toasts: [],
  dispatchToast: (props: DispatchToastProps): ToastProps | null => null,
  closeToast: (toast: ToastProps): void => {},
})

export const Provider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([])

  const [counter, increaseCounter] = useReducer((prev) => prev + 1, 0)

  const dispatchToast: ToastsContextType['dispatchToast'] = (props) => {
    const toast: ToastProps = {
      id: `toast-${counter}`,
      open: true,
      level: props.level ?? 'success',
      title: props.title,
      description: props.description,

      type: props.type,
      duration: props.duration,
    }

    setToasts((prevToasts) => [...prevToasts, toast])

    increaseCounter()

    return toast
  }

  const closeToast: ToastsContextType['closeToast'] = (toast) => {
    setToasts((prevToasts) =>
      prevToasts.map((t) => (t.id === toast.id ? { ...t, open: false } : t))
    )

    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id))
    }, 1000)
  }

  return (
    <ReactToast.Provider>
      <ToastsContext.Provider value={{ toasts, dispatchToast, closeToast }}>
        {children}
      </ToastsContext.Provider>
    </ReactToast.Provider>
  )
}

export const Viewport: React.FC<ReactToast.ToastViewportProps> = ({ className, ...props }) => {
  const { toasts, closeToast } = useContext(ToastsContext)

  return (
    <>
      {toasts.map((toast) => (
        <Toast key={toast.id} toast={toast} onClose={closeToast} />
      ))}

      <ReactToast.Viewport
        {...props}
        className={cx(
          'fixed bottom-0 right-0 z-100 flex w-screen max-w-sm flex-col gap-2 p-6 p-6',
          className
        )}
      />
    </>
  )
}
