import {
  Asset_Assets,
  Set_Content_Image,
  Sets_Content,
  useGetCurrentUserQuery,
} from '@graphql/generated'
import cx from 'classnames'
import { HTMLProps, MouseEventHandler } from 'react'
import StatamicImage from '@components/common/StatamicImage'
import Bard from '@components/common/Bard/Bard'
import { useSwiper } from 'swiper/react'
import FlatExplosionCutIllustration from '@components/illustrations/FlatExplosionCutIllustration'
import LogoSmall from '@components/icons/LogoSmall'
import LogoSmallLight from '@components/icons/LogoSmallLight'
import LogoSmallDark from '@components/icons/LogoSmallDark'
import DownloadIcon from '@components/icons/DownloadIcon'
import { generateSrc } from '@components/common/CloudinaryImage'
import { hasPermission } from '@lib/user'
import dynamic from 'next/dynamic'

const ReactPlayerWrapper = dynamic(() => import('@components/common/ReactPlayerWrapper'))

type RequiredProps = {
  content: Sets_Content
}

type OptionalProps = Partial<
  Omit<HTMLProps<HTMLDivElement>, 'content'> & {
    className: string
    contentSize: 'normal' | 'small'
    lazy: boolean
    isVisible: boolean
    allowGrowing: boolean
    allowClickInside: boolean
    autoPlay: boolean
  }
>

export function generateSrcForPostImage(
  content: { image?: null | Pick<NonNullable<Set_Content_Image['image']>, 'permalink'> } & Pick<
    Set_Content_Image,
    'logo_position' | 'logo_color'
  >
): string {
  if (!content.image) return ''

  return generateSrc({
    src: content.image.permalink?.replace(/https?:\/\/[^/]+/i, '') ?? '',
    transforms: content.logo_color?.value
      ? `l_watermarks:watermark-petarde-${content.logo_color?.value},c_fit,w_0.13,fl_relative,g_${
          {
            top_right: 'north_east',
            top_left: 'north_west',
            bottom_right: 'south_east',
            bottom_left: 'south_west',
          }[content.logo_position?.value ?? 'top_right']
        },x_30,y_30`
      : undefined,
  })
}

export function prepareTextForStencil(text?: string): string {
  if (!text) {
    return ''
  }

  text = text
    .replace(/<br(?: \/)?>/g, '\n')
    .replace(/<(?:\/)?strong>/g, '**')
    .replace(/<(?:\/)?em>/g, '_')
    .replace(/<(?:\/)?strike>/g, '~~')

  const el = document.createElement('div')
  el.innerHTML = text

  return el.innerText
}

const PostMedia: React.FC<RequiredProps & OptionalProps> = ({
  content,
  contentSize,
  lazy,
  isVisible,
  allowGrowing,
  allowClickInside,
  autoPlay,
  className,
  ...props
}) => {
  const swiper = useSwiper()

  const userQuery = useGetCurrentUserQuery()
  const user = userQuery.data?.user

  function downloadOriginal(ev: any) {
    ev.stopPropagation()
    ev.preventDefault()

    let url = null

    if ('image' in content) {
      url = generateSrcForPostImage(content) || null
    } else if ('text' in content) {
      url = `https://images.usestencil.com/qs/147dbff8-597a-4349-90d0-f5f459c83e4d/${
        {
          red: 'dyMz3yqv5hSsaDTpzW2kHY.png',
          petrol: 'G62j6a82SXoggedzUn7MbY.png',
          white: 'xR3UWhAgMAqu9uzr82gB42.png',
        }[content.background_color?.value ?? 'red']
      }?text=${encodeURIComponent(prepareTextForStencil(content.text))}`
    } else {
      throw new Error(`Cannot download original of media of type ${content.type}`)
    }

    if (url) {
      window.open(url, '_blank')
    }
  }

  if ('image' in content) {
    if (!content.image) {
      // console.warn('PostMedia() : Media with no image received', content)

      return <></>
    }

    return (
      <div className={cx('group relative bg-white', className)}>
        <StatamicImage
          loading={lazy ? 'lazy' : 'eager'}
          asset={content.image}
          transforms={
            content.logo_color?.value
              ? `l_watermarks:watermark-petarde-${
                  content.logo_color?.value
                },c_fit,w_0.13,fl_relative,g_${
                  {
                    top_right: 'north_east',
                    top_left: 'north_west',
                    bottom_right: 'south_east',
                    bottom_left: 'south_west',
                  }[content.logo_position?.value ?? 'top_right']
                },x_30,y_30`
              : undefined
          }
          onLoad={() => swiper?.update()}
          sizes="(min-width: 768px) 50vw, 100vw"
          className={cx(
            'w-full object-contain',
            allowGrowing
              ? 'aspect-[auto_16/9] h-full max-h-[calc(100vh-var(--header-height))] md:max-h-[calc(100vh-var(--header-height)-3rem)]'
              : 'aspect-video h-full max-h-[60vh]'
          )}
        />

        {hasPermission(user, 'website download original media') && (
          <button
            type="button"
            onClick={downloadOriginal}
            className={cx(
              'absolute right-2 p-2 outline-none transition-all hover:scale-125 focus:opacity-100 focus-visible:ring group-hover:opacity-100 [@media(hover:hover)]:opacity-0',
              content.logo_position?.value === 'bottom_right' ? 'top-2' : 'bottom-2'
            )}
            tabIndex={-1}
          >
            <span className="sr-only">Bild herunterladen</span>
            <DownloadIcon className="h-5 w-5 text-blue-700" />
          </button>
        )}
      </div>
    )
  }

  if ('video' in content) {
    if (!content.video) {
      return (
        <div className="flex aspect-video items-center justify-center">
          <p className="typo-100-regular py-10 px-10">
            Wir werden dein Video auf YouTube hochladen und im Anschluss hier freischalten.
            <br />
            Bis es soweit ist bitten wir um etwas Geduld.
          </p>
        </div>
      )
    }

    return (
      <ReactPlayerWrapper
        className={cx('flex aspect-video items-center justify-center bg-white', className)}
        url={content.video}
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: {},
          },
        }}
        forwardEvents={allowClickInside ? ['click'] : []}
        autoPlay={autoPlay}
      />
    )
  }

  if ('text' in content) {
    return (
      <div
        className={cx(
          'group relative isolate flex aspect-video items-center justify-center overflow-hidden',
          {
            red: 'bg-red-600 text-white',
            petrol: 'bg-blue-700 text-white',
            white: 'bg-white text-blue-900',
          }[content.background_color.value ?? 'red'] ?? 'bg-red-600 text-white',
          className
        )}
        {...props}
      >
        <FlatExplosionCutIllustration
          className={cx(
            'absolute bottom-0 -z-1 h-[140%] w-auto',
            {
              red: 'text-red-500',
              petrol: 'text-white/10',
              white: 'text-gray-50',
            }[content.background_color.value ?? 'red'] ?? 'text-red-500'
          )}
        />
        {content.background_color.value === 'white' ? (
          <LogoSmall className="absolute top-4 right-4 w-[13%]" />
        ) : content.background_color.value === 'petrol' ? (
          <LogoSmallLight className="absolute top-4 right-4 w-[13%]" />
        ) : (
          <LogoSmallDark className="absolute top-4 right-4 w-[13%]" />
        )}

        <div
          className={cx(
            'pointer-events-none flex h-full max-h-full w-full flex-col items-start overflow-hidden',
            {
              small: 'py-10 px-10',
              normal: 'py-10 pl-10 pr-15 xl:px-20 2xl:pl-25 2xl:pr-20',
            }[contentSize ?? 'normal']
          )}
        >
          <div className="flex flex-grow flex-col items-start justify-center">
            <Bard
              html={content.text}
              className={cx('whitespace-pre-line', {
                'typo-400-regular xs:typo-1000-regular sm:typo-1100-regular lg:typo-1000-regular xl:typo-1100-regular !text-inherit':
                  contentSize === 'normal' && content.text.length < 80,
                'typo-200-regular xs:typo-300-regular sm:typo-1000-regular lg:typo-400-regular xl:typo-1100-regular !text-inherit':
                  contentSize === 'normal' &&
                  content.text.length >= 80 &&
                  content.text.length < 140,
                'typo-100-regular xs:typo-200-regular sm:typo-300-regular lg:typo-200-regular xl:typo-1000-regular 2xl:typo-1100-regular !text-inherit':
                  contentSize === 'normal' && content.text.length >= 140,
                'typo-400-regular xs:typo-1000-regular sm:typo-1100-regular lg:typo-300-regular xl:typo-400-regular !text-inherit':
                  contentSize === 'small' && content.text.length < 80,
                'typo-200-regular xs:typo-300-regular sm:typo-1000-regular lg:typo-100-regular xl:typo-200-regular !text-inherit':
                  contentSize === 'small' && content.text.length >= 80 && content.text.length < 140,
                'typo-100-regular xs:typo-200-regular sm:typo-300-regular lg:typo-100-regular !text-inherit':
                  contentSize === 'small' && content.text.length >= 140,
              })}
            />
          </div>
        </div>

        {hasPermission(user, 'website download original media') && (
          <button
            type="button"
            onClick={downloadOriginal}
            className="absolute right-2 bottom-2 p-2 outline-none transition-all hover:scale-125 focus:opacity-100 focus-visible:ring group-hover:opacity-100 [@media(hover:hover)]:opacity-0"
            tabIndex={-1}
          >
            <span className="sr-only">Bild herunterladen</span>
            <DownloadIcon className="h-5 w-5 text-blue-700" />
          </button>
        )}
      </div>
    )
  }

  console.warn('PostMedia() : Unknown Media Type received', content)

  return null
}

PostMedia.defaultProps = {
  contentSize: 'normal',
  lazy: true,
  isVisible: true,
}

export default PostMedia
