import Footer from '@components/Footer'
import Header from '@components/Header'
import { queryClient } from '@lib/fetcher'
import '@styles/globals.css'
import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { Hydrate, InfiniteData, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Toasts from '@components/toasts/Toasts/Toasts'
import { setLocale } from 'yup'
import yupLocaleDe from '@lang/de/yup'
import Head from 'next/head'
import Error from '@components/common/Error'
import usePreviewFromCookie from '@hooks/usePreviewFromCookie'
import * as Tooltip from '@radix-ui/react-tooltip'
import { WithContext, Event, Thing, ComedyEvent, Graph, CreativeWork } from 'schema-dts'
import { Router } from 'next/router'
import { defaultGetEventsVariables } from '@components/component-loader/components/Events'
import {
  Entry_Events_Event,
  Entry_Posts_Post,
  GetEventsQuery,
  GetPageBySlugQuery,
  GetPostsQuery,
  AssetInterface,
} from '@graphql/generated'
import { defaultGetPostsVariables } from '@components/pages/NewsPage'
import useFathomTracking from '@hooks/useFathomTracking'

setLocale(yupLocaleDe)

type PageMeta = {
  hidden: boolean
  title: string
  description: string
  image?: string | null | Pick<AssetInterface, 'permalink'>
  type: string
  url: string
  color: string
}

function stripHtmlTags(string: string): string {
  return string.replace(/(<([^>]+)>)/gi, '')
}

function structuredId(id: string, prefix?: string): string {
  return `${process.env.NEXT_PUBLIC_APP_URL}${prefix ?? ''}/#${id}`
}

function buildStructuredData(
  pageProps: AppProps['pageProps'],
  meta: PageMeta,
  router: Router
): Graph {
  const things: Array<Thing> = []

  const page = queryClient.getQueryData<GetPageBySlugQuery>([
    'GetPageBySlug',
    { uri: router.asPath },
  ])?.entry

  things.push({
    '@type': 'Organization',
    '@id': structuredId('die-petarde'),
    name: 'Die Petarde',
  })

  if (page) {
    things.push({
      '@type': 'WebPage',
      '@id': structuredId('page', router.asPath),
      url: meta.url,
      name: stripHtmlTags(meta.title),
      description: stripHtmlTags(meta.description),
      inLanguage: 'de-CH',
      dateModified: page.last_modified ?? undefined,
      mainEntity: { '@id': structuredId('die-petarde') },
    })

    if (page.blueprint === 'post' && 'author' in page) {
      things.push({
        '@type': 'CreativeWork',
        '@id': structuredId('creative-work', page.uri ?? ''),
        mainEntityOfPage: { '@id': structuredId('page', router.asPath) },
        url: page.permalink ?? undefined,
        headline: stripHtmlTags(page.title),
        image:
          (typeof meta.image === 'string'
            ? meta.image
            : meta.image && 'permalink' in meta.image
            ? meta.image.permalink
            : undefined) ?? undefined,
        author: {
          '@type': 'Person',
          name: page.author.name ?? undefined,
          callSign: page.author.username ?? undefined,
          givenName: page.author.firstname ?? undefined,
          familyName: page.author.lastname ?? undefined,
          funder: { '@id': structuredId('die-petarde') },
          memberOf: { '@id': structuredId('die-petarde') },
          worksFor: { '@id': structuredId('die-petarde') },
        },
        datePublished: page.date ?? undefined,
        funder: { '@id': structuredId('die-petarde') },
        genre: 'Satire',
        keywords:
          'tags' in page ? page.tags?.map((t) => t?.title).join(', ') ?? undefined : undefined,
      })
    }

    if (page.blueprint === 'team_member' && 'biography' in page) {
      things.push({
        '@type': 'Person',
        '@id': structuredId('person', page.uri ?? ''),
        mainEntityOfPage: { '@id': structuredId('page', router.asPath) },
        url: page.permalink ?? undefined,
        image:
          (typeof meta.image === 'string'
            ? meta.image
            : meta.image && 'permalink' in meta.image
            ? meta.image.permalink
            : undefined) ?? undefined,
        name: page.author.name ?? undefined,
        callSign: page.author.username ?? undefined,
        givenName: page.author.firstname ?? undefined,
        familyName: page.author.lastname ?? undefined,
        funder: { '@id': structuredId('die-petarde') },
        memberOf: { '@id': structuredId('die-petarde') },
        worksFor: { '@id': structuredId('die-petarde') },
      })
    }

    if (page.blueprint === 'news' && 'insert_components' in page) {
      const posts =
        queryClient
          .getQueryData<InfiniteData<GetPostsQuery>>(['GetPosts', defaultGetPostsVariables])
          ?.pages.flatMap((p) => p.entries?.data) ?? []

      things.push(
        ...(posts as Array<Entry_Posts_Post>).map(
          (post: Entry_Posts_Post): CreativeWork => ({
            '@type': 'CreativeWork',
            '@id': structuredId('creative-work', post.uri ?? ''),
            mainEntityOfPage: post.permalink ?? undefined,
            url: post.permalink ?? undefined,
            headline: stripHtmlTags(post.title),
            author: {
              '@type': 'Person',
              name: post.author.name ?? undefined,
              callSign: post.author.username ?? undefined,
              givenName: post.author.firstname ?? undefined,
              familyName: post.author.lastname ?? undefined,
              funder: { '@id': structuredId('die-petarde') },
              memberOf: { '@id': structuredId('die-petarde') },
              worksFor: { '@id': structuredId('die-petarde') },
            },
            datePublished: post.date ?? undefined,
            funder: { '@id': structuredId('die-petarde') },
            genre: 'Satire',
            keywords: post.tags?.map((t) => t?.title).join(', ') ?? undefined,
          })
        )
      )
    }

    if ('components' in page) {
      page.components?.forEach((component) => {
        if (component?.type === 'events') {
          const events =
            queryClient
              .getQueryData<InfiniteData<GetEventsQuery>>(['GetEvents', defaultGetEventsVariables])
              ?.pages.flatMap((p) => p.entries?.data) ?? []

          things.push(
            ...(events as Array<Entry_Events_Event>).map(
              (event: Entry_Events_Event): ComedyEvent => ({
                '@type': 'ComedyEvent',
                name: stripHtmlTags(event.title),
                description: stripHtmlTags(event.description ?? ''),
                startDate: stripHtmlTags(event.date ?? ''),
                location: {
                  '@type': 'Place',
                  name: stripHtmlTags(event.location ?? ''),
                  url: event.location_link ?? undefined,
                },
                organizer: {
                  '@id': structuredId('die-petarde'),
                },
              })
            )
          )
        }
      })
    }
  }

  return {
    '@context': 'https://schema.org',
    '@graph': things,
  }
}

function MyApp({
  Component,
  pageProps,
  router,
}: AppProps<{
  meta: PageMeta & { image: Pick<AssetInterface, 'permalink'> }
  dehydratedState: any
  errorCode?: number | null
  errorTitle?: string
}>) {
  usePreviewFromCookie()

  useFathomTracking(
    process.env.NEXT_PUBLIC_FATHOM_TRACKING_CODE,
    process.env.NEXT_PUBLIC_APP_URL ? [new URL(process.env.NEXT_PUBLIC_APP_URL).host] : []
  )

  const meta: PageMeta = {
    hidden: pageProps.meta?.hidden ?? false,
    title: pageProps.meta?.title ?? 'Die Petarde',
    description:
      pageProps.meta?.description ??
      'Die neue, unabhängige Satireplattform der Schweiz! Zensurfrei, branchenübergreifend und publikumsfinanziert, unabhängig von Investoren oder Medienkonzernen und mit einem revolutionären Bezahlmodell.',
    image:
      typeof pageProps.meta?.image === 'string'
        ? pageProps.meta?.image
        : pageProps.meta?.image?.permalink,
    type: pageProps.meta?.type ?? 'website',
    url: pageProps.meta?.url ?? process.env.NEXT_PUBLIC_APP_URL + router.asPath,
    color: pageProps.meta?.color ?? '#FE510B',
  }

  const structuredData = buildStructuredData(pageProps, meta, router)

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Head>
        <meta name="robots" content={meta.hidden ? 'noindex' : 'all'} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta id="msapplication-TileColor" name="msapplication-TileColor" content={meta.color} />
        <meta id="theme-color" name="theme-color" content={meta.color} />
        <link rel="canonical" href={meta.url} />
        <title>{stripHtmlTags(meta.title)}</title>
        <meta id="description" name="description" content={stripHtmlTags(meta.description)} />
        <meta id="og:type" property="og:type" content={meta.type} />
        <meta id="og:url" property="og:url" content={meta.url} />
        <meta id="og:title" name="og:title" content={stripHtmlTags(meta.title)} />
        <meta id="og:description" name="og:description" content={stripHtmlTags(meta.description)} />
        {meta.image && (
          <>
            <meta
              id="og:image"
              name="og:image"
              content={
                (typeof meta.image === 'string'
                  ? meta.image
                  : meta.image && 'permalink' in meta.image
                  ? meta.image.permalink
                  : undefined) ?? undefined
              }
            />
            <meta
              id="twitter:image"
              name="twitter:image"
              content={
                (typeof meta.image === 'string'
                  ? meta.image
                  : meta.image && 'permalink' in meta.image
                  ? meta.image.permalink
                  : undefined) ?? undefined
              }
            />
          </>
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        />
      </Head>

      <Hydrate state={pageProps.dehydratedState}>
        <Tooltip.Provider delayDuration={300}>
          <Toasts.Provider>
            <NextNProgress color="#1D4ED8" options={{ showSpinner: false }} />

            <Header />

            {pageProps.errorCode ? (
              <Error statusCode={pageProps.errorCode} title={pageProps.errorTitle} />
            ) : (
              <Component {...pageProps} />
            )}

            <Footer />

            <Toasts.Viewport />
          </Toasts.Provider>
        </Tooltip.Provider>
      </Hydrate>
    </QueryClientProvider>
  )
}

export default MyApp
