import React, { HTMLAttributes, HTMLProps, PropsWithChildren } from 'react'
import cx from 'classnames'
import { motion, useAnimation } from 'framer-motion'
import copy from 'clipboard-copy'
import { Maybe } from '@graphql/generated'
import { Except } from 'type-fest'

const ShareButton = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<
    {
      value?: Maybe<string>
      title?: Maybe<string>
      text?: Maybe<string>
    } & Except<HTMLProps<HTMLButtonElement>, 'onClick' | 'type' | 'value' | 'title'>
  >
>(({ value, title, text, children, disabled, className, ...props }, ref) => {
  const animationControls = useAnimation()
  const initialAnimationState = { x: '-50%', y: '-100%' }

  const onClick = async () => {
    if (!value) {
      return
    }

    if (typeof navigator.share === 'function') {
      try {
        navigator.share({
          url: value,
          title: title ?? undefined,
          text: text ?? undefined,
        })
      } catch (e: any) {
        if (!e.toString().includes('AbortError')) {
          throw e
        }
      }
    } else {
      await copy(value)
      await animationControls.start({
        x: '-50%',
        y: -25,
        opacity: [1, 0.75, 0],
        transition: { type: 'tween', duration: 1, times: [0, 0.75, 1] },
      })
      animationControls.set(initialAnimationState)
    }
  }

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled === undefined ? !value : disabled}
      onClick={onClick}
      className={cx('relative', className)}
      {...props}
    >
      {children}

      <motion.span
        initial={initialAnimationState}
        animate={animationControls}
        className="absolute bottom-0 left-1/2 w-[12ch] -translate-x-1/2 text-center text-xs text-blue-900 opacity-0"
      >
        Link kopiert
      </motion.span>
    </button>
  )
})

ShareButton.displayName = 'ShareButton'

export default ShareButton
