import { useEffect, useState } from 'react'

const useResizeObserver = (
  element: HTMLElement | null,
  callback: ResizeObserverCallback,
  options: ResizeObserverOptions = {}
) => {
  useEffect(() => {
    if (element) {
      const observer = new ResizeObserver(callback)

      observer.observe(element, options ?? {})

      return () => {
        observer.disconnect()
      }
    }
  }, [callback, element, options])
}

export default useResizeObserver
