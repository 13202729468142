import React, { useContext } from 'react'
import { Entry_Team_TeamMember, useGetTeamMembersQuery } from '@graphql/generated'
import { TeamFilterContext } from '@components/team/filters/Filter'
import { defaultGetTeamMembersVariables } from '@components/component-loader/components/Team'
import TeamMemberCard from '@components/team/TeamMemberCard'
import Loading from '@components/common/Loading'
import Reveal, { RevealChild } from '@components/common/Reveal'

const TeamMembersList: React.FC<{}> = (props) => {
  const { filter } = useContext(TeamFilterContext)

  const variables = { ...defaultGetTeamMembersVariables, filter }

  const teamMembersQuery = useGetTeamMembersQuery(variables, {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  if (teamMembersQuery.isLoading) {
    return (
      <div className="flex justify-center">
        <Loading />
      </div>
    )
  }

  // Map all loaded infinite query pages into one array
  const teamMembers = teamMembersQuery.data?.entries?.data

  if (!teamMembers?.length) {
    return (
      <p className="typo-300-regular flex justify-center gap-x-4 text-gray-500">
        <span className="mt-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-gray-500">
          <span aria-hidden="true" className="typo-100 text-white">
            i
          </span>
        </span>
        <span>Zu diesem Filter haben wir momentan keine Teammitglieder.</span>
      </p>
    )
  }

  return (
    <div className="grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-3 md:gap-y-11 md:gap-x-8 lg:grid-cols-4">
      {teamMembers?.map(
        (teamMember, i) =>
          !!teamMember?.id && (
            <Reveal transition={{ delay: 0.3 + 0.1 * (i % 4) }} key={teamMember.id}>
              <TeamMemberCard teamMember={teamMember as Entry_Team_TeamMember} />
            </Reveal>
          )
      )}
    </div>
  )
}

TeamMembersList.displayName = 'TeamMembersList'

TeamMembersList.defaultProps = {}

const MemoizedTeamMembersList = React.memo(TeamMembersList)

export default MemoizedTeamMembersList
