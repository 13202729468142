import React, { HTMLProps } from 'react'
import styles from './Bard.module.css'
import cx from 'classnames'
import WithNextLinks from '@components/common/WithNextLinks'
import { motion } from 'framer-motion'
import useBardMapping, { BardMap } from '@hooks/useBardMapping'

export type BardProps = HTMLProps<HTMLDivElement> & {
  html: string | null | undefined
  bardMap?: BardMap
}

const Bard = React.forwardRef<HTMLDivElement, BardProps>(
  ({ html, bardMap, className, ...props }, ref) => {
    html = useBardMapping(html, bardMap ?? {})

    return (
      <WithNextLinks>
        <div
          ref={ref}
          dangerouslySetInnerHTML={{ __html: html }}
          className={cx(styles.bard, className)}
          {...props}
        />
      </WithNextLinks>
    )
  }
)

Bard.displayName = 'Bard'

export const MotionBard = motion(Bard)

export default Bard
