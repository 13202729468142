import { IconProps } from '@additional'

const ShareIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
      <path
        fill="currentColor"
        d="M32 224L192 384 352 224V192H256V32H128V192H32v32zm0 192H0v64H32 352h32V416H352 32z"
      />
    </svg>
  )
}

ShareIcon.defaultProps = {
  'aria-hidden': true,
}

export default ShareIcon
