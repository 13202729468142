import { gqlFetcher, jsonFetcher, queryClient, ResponseError, WrappedData } from '@lib/fetcher'
import { IncomingMessage } from 'http'
import { UseMutationOptions, UseQueryOptions } from 'react-query'
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  Maybe,
  User,
} from '@graphql/generated'

export interface LoginData {
  email: string
  password: string
}

export async function fetchWithCsrfRetry<T = Response>(callback: () => Promise<T>): Promise<T> {
  return callback().catch((error) => {
    if (error instanceof ResponseError && error.status === 419) {
      return fetchCsrfToken().then(() => callback())
    }

    throw error
  })
}

export function withCsrfRetry<T = Response>(callback: () => Promise<T>): () => Promise<T> {
  return () => fetchWithCsrfRetry(callback)
}

export function buildCsrfTokenQueryFunction(req?: IncomingMessage): UseQueryOptions<null, Error> {
  return {
    queryKey: 'csrf-token',
    queryFn: jsonFetcher('/sanctum/csrf-cookie', null, { method: 'POST' }, req),
  }
}

export function fetchCsrfToken(req?: IncomingMessage): Promise<null> {
  return queryClient.fetchQuery(buildCsrfTokenQueryFunction(req))
}

export function buildLoginMutationFunction(
  req?: IncomingMessage
): UseMutationOptions<User, Error, LoginData> {
  return {
    mutationKey: 'login',
    mutationFn: (data) =>
      fetchWithCsrfRetry(
        jsonFetcher<WrappedData<User>>('/login', data, { method: 'POST' }, req)
      ).then((data) => data.data),
    onSuccess: (user) => {
      queryClient.setQueryData<User | null>(['GetCurrentUser'], (previousUser) => user)
    },
  }
}

export function buildLogoutMutationFunction(
  req?: IncomingMessage
): UseMutationOptions<null, Error> {
  return {
    mutationKey: 'logout',
    mutationFn: withCsrfRetry(jsonFetcher('/logout', null, { method: 'POST' }, req)),
    onSuccess: () => {
      queryClient.invalidateQueries(['GetCurrentUser'])
    },
  }
}

export function buildUserQueryFunction(
  req?: IncomingMessage
): UseQueryOptions<GetCurrentUserQuery | null, Error> {
  return {
    queryKey: ['GetCurrentUser'],
    queryFn: () =>
      fetchWithCsrfRetry(
        gqlFetcher<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
          GetCurrentUserDocument,
          {},
          {},
          req
        )
      ).catch((error) => {
        if (error instanceof ResponseError && error.status === 401) {
          return { user: null }
        }

        throw error
      }),
  }
}

export function fetchUser(req?: IncomingMessage): Promise<GetCurrentUserQuery['user'] | null> {
  return queryClient.fetchQuery(buildUserQueryFunction(req)).then((data) => data?.user ?? null)
}

export function hasPermission(user?: Maybe<Pick<User, 'permissions'>>, permission?: string) {
  return permission && user?.permissions?.includes(permission)
}
