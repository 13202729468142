import * as Collapsible from '@radix-ui/react-collapsible'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import Logo from '@components/icons/Logo'
import { buildLogoutMutationFunction, buildUserQueryFunction } from '@lib/user'
import { useRouter } from 'next/router'
import { useMutation, useQuery } from 'react-query'
import Container from '@components/common/Container'
import AppLink from '@components/common/AppLink'
import BurgerIcon from '@components/icons/BurgerIcon'
import { Routes } from '@lib/constants'
import { use100vh } from 'react-div-100vh'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'
import CrossIcon from '@components/icons/CrossIcon'
import {
  Maybe,
  NavPage_MainNavigation,
  PageInterface,
  useGetCurrentUserQuery,
  useGetGlobalsQuery,
} from '@graphql/generated'
import Button from '@components/common/Button'
import cx from 'classnames'
import ChevronIcon from '@components/icons/ChevronIcon'
import ProfileExplosionIcon from '@components/icons/ProfileExplosionIcon'
import ShoppingCartExplosionIcon from '@components/icons/ShoppingCartExplosionIcon'

const MobileNavigation: React.FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const router = useRouter()
  const height = use100vh()

  const globalsQuery = useGetGlobalsQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  const userQuery = useGetCurrentUserQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const [openCollapsibleId, setOpenCollapsibleId] = useState<string>()

  useEffect(() => {
    if (!isOpen) {
      setOpenCollapsibleId(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      onClose()
    }
  }, [router.asPath])

  return (
    <AnimatePresence>
      {isOpen && (
        <NavigationMenu.Root orientation="vertical" asChild>
          <motion.nav
            variants={{
              initial: {
                height: 0,
                paddingTop: 0,
              },
              animate: {
                height: height ?? '100vh',
                paddingTop: 'var(--logo-height)',
                transition: {
                  type: 'tween',
                  staggerChildren: 0.1,
                  when: 'beforeChildren',
                },
              },
              exit: {
                height: 0,
                paddingTop: 0,
                transition: {
                  type: 'tween',
                  when: 'afterChildren',
                },
              },
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed inset-x-0 overflow-auto bg-red-500 lg:hidden"
          >
            <NavigationMenu.List asChild>
              <ul className="flex flex-col items-start px-6 py-14">
                {globalsQuery.data?.main_navigation?.tree?.map((item) =>
                  !item?.page ? null : (
                    <NavigationMenu.Item key={item.page.id} asChild value={item.page.id}>
                      <motion.li
                        variants={{
                          initial: { opacity: 0, x: -20 },
                          animate: { opacity: 1, x: 0 },
                          exit: { opacity: 0, transition: { duration: 0.2 } },
                        }}
                        className={cx(
                          'w-full first:mt-0 last:mb-0',
                          (item.page as NavPage_MainNavigation).highlight ? 'my-6' : 'my-2.5'
                        )}
                      >
                        {Array.isArray(item.children) && item.children.length ? (
                          <Collapsible.Root
                            open={openCollapsibleId === item.page.id}
                            onOpenChange={(isOpen) =>
                              setOpenCollapsibleId(isOpen ? item.page?.id : undefined)
                            }
                            className="w-full"
                          >
                            <Collapsible.Trigger className="typo-1200 -mx-1 inline-flex items-center space-x-8 px-1 text-start text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring">
                              <span>{item.page.title}</span>
                              <ChevronIcon
                                className={cx('h-5 w-5 transition-transform duration-300', {
                                  'rotate-180': openCollapsibleId === item.page.id,
                                })}
                              />
                            </Collapsible.Trigger>

                            <AnimatePresence>
                              {openCollapsibleId === item.page.id && (
                                <Collapsible.Content forceMount>
                                  <motion.div
                                    variants={{
                                      initial: {
                                        height: 0,
                                        overflow: 'hidden',
                                      },
                                      animate: {
                                        height: 'var(--radix-collapsible-content-height)',
                                        overflow: 'visible',
                                        transition: {
                                          staggerChildren: 0.05,
                                        },
                                      },
                                    }}
                                    initial="initial"
                                    animate="animate"
                                    exit="initial"
                                  >
                                    <NavigationMenu.Sub>
                                      <NavigationMenu.List className="space-y-6 pt-8 pb-8">
                                        {item.children.map((child) =>
                                          !child?.page ? null : (
                                            <NavigationMenu.Item key={child.page.id} asChild>
                                              <motion.li
                                                variants={{
                                                  initial: {
                                                    opacity: 0,
                                                  },
                                                  animate: {
                                                    opacity: 1,
                                                  },
                                                }}
                                              >
                                                {(child.page as NavPage_MainNavigation)
                                                  .highlight ? (
                                                  <NavigationMenu.Link
                                                    asChild
                                                    active={router.asPath === child.page.url}
                                                  >
                                                    <Button
                                                      href={child.page.url ?? '#'}
                                                      level="secondary"
                                                      className="!text-4xl"
                                                      onClick={() => onClose()}
                                                    >
                                                      {child.page.title}
                                                    </Button>
                                                  </NavigationMenu.Link>
                                                ) : (
                                                  <NavigationMenu.Link
                                                    asChild
                                                    active={router.asPath === child.page.url}
                                                  >
                                                    <AppLink
                                                      href={child.page.url ?? '#'}
                                                      className="typo-700 -mx-1 px-1 text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring"
                                                      onClick={() => onClose()}
                                                    >
                                                      {child.page.title}
                                                    </AppLink>
                                                  </NavigationMenu.Link>
                                                )}
                                              </motion.li>
                                            </NavigationMenu.Item>
                                          )
                                        )}
                                      </NavigationMenu.List>
                                    </NavigationMenu.Sub>
                                  </motion.div>
                                </Collapsible.Content>
                              )}
                            </AnimatePresence>
                          </Collapsible.Root>
                        ) : (item.page as NavPage_MainNavigation).highlight ? (
                          <NavigationMenu.Link asChild active={router.asPath === item.page.url}>
                            <Button
                              href={item.page.url ?? '#'}
                              level="secondary"
                              className="!text-4xl"
                              onClick={() => onClose()}
                            >
                              {item.page.title}
                            </Button>
                          </NavigationMenu.Link>
                        ) : (
                          <NavigationMenu.Link asChild active={router.asPath === item.page.url}>
                            <AppLink
                              href={item.page.url ?? '#'}
                              className="typo-1200 -mx-1 px-1 text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring"
                              onClick={() => onClose()}
                            >
                              {item.page.title}
                            </AppLink>
                          </NavigationMenu.Link>
                        )}
                      </motion.li>
                    </NavigationMenu.Item>
                  )
                )}

                <NavigationMenu.Item asChild value="profile">
                  <motion.li
                    key="profile"
                    variants={{
                      initial: { opacity: 0, x: -20 },
                      animate: { opacity: 1, x: 0 },
                      exit: { opacity: 0, transition: { duration: 0.2 } },
                    }}
                    className="mt-12"
                  >
                    <NavigationMenu.Link
                      asChild
                      active={router.asPath === (userQuery.data?.user ? '/profil' : '/login')}
                    >
                      <AppLink
                        href={userQuery.data?.user ? '/profil' : '/login'}
                        className="typo-700 group -mx-1 inline-flex items-center px-1 text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring"
                      >
                        <ProfileExplosionIcon className="mr-3 -mt-3 h-8 w-8" />
                        {userQuery.data?.user ? 'Profil' : 'Login'}
                      </AppLink>
                    </NavigationMenu.Link>
                  </motion.li>
                </NavigationMenu.Item>

                <NavigationMenu.Item asChild value="shop">
                  <motion.li
                    key="shop"
                    variants={{
                      initial: { opacity: 0, x: -20 },
                      animate: { opacity: 1, x: 0 },
                      exit: { opacity: 0, transition: { duration: 0.2 } },
                    }}
                    className="mt-6"
                  >
                    <NavigationMenu.Link asChild active={false}>
                      <AppLink
                        href="https://petarde.myshopify.com/"
                        className="typo-700 group -mx-1 inline-flex items-center px-1 text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring"
                      >
                        <ShoppingCartExplosionIcon className="mr-3 -mt-2.5 h-8 w-8" />
                        Shop
                      </AppLink>
                    </NavigationMenu.Link>
                  </motion.li>
                </NavigationMenu.Item>
              </ul>
            </NavigationMenu.List>
          </motion.nav>
        </NavigationMenu.Root>
      )}
    </AnimatePresence>
  )
}

const MobileNavigationBar: React.FC<{
  isOpen: boolean
  onBurgerClick: (isOpen: boolean) => void
}> = ({ isOpen, onBurgerClick }) => {
  const router = useRouter()

  const userQuery = useGetCurrentUserQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return (
    <NavigationMenu.Root orientation="horizontal" asChild>
      <motion.nav
        initial="hidden"
        animate="show"
        transition={{
          staggerChildren: 0.1,
          staggerDirection: -1,
          delayChildren: 0.2,
        }}
        className="flex h-full w-full justify-end lg:hidden"
      >
        <NavigationMenu.List className="flex h-full w-full items-center justify-end space-x-6">
          <NavigationMenu.Item asChild value="profile">
            <motion.li
              variants={{
                hidden: { opacity: 0, y: -20 },
                show: { opacity: 1, y: 0 },
              }}
            >
              <NavigationMenu.Link
                asChild
                active={router.asPath === (userQuery.data?.user ? '/profil' : '/login')}
              >
                <AppLink
                  href={userQuery.data?.user ? '/profil' : '/login'}
                  className="group text-white outline-none transition hover:text-white/90 focus:text-white/90"
                >
                  <span className="sr-only">{userQuery.data?.user ? 'Profil' : 'Login'}</span>
                  <ProfileExplosionIcon className="-mt-3 h-8 w-8" />
                </AppLink>
              </NavigationMenu.Link>
            </motion.li>
          </NavigationMenu.Item>

          <NavigationMenu.Item asChild value="shop">
            <motion.li
              variants={{
                hidden: { opacity: 0, y: -20 },
                show: { opacity: 1, y: 0 },
              }}
            >
              <NavigationMenu.Link asChild active={false}>
                <AppLink
                  href="https://petarde.myshopify.com/"
                  className="group text-white outline-none transition hover:text-white/90 focus:text-white/90"
                >
                  <span className="sr-only">Shop</span>
                  <ShoppingCartExplosionIcon className="-mt-3 h-8 w-8" />
                </AppLink>
              </NavigationMenu.Link>
            </motion.li>
          </NavigationMenu.Item>

          <NavigationMenu.Item className="flex items-center" value="menu">
            <motion.button
              variants={{
                hidden: { opacity: 0, y: -20 },
                show: { opacity: 1, y: 0 },
              }}
              exit={{ opacity: 0 }}
              onClick={() => onBurgerClick(!isOpen)}
              className="-m-1 p-1 text-white outline-none focus-visible:ring"
            >
              <AnimatePresence exitBeforeEnter>
                {isOpen ? (
                  <motion.span
                    key="cross"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <span className="sr-only">Navigation schliessen</span>
                    <CrossIcon className="h-5 w-5" />
                  </motion.span>
                ) : (
                  <motion.span
                    key="burger"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <span className="sr-only">Navigation öffnen</span>
                    <BurgerIcon className="h-5 w-5" />
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.button>
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </motion.nav>
    </NavigationMenu.Root>
  )
}

const DesktopNavigationItem: React.FC<{
  activeItemId?: string
  item: {
    children?: Array<Maybe<{ page?: Maybe<PageInterface & NavPage_MainNavigation> }>> | null
    page?: Maybe<PageInterface & NavPage_MainNavigation>
  }
}> = ({ activeItemId, item }) => {
  const router = useRouter()

  if (!item?.page) {
    return null
  }

  return (
    <NavigationMenu.Item asChild value={item.page.id}>
      <motion.li
        variants={{
          hidden: { opacity: 0, y: -20 },
          show: { opacity: 1, y: 0 },
        }}
        className="relative ml-8 hidden h-full items-center first:ml-0 lg:flex"
      >
        {Array.isArray(item.children) && !!item.children.length && (
          <>
            <NavigationMenu.Trigger asChild>
              <AppLink className="typo-600 -mx-1 px-1 text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring">
                {item.page.title}
              </AppLink>
            </NavigationMenu.Trigger>

            <AnimatePresence>
              {activeItemId === item.page.id && (
                <NavigationMenu.Content forceMount className="absolute top-13 -left-5 pt-7">
                  <NavigationMenu.Sub>
                    <NavigationMenu.List
                      asChild
                      className="flex min-w-[10rem] flex-col items-stretch bg-white shadow-lg"
                    >
                      <motion.ul
                        variants={{
                          hidden: {
                            opacity: 0,
                            scale: 0.95,
                          },
                          show: {
                            opacity: 1,
                            scale: 1,
                          },
                        }}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        transition={{
                          type: 'tween',
                          staggerChildren: 0.1,
                        }}
                        className="origin-top"
                      >
                        {item.children.map((child) =>
                          !child?.page ? null : (
                            <NavigationMenu.Item key={child.page.id} asChild>
                              <motion.li
                                variants={{
                                  hidden: {
                                    opacity: 0,
                                  },
                                  show: {
                                    opacity: 1,
                                  },
                                }}
                                className="w-full first:mt-2 last:mb-3"
                              >
                                <NavigationMenu.Link
                                  asChild
                                  active={router.asPath === child.page.url}
                                >
                                  <AppLink
                                    href={child.page.url ?? '#'}
                                    className="typo-500 block py-3 px-5 text-red-500 outline-none transition hover:text-red-600 focus:text-red-600 focus-visible:ring"
                                  >
                                    {child.page.title}
                                  </AppLink>
                                </NavigationMenu.Link>
                              </motion.li>
                            </NavigationMenu.Item>
                          )
                        )}
                      </motion.ul>
                    </NavigationMenu.List>
                  </NavigationMenu.Sub>
                </NavigationMenu.Content>
              )}
            </AnimatePresence>
          </>
        )}

        {(Array.isArray(item.children) && !!item.children.length) || (
          <NavigationMenu.Link asChild active={router.asPath === item.page.url}>
            {item.page.highlight ? (
              <Button href={item.page.url ?? '#'} level="secondary" size="small">
                {item.page.title}
              </Button>
            ) : (
              <AppLink
                href={item.page.url ?? '#'}
                className="typo-600 -mx-1 px-1 text-white outline-none transition hover:text-white/90 focus:text-white/90 focus-visible:ring"
              >
                {item.page.title}
              </AppLink>
            )}
          </NavigationMenu.Link>
        )}
      </motion.li>
    </NavigationMenu.Item>
  )
}

const DesktopNavigationBar: React.FC<{}> = (props) => {
  const router = useRouter()

  const animationControls = useAnimation()

  const globalsQuery = useGetGlobalsQuery(undefined, {
    onSettled: () => {
      animationControls.start('show')
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
  const userQuery = useGetCurrentUserQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    // Make sure to show the navigation if the globals have been fetched server side
    if (globalsQuery.isFetched) {
      animationControls.start('show')
    }
  }, [globalsQuery, animationControls])

  const navItems = globalsQuery.data?.main_navigation?.tree ?? []
  const leftNavItems = [...navItems]
  // Only the last nav item (if highlighted) should be displayed to the right of the icons.
  const rightNavItems = (
    leftNavItems[leftNavItems.length - 1]?.page as Maybe<NavPage_MainNavigation>
  )?.highlight
    ? leftNavItems.splice(-1)
    : []

  const [activeItemId, setActiveItemId] = useState<string>()

  return (
    <NavigationMenu.Root
      orientation="horizontal"
      className="hidden h-full w-full lg:block"
      value={activeItemId}
      onValueChange={(value) => setActiveItemId(value)}
    >
      <motion.div
        initial="hidden"
        animate={animationControls}
        transition={{
          staggerChildren: 0.1,
          staggerDirection: -1,
          delayChildren: 0.2,
        }}
        className="flex h-full w-full justify-end"
      >
        <NavigationMenu.List className="flex h-full w-full items-center justify-end">
          {leftNavItems.map((item, index) =>
            !item?.page ? null : (
              <DesktopNavigationItem key={item.page.id} activeItemId={activeItemId} item={item} />
            )
          )}

          <NavigationMenu.Item asChild value="profile">
            <motion.li
              variants={{
                hidden: { opacity: 0, y: -20 },
                show: { opacity: 1, y: 0 },
              }}
              className="ml-8"
            >
              <NavigationMenu.Link
                asChild
                active={router.asPath === (userQuery.data?.user ? '/profil' : '/login')}
              >
                <AppLink
                  href={userQuery.data?.user ? '/profil' : '/login'}
                  className="group text-white outline-none transition hover:text-white/90 focus:text-white/90"
                >
                  <span className="sr-only">{userQuery.data?.user ? 'Profil' : 'Login'}</span>
                  <ProfileExplosionIcon className="-mt-3 h-8 w-8" />
                </AppLink>
              </NavigationMenu.Link>
            </motion.li>
          </NavigationMenu.Item>

          <NavigationMenu.Item asChild value="shop">
            <motion.li
              variants={{
                hidden: { opacity: 0, y: -20 },
                show: { opacity: 1, y: 0 },
              }}
              className="ml-6"
            >
              <NavigationMenu.Link asChild active={false}>
                <AppLink
                  href="https://petarde.myshopify.com/"
                  className="group text-white outline-none transition hover:text-white/90 focus:text-white/90"
                >
                  <span className="sr-only">Shop</span>
                  <ShoppingCartExplosionIcon className="-mt-3 h-8 w-8" />
                </AppLink>
              </NavigationMenu.Link>
            </motion.li>
          </NavigationMenu.Item>

          {rightNavItems.map((item) =>
            !item?.page ? null : (
              <DesktopNavigationItem key={item.page.id} activeItemId={activeItemId} item={item} />
            )
          )}
        </NavigationMenu.List>
      </motion.div>
    </NavigationMenu.Root>
  )
}

const Header: React.FC = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const [zIndexElevated, setZIndexElevated] = useState(false)

  useEffect(() => {
    if (mobileNavOpen) {
      setZIndexElevated(true)
    } else {
      setTimeout(() => {
        setZIndexElevated(false)
      }, 300)
    }
  }, [mobileNavOpen])

  return (
    <header
      className={cx(
        'fixed inset-x-0 top-0 isolate isolate flex w-full items-start',
        zIndexElevated ? 'z-top' : 'z-20'
      )}
    >
      <AppLink
        href={Routes.Home}
        className="relative z-10 -mt-px flex h-17 w-32 flex-shrink-0 items-center justify-center outline-none after:absolute after:inset-0 after:-z-1 after:-translate-x-[0.5rem] after:-skew-x-13 after:bg-red-500 focus-visible:ring lg:h-28 lg:w-52 lg:after:-translate-x-[0.8125rem]"
      >
        <span className="sr-only">Zur Startseite</span>
        <Logo className="-ml-2 w-20 text-white lg:-mt-2 lg:-ml-3 lg:w-32" />
      </AppLink>
      <nav className="relative isolate z-10 flex h-14 flex-grow items-center justify-end gap-4 before:absolute before:inset-0 before:-right-8 before:-skew-x-13 before:bg-red-500 lg:h-20">
        <Container fluid className="z-10 h-full">
          <DesktopNavigationBar />
          <MobileNavigationBar
            isOpen={mobileNavOpen}
            onBurgerClick={(isOpen) => setMobileNavOpen(isOpen)}
          />
        </Container>
      </nav>

      <MobileNavigation isOpen={mobileNavOpen} onClose={() => setMobileNavOpen(false)} />
    </header>
  )
}

export default Header
