import AppLink, { AppLinkProps } from '@components/common/AppLink'
import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

const Link = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  PropsWithChildren<AppLinkProps>
>(({ children, className, ...props }, ref) => {
  return (
    <AppLink
      ref={ref}
      {...props}
      className={cx(
        'font-semibold outline-none ring-offset-2 transition hover:text-red-500 focus-visible:ring',
        className
      )}
    >
      {children}
    </AppLink>
  )
})

Link.displayName = 'Link'

export default Link
