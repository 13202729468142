import { IconProps } from '@additional'

const FlatExplosionIllustration: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 258.583 191.032" {...props}>
      <path
        d="m169.684 152.759-14.26-3.15-42.007-9.33-28.64-6.343L0 121.53 82.339 100 43.817 12.621l53.937 51.293 17.034-56.061 13.332 54.476L185.757 0l-20.82 65.015 34.045-13.495-18.672 29.266 78.273-12.75-72.588 45.026 67.485 13.215-57.852 11.952 62.944 35.242-5.469-2.19-59.945-13.3-3.354-.744 33.559 33.8ZM30.626 62.124l33.643 12.765 3.074 7.166ZM231.393 49.93l-6.8 11.283-15.62 2.675Z"
        fill="currentColor"
      />
    </svg>
  )
}

FlatExplosionIllustration.defaultProps = {
  'aria-hidden': true,
}

export default FlatExplosionIllustration
