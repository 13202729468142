import { IconProps } from '@additional'

const CrossIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="m2.47 19.702 7.841-7.84-7.575-7.563L4.561 2.47l7.576 7.565L19.7 2.474l1.829 1.828-7.561 7.559 7.543 7.532-1.826 1.829-7.544-7.534-7.844 7.842Z"
        fill="currentColor"
      />
    </svg>
  )
}

CrossIcon.defaultProps = {
  'aria-hidden': true,
}

export default CrossIcon
