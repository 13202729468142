import { getCookie } from 'cookies-next'
import { queryClient } from '@lib/fetcher'

function log(...message: any[]) {
  console.log(
    '%cLive Preview',
    'color: white; background-color: #ff269e; padding: 2px 4px; border-radius: 2px;',
    ...message
  )
}

const usePreviewFromCookie = (cookieKey: string = 'preview_token') => {
  if (process.browser) {
    const previewToken = getCookie(cookieKey)

    if (previewToken) {
      queryClient.setQueryData('preview-token', previewToken)

      log('Enabled with token:', previewToken)

      // Add live preview update event handler
      window.onmessage = ({ data }) => {
        if (!data) return

        if (
          data === 'live-preview-update' ||
          (typeof data === 'object' && data.name === 'statamic.preview.updated')
        ) {
          log('Refreshing page...')
          queryClient.refetchQueries()
        }
      }

      return
    }

    queryClient.setQueryData('preview-token', null)
  }
}

export default usePreviewFromCookie
