import {
  AssetInterface,
  BardText,
  Maybe,
  Scalars,
  Set_TextComponents_Button,
  Sets_AsideComponents,
  Sets_Components,
  Sets_HeroText,
  Sets_InsertComponents,
  Sets_TeamMemberComponents,
} from '@graphql/generated'
import React from 'react'
import BannerWithImage from '@components/component-loader/components/BannerWithImage'
import Banner from '@components/component-loader/components/Banner'
import Newsletter from '@components/component-loader/components/Newsletter'
import AsideTeaser from '@components/component-loader/components/AsideTeaser'
import Post from '@components/component-loader/components/Post'
import TitleWithText from '@components/component-loader/components/TitleWithText'
import BardTextComponent from '@components/component-loader/components/BardText'
import StatamicImage from '@components/common/StatamicImage'
import ImageWithText from '@components/component-loader/components/ImageWithText'
import ImageGallery from '@components/component-loader/components/ImageGallery'
import Button, { ButtonProps } from '@components/common/Button'
import ThreeIconCards from '@components/component-loader/components/ThreeIconCards'
import Teaser from '@components/component-loader/components/Teaser'
import Events from '@components/component-loader/components/Events'
import Team from '@components/component-loader/components/Team'
import TeamMemberPosts from '@components/component-loader/components/TeamMemberPosts'

const ComponentMap: {
  [k: string]: React.FC<any>
} = {
  'components-title_with_text': TitleWithText,
  'components-image_with_text': ImageWithText,
  'components-teaser': Teaser,
  'components-image_gallery': ImageGallery,
  'components-three_icon_cards': ThreeIconCards,
  'components-events': Events,
  'components-team': Team,

  'team-member-components-posts': TeamMemberPosts,
  'team-member-components-title_with_text': TitleWithText,
  'team-member-components-image_with_text': ImageWithText,
  'team-member-components-teaser': Teaser,
  'team-member-components-image_gallery': ImageGallery,

  'insert-components-banner_with_image': BannerWithImage,
  'insert-components-banner': Banner,
  'insert-components-newsletter': Newsletter,

  'aside-components-banner': (props: any) => <Banner aside {...props} />,
  'aside-components-post': Post,
  'aside-components-newsletter': (props: any) => <Newsletter aside {...props} />,
  'aside-components-aside_teaser': AsideTeaser,

  'hero-components-newsletter': (props: any) => <Newsletter aside inline {...props} />,
  'hero-components-text': BardTextComponent,

  'bard-text': BardTextComponent,
  'bard-button': (props: {
    link?: Maybe<Scalars['String']>
    text?: Maybe<Scalars['String']>
    level?: Maybe<{ value?: Maybe<ButtonProps['level']> }>
  }) =>
    (!!props.link && (
      <div className="my-6 sm:my-10">
        <Button href={props.link} level={props.level?.value ?? 'primary'}>
          {props.text}
        </Button>
      </div>
    )) ||
    null,
  'bard-image': (props: { image?: Maybe<AssetInterface> }) =>
    (props.image && (
      <StatamicImage loading="lazy" asset={props.image} className="my-4 sm:my-6" />
    )) ??
    null,
}

type ComponentLoaderProps =
  | ({
      group: 'components'
    } & (
      | {
          component?: Maybe<Sets_Components>
        }
      | {
          components?: Array<Maybe<Sets_Components>>
        }
    ))
  | ({
      group: 'team-member-components'
    } & (
      | {
          component?: Maybe<Sets_TeamMemberComponents>
        }
      | {
          components?: Array<Maybe<Sets_TeamMemberComponents>>
        }
    ))
  | ({
      group: 'insert-components'
    } & (
      | {
          component?: Maybe<Sets_InsertComponents>
        }
      | {
          components?: Array<Maybe<Sets_InsertComponents>>
        }
    ))
  | ({
      group: 'aside-components'
    } & (
      | {
          component?: Maybe<Sets_AsideComponents>
        }
      | {
          components?: Array<Maybe<Sets_AsideComponents>>
        }
    ))
  | ({
      group: 'hero-components'
    } & (
      | {
          component?: Maybe<Sets_HeroText>
        }
      | {
          components?: Array<Maybe<Sets_HeroText>>
        }
    ))
  | ({
      group: 'bard'
    } & (
      | {
          component?: Maybe<
            | BardText
            | {
                image?: Maybe<AssetInterface>
                type: string
              }
          >
        }
      | {
          components?: Array<
            Maybe<
              | BardText
              | {
                  image?: Maybe<AssetInterface>
                  type: string
                }
            >
          >
        }
    ))

const ComponentLoader: React.FC<ComponentLoaderProps> = (props) => {
  const components =
    'component' in props
      ? props.component
        ? [props.component]
        : []
      : 'components' in props
      ? props.components ?? []
      : []

  return (
    <>
      {components.map((component, i) => {
        if (!component) {
          return null
        }

        const key = `${props.group}-${i}-${component.type}`

        let Comp = ComponentMap[`${props.group}-${component.type}`] as
          | React.FC<any>
          | null
          | undefined

        if (Comp) {
          return <Comp key={key} {...(component as any)} />
        }

        if (process.env.NODE_ENV === 'development') {
          return component.type
        }
      })}
    </>
  )
}

ComponentLoader.defaultProps = {
  group: 'components',
  components: [],
}

export default ComponentLoader
