import { default as NextImage, ImageProps as NextImageProps } from 'next/image'
import { Asset_Assets } from '@graphql/generated'
import { encodePath, joinURL } from 'ufo'
import throttle from 'lodash/throttle'
import React, { HTMLProps, MutableRefObject, Ref, useEffect, useRef, useState } from 'react'

const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/petarde/image/upload'
const CLOUDINARY_PLACEHOLDER_TRANSFORMATION = ''
const SCREEN_SIZES: { [key: string]: `${number}px` } = {
  '4xs': '50px',
  '3xs': '100px',
  '2xs': '200px',
  xs: '450px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
}

export type CloudinaryImageProps = {
  src: string
  width?: number
  height?: number
  quality?: number | string
  blur?: number
  crop?: string
  format?: string
  aspectRatio?: number
  focal?: string | [number, number]
  zoom?: string | number
  sizes?: string
  transforms?: string | { [key: string]: string }
  fallbackWidth?: number
  placeholderQuality?: number
  placeholderWidth?: number
  usePlaceholder?: boolean
  placeholderDataUrl?: string
} & Partial<
  Omit<HTMLProps<HTMLImageElement>, 'src' | 'width' | 'height' | 'aspectRatio' | 'placeholder'>
>

function getScreens(): Array<{
  breakpoint: string
  size: `${number}px`
  media: `min-width: ${number}px`
}> {
  return Object.entries(SCREEN_SIZES)
    .map(([key, value]) => ({
      breakpoint: key,
      media: `min-width: ${value}` as `min-width: ${number}px`,
      size: value,
    }))
    .sort((a, b) => +b.size.replace('px', '') - +a.size.replace('px', ''))
}

function getBreakpointSizes(): number[] {
  return getScreens().map((screen) => parseInt(screen.size))
}
function getLargestBreakpointSize() {
  return getBreakpointSizes()[0]
}

type GenerateSrcProps = { placeholder?: boolean } & Pick<
  CloudinaryImageProps,
  | 'src'
  | 'quality'
  | 'width'
  | 'aspectRatio'
  | 'blur'
  | 'crop'
  | 'format'
  | 'focal'
  | 'zoom'
  | 'transforms'
>

export function generateSrc({
  src,
  quality,
  width,
  aspectRatio,
  blur,
  crop,
  format,
  focal,
  zoom,
  placeholder,
  transforms: additionalTransforms,
}: GenerateSrcProps): string {
  if (!getFileTypeSupported({ src })) {
    return joinURL(CLOUDINARY_BASE_URL, encodePath(src))
  }

  const transformations: string[] = []

  if (placeholder) {
    if (CLOUDINARY_PLACEHOLDER_TRANSFORMATION) {
      transformations.push(`t_${CLOUDINARY_PLACEHOLDER_TRANSFORMATION}`)
    } else {
      transformations.push('e_blur:2000,f_auto,q_auto:eco,w_300,z_1.1')
    }
    if (aspectRatio) transformations.push(`ar_${aspectRatio}`)
  } else {
    if (width) transformations.push(`w_${width}`)
    if (quality) transformations.push(`q_${quality}`)
    if (blur) transformations.push(`e_blur:${blur}`)
    if (format) transformations.push(`f_${format}`)
    if (zoom) transformations.push(`z_${zoom}`)
    if (width && aspectRatio) {
      transformations.push(`h_${Math.round(width / aspectRatio)}`)
    } else if (!width && aspectRatio) {
      transformations.push(`ar_${aspectRatio}`)
    }
  }

  if (crop) transformations.push(`c_${crop}`)

  if (focal && crop && ['crop', 'fill', 'lfill', 'lpad', 'mpad', 'pad', 'thumb'].includes(crop)) {
    if (Array.isArray(focal)) {
      transformations.push(`x_${focal[0]},y_${focal[1]},g_xy_center`)
    } else {
      transformations.push(`g_${focal}`)
    }
  }

  let transformationsString = transformations.join(',')

  if (additionalTransforms) {
    if (typeof additionalTransforms === 'object') {
      transformationsString +=
        '/' +
        Object.entries(additionalTransforms)
          .map(([key, value]) => {
            transformations.push(`${key}_${value}`)
          })
          .join(',')
    } else {
      transformationsString += '/' + additionalTransforms
    }
  }

  const remoteFolderMapping = CLOUDINARY_BASE_URL.match(/\/image\/upload\/(.*)/)

  // Handle delivery remote media file URLs
  // Note: Non-remote images will pass into this function if the baseURL is not using a sub directory
  if (remoteFolderMapping?.length && remoteFolderMapping?.length >= 1) {
    // need to do some weird logic to get the remote folder after image/upload after the operations and before the src
    const remoteFolder = remoteFolderMapping[1]
    const baseURLWithoutRemoteFolder = CLOUDINARY_BASE_URL.replace(remoteFolder, '')
    return joinURL(baseURLWithoutRemoteFolder, transformationsString, remoteFolder, encodePath(src))
  }

  return joinURL(CLOUDINARY_BASE_URL, transformationsString, encodePath(src))
}

function getFileTypeSupported({ src }: Pick<CloudinaryImageProps, 'src'>): boolean {
  return ['jpg', 'jpeg', 'png', 'webp', 'avif', 'gif'].includes(
    (src.match(/(?:\.([^.]+))?$/) ?? [])[1]?.toLowerCase()
  )
}

function getOriginalUrl(props: CloudinaryImageProps): string {
  return generateSrc(props)
}

function getPlaceholderUrl(props: CloudinaryImageProps): string {
  if (props.placeholderDataUrl && !props.aspectRatio) {
    return props.placeholderDataUrl
  }

  return generateSrc({
    placeholder: true,
    ...props,
  })
}

function getImageSizes(
  {
    sizes,
    width,
    height,
    aspectRatio,
  }: Pick<CloudinaryImageProps, 'sizes' | 'width' | 'height' | 'aspectRatio'>,
  imageRef: MutableRefObject<HTMLImageElement | null>
): string {
  if (sizes) {
    return sizes
  }

  if (imageRef.current) {
    const imageAspectRatio = getImageAspectRatio({ width, height, aspectRatio })
    const containerWidth = imageRef.current.getBoundingClientRect().width
    const isCover = getComputedStyle(imageRef.current).objectFit === 'cover'

    if (isCover && imageAspectRatio) {
      const containerHeight = imageRef.current.getBoundingClientRect().height
      const containerAspectRatio = containerWidth / containerHeight

      if (imageAspectRatio > containerAspectRatio) {
        const size = Math.ceil((Math.round(containerHeight) / Math.round(window.innerHeight)) * 100)
        return `${size}vh`
      } else {
        const size = Math.ceil((Math.round(containerWidth) / Math.round(window.innerWidth)) * 100)
        return `${size}vw`
      }
    } else {
      const size = Math.ceil((Math.round(containerWidth) / Math.round(window.innerWidth)) * 100)
      return `${size}vw`
    }
  }

  return '1px'
}

function getImageSrcSet(props: CloudinaryImageProps): string {
  const srcSet = getBreakpointSizes().map(
    (breakpointSize) =>
      generateSrc({
        ...props,
        width: breakpointSize,
      }) + ` ${breakpointSize}w`
  )

  if (props.usePlaceholder) {
    srcSet.push(getPlaceholderUrl(props) + ' 32w')
  }

  return srcSet.join(',')
}

function getImageWidth({
  width,
  height,
  aspectRatio,
}: Pick<CloudinaryImageProps, 'width' | 'height' | 'aspectRatio'>): number {
  if ((width && height) || (width && aspectRatio)) {
    return width
  }

  if (aspectRatio && height && Number(height) > 0) {
    return height * aspectRatio
  }

  return getLargestBreakpointSize()
}

function getImageHeight({
  width,
  height,
  aspectRatio,
}: Pick<CloudinaryImageProps, 'width' | 'height' | 'aspectRatio'>): number | undefined {
  if ((width && height) || (height && aspectRatio)) {
    return height
  }

  if (aspectRatio && width && Number(width) > 0) {
    return width / aspectRatio
  }

  if (aspectRatio) {
    return getLargestBreakpointSize() / aspectRatio
  }

  return undefined
}

function getImageAspectRatio({
  width,
  height,
  aspectRatio,
}: Pick<CloudinaryImageProps, 'width' | 'height' | 'aspectRatio'>): number | undefined {
  if (aspectRatio) {
    return aspectRatio
  }

  const imageWidth = getImageWidth({ width, height, aspectRatio })
  const imageHeight = getImageHeight({ width, height, aspectRatio })
  if (imageWidth && imageHeight) {
    return imageWidth / imageHeight
  }

  return undefined
}

const CloudinaryImage = React.forwardRef<HTMLImageElement, CloudinaryImageProps>((props, ref) => {
  const {
    src,
    width,
    height,
    quality,
    blur,
    crop,
    format,
    aspectRatio,
    focal,
    zoom,
    sizes,
    transforms,
    fallbackWidth,
    placeholderQuality,
    placeholderWidth,
    usePlaceholder,
    placeholderDataUrl,
    alt,
    ...leftOverProps
  } = props

  const fileTypeSupported = getFileTypeSupported(props)

  const imageRef = useRef<HTMLImageElement>(null)
  React.useImperativeHandle<HTMLImageElement | null, HTMLImageElement | null>(
    ref,
    () => imageRef.current
  )

  const originalUrl = getOriginalUrl(props)
  const imageWidth = getImageWidth(props)
  const imageHeight = getImageHeight(props)

  let [imageSizes, setImageSizes] = useState(getImageSizes(props, imageRef))
  const imageSrcSet = getImageSrcSet(props)

  const onResize = throttle(() => {
    setImageSizes(getImageSizes(props, imageRef))
  }, 100)

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize, { passive: true })

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  if (fileTypeSupported) {
    return (
      // @ts-ignore
      <img
        ref={imageRef}
        src={originalUrl}
        srcSet={imageSrcSet}
        alt={alt}
        sizes={imageSizes}
        width={imageWidth}
        height={imageHeight}
        {...leftOverProps}
      />
    )
  }

  return (
    // @ts-ignore
    <img
      ref={imageRef}
      src={originalUrl}
      alt={alt}
      width={imageWidth}
      height={imageHeight}
      {...leftOverProps}
    />
  )
})

CloudinaryImage.displayName = 'CloudinaryImage'

CloudinaryImage.defaultProps = {
  quality: 'auto',
  crop: 'lfill',
  format: 'auto',
  focal: 'auto',
  fallbackWidth: 2000,
  placeholderQuality: 30,
  placeholderWidth: 300,
  usePlaceholder: true,
}

export default CloudinaryImage
