import { useRouter } from 'next/router'

export type QueryLinkBuilder = (
  key: string,
  value?: string | string[],
  removeExistingQuery?: boolean
) => string

const useQueryLinkBuilder = (): QueryLinkBuilder => {
  const router = useRouter()

  return (key: string, value?: string | string[], removeExistingQuery: boolean = false) => {
    const query = new URLSearchParams()

    if (!removeExistingQuery) {
      Object.entries(router.query).forEach(([key, value]) => {
        if (['uri'].includes(key)) {
          return
        }

        query.set(key, typeof value === 'string' ? value : JSON.stringify(value))
      })
    }

    if (value) {
      query.set(key, typeof value === 'string' ? value : JSON.stringify(value))
    } else {
      query.delete(key)
    }

    const [path] = router.asPath.split('?')

    if (query.toString()) {
      return `${path}?${query.toString()}`
    }

    return path
  }
}

export default useQueryLinkBuilder
