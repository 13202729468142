import { IconProps } from '@additional'

const FlatExplosionCutIllustration: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 787.652 408.325" {...props}>
      <path
        d="m259.826 408.324-1.593-.353L0 370.184l250.809-65.586L133.471 38.444l164.293 156.243 51.883-170.763L390.26 189.86 565.826 0l-63.417 198.039 103.7-41.107-56.883 89.146 238.422-38.839-221.1 137.152 205.563 40.252-114.63 23.681Z"
        fill="currentColor"
      />
    </svg>
  )
}

FlatExplosionCutIllustration.defaultProps = {
  'aria-hidden': true,
}

export default FlatExplosionCutIllustration
