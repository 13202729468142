import FormField from '@components/forms/fields/FormField'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import yupLocaleDe from '@lang/de/yup'
import cx from 'classnames'
import Input from '@components/forms/fields/Input'
import Button from '@components/common/Button'
import { useSubscribeToNewsletterMutation } from '@graphql/generated'
import { UnprocessableEntity } from '@lib/fetcher'
import Bard from '@components/common/Bard/Bard'
import * as Fathom from 'fathom-client'

Yup.setLocale(yupLocaleDe)

type FormValues = {
  name: string
  email: string
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('Name'),
  email: Yup.string().required().email().label('E-Mail'),
})

const SmallNewsletterForm: React.FC<{
  title?: string
  button_text?: string
  thank_you_text?: string
}> = (props) => {
  const subscribeMutation = useSubscribeToNewsletterMutation()

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    subscribeMutation.mutate(values, {
      onError: (error) => {
        if (error instanceof UnprocessableEntity) {
          actions.setErrors(error.getSingleValidationErrors())
        }
      },
      onSuccess: () => {
        Fathom.trackGoal('XPBQYFTB', 0)
      },
    })
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ name: '', email: '' }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isValid, values }) => (
        <form onSubmit={handleSubmit} className="space-y-7">
          <p className="typo-600">{props.title}</p>

          {subscribeMutation.data?.result?.success === true && (
            <Bard
              html={props.thank_you_text}
              className="typo-200-regular xs:max-w-[80%] sm:max-w-[60%] md:max-w-full"
            />
          )}

          {subscribeMutation.data?.result?.success !== true && (
            <>
              <div className="space-y-2">
                {!!values.email && (
                  <FormField name="name">
                    <Input placeholder="Name" autoComplete="name" />
                  </FormField>
                )}
                <FormField name="email">
                  <Input type="email" placeholder="E-Mail" />
                </FormField>
              </div>

              <div className="">
                <Button type="submit" disabled={!isValid || subscribeMutation.isLoading}>
                  {props.button_text}
                </Button>
              </div>

              {subscribeMutation.data?.result?.message && (
                <Bard
                  html={subscribeMutation.data?.result?.message}
                  className="typo-200-regular xs:max-w-[80%] sm:max-w-[60%] md:max-w-full"
                />
              )}
            </>
          )}
        </form>
      )}
    </Formik>
  )
}

SmallNewsletterForm.defaultProps = {
  title: 'Newsletter',
  button_text: 'Anmelden',
  thank_you_text: 'Vielen Dank für die Anmeldung.',
}

export default SmallNewsletterForm
