import { IconProps } from '@additional'

const TwitterIcon: React.FC<IconProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M22 5.799a8.548 8.548 0 0 1-2.362.648 4.077 4.077 0 0 0 1.8-2.266 8.194 8.194 0 0 1-2.6.993 4.1 4.1 0 0 0-7.093 2.8 4.222 4.222 0 0 0 .095.935A11.606 11.606 0 0 1 3.389 4.62a4.1 4.1 0 0 0 1.26 5.48A4.049 4.049 0 0 1 2.8 9.598v.045a4.119 4.119 0 0 0 3.285 4.032 4.092 4.092 0 0 1-1.075.135 3.625 3.625 0 0 1-.776-.07 4.139 4.139 0 0 0 3.831 2.856 8.239 8.239 0 0 1-5.084 1.745A7.679 7.679 0 0 1 2 18.285a11.543 11.543 0 0 0 6.29 1.84A11.59 11.59 0 0 0 19.96 8.458c0-.181-.006-.356-.015-.53A8.179 8.179 0 0 0 22 5.799Z"
        fill="currentColor"
      />
    </svg>
  )
}

TwitterIcon.defaultProps = {
  'aria-hidden': true,
}

export default TwitterIcon
